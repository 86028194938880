import React from 'react'
import { Form } from 'semantic-ui-react'
import ObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField'
import camelize from 'underscore.string/camelize'
import underscored from 'underscore.string/underscored'
import isUuid from 'is_uuid'

import { getDefaultRegistry, retrieveSchema, } from "react-jsonschema-form/lib/utils";

function groupProperties(properties, uiSchema) {
  let groupedProperties = {}

  properties.forEach( (propertyName) =>  {
    let property = properties[propertyName]
    let propertyUiSchema = uiSchema[propertyName]
    let group = (propertyUiSchema && propertyUiSchema["ui:group"]) || "ungrouped"
    groupedProperties[group] = groupedProperties[group] || [];
    groupedProperties[group].push(propertyName);
  })

  return groupedProperties;
}

function orderProperties(properties, order) {
  if (!Array.isArray(order)) {
    return properties;
  }

  const arrayToHash = arr =>
    arr.reduce((prev, curr) => {
      prev[curr] = true;
      return prev;
    }, {});
  const errorPropList = arr =>
    arr.length > 1
      ? `properties '${arr.join("', '")}'`
      : `property '${arr[0]}'`;
  const propertyHash = arrayToHash(properties);
  const orderHash = arrayToHash(order);
  const extraneous = order.filter(prop => prop !== "*" && !propertyHash[prop]);
  if (extraneous.length) {
    throw new Error(
      `uiSchema order list contains extraneous ${errorPropList(extraneous)}`
    );
  }
  const rest = properties.filter(prop => !orderHash[prop]);
  const restIndex = order.indexOf("*");
  if (restIndex === -1) {
    if (rest.length) {
      throw new Error(
        `uiSchema order list does not contain ${errorPropList(rest)}`
      );
    }
    return order;
  }
  if (restIndex !== order.lastIndexOf("*")) {
    throw new Error("uiSchema order list contains more than one wildcard item");
  }

  const complete = [...order];
  complete.splice(restIndex, 1, ...rest);
  return complete;
}


export default class SemanticObjectField extends ObjectField {
  renderGroup(group, groupProperties, groupOptions = null) {
    const {
      uiSchema,
      formData,
      idSchema,
      name,
      required,
      disabled,
      readonly,
      onBlur,
      onFocus,
      entityName,
      registry = getDefaultRegistry(),
      translationScope
    } = this.props;

    let errors = this.props.errors
    var formContext = {}

    if(idSchema.$id === "root") {
      formContext = registry.formContext;
      errors = formContext.errors
    } else {
      formContext = this.props.formContext;
    }

    formContext = Object.assign(formContext || {});
    const { definitions, fields} = registry;

    const { SchemaField, TitleField, DescriptionField } = fields;
    const schema = retrieveSchema(this.props.schema, definitions);
    const title = schema.title === undefined ? name : schema.title;

    var fullTranslationScope
    if(idSchema.$id === "root") {
      var fullModel = formContext.entity
      fullTranslationScope = `api.forms.${underscored(formContext.entityName)}`
    } else {
      if (isNaN(this.props.name) && !isUuid(this.props.name)) {
        var fullModel = `${this.props.model || ''}.${camelize(this.props.name)}`

        fullTranslationScope = `api.forms.${underscored(formContext.entityName)}.${this.props.name}`
        formContext = {...formContext, ...{entityDesignation: this.props.name}};
      } else {
        var fullModel = `${this.props.model || ''}.${this.props.name}`
        fullTranslationScope = `${translationScope || ''}`
      }
    }

    const { "ui:options": options } = uiSchema
    const { widths } = (options || {})
    const { groupType } = (groupOptions || {})

    let GroupTemplate = null
    if (groupType === "button") {
      GroupTemplate = null
    }

    if (GroupTemplate) {
      return (<GroupTemplate group={group} {...this.props}>
        {
          groupProperties.map((name, index) => {
            return (
              <SchemaField
                key={index}
                name={name}
                required={this.isRequired(name)}
                schema={schema.properties[name]}
                uiSchema={uiSchema[name]}
                errors={errors && errors[name]}
                idSchema={{ "$id": name}}
                formData={formData[name]}
                onChange={this.onPropertyChange(name)}
                onBlur={onBlur}
                onFocus={onFocus}
                registry={registry}
                disabled={disabled}
                readonly={readonly}
                entityName={entityName}
                model={fullModel}
                translationScope={fullTranslationScope}
                formContext={formContext}
              />
            );
          })}
      </GroupTemplate>)
    } else {
      return groupProperties.map((name, index) => {
              return (
                <SchemaField
                  key={index}
                  name={name}
                  required={this.isRequired(name)}
                  schema={schema.properties[name]}
                  uiSchema={uiSchema[name]}
                  errors={errors && errors[name]}
                  idSchema={{ "$id": name}}
                  formData={formData[name]}
                  onChange={this.onPropertyChange(name)}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  registry={registry}
                  disabled={disabled}
                  readonly={readonly}
                  entityName={entityName}
                  model={fullModel}
                  translationScope={fullTranslationScope}
                  formContext={formContext}
                />
              );
      });
    }
  }

  renderGroups() {
    const {
      uiSchema,
      formData,
      errors,
      idSchema,
      name,
      required,
      disabled,
      readonly,
      onBlur,
      onFocus,
      entityName,
      registry = getDefaultRegistry(),
    } = this.props;

    var formContext = {}

    if(idSchema.$id === "root") {
      formContext = registry.formContext;
    } else {
      formContext = this.props.formContext;
    }

    formContext = Object.assign(formContext || {});
    const { definitions, fields} = registry;

    const { SchemaField, TitleField, DescriptionField } = fields;
    const schema = retrieveSchema(this.props.schema, definitions);
    const title = schema.title === undefined ? name : schema.title;

    if(idSchema.$id === "root") {
      var fullModel = formContext.entity
      var fullTranslationScope = `api.forms.${underscored(formContext.entityName)}`
    } else {
      if (isNaN(this.props.name) && !isUuid(this.props.name)) {
        var fullModel = `${this.props.model || ''}.${camelize(this.props.name)}`
        if(['relationships','attributes','data'].indexOf(this.props.name) < 0) {
          var fullTranslationScope = `api.forms.${underscored(formContext.entityName)}.${this.props.name}`
          formContext = {...formContext, ...{entityDesignation: this.props.name}};
        } else  {
          var fullTranslationScope = `${this.props.translationScope || ''}`
        }
      } else {
        var fullModel = `${this.props.model || ''}.${this.props.name}`
        var fullTranslationScope = `${this.props.translationScope || ''}`
      }
    }

    const { "ui:options": options, "ui:groupOptions": groupOptions, "ui:order": order } = uiSchema
    const { widths } = (options || {})

    const properties = Object.keys(schema.properties);
    let orderedProperties = orderProperties(properties, order);
    let groupedProperties = groupProperties(orderedProperties, uiSchema);

    return Object.keys(groupedProperties).map((group, groupIndex) => {
      let groupProperties = groupedProperties[group];
      if (group !== "ungrouped") {
      return (<Form.Group key={groupIndex}>
        {this.renderGroup(group, groupProperties, groupOptions && groupOptions[group])}
      </Form.Group>
          )
      } else {
        return this.renderGroup(group, groupProperties)
      }
    })
  }

  render() {
    return (
      <div className="objectfield">
        { this.renderGroups() }
      </div>
    );
  }
}
