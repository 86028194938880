import TimelineNode from 'components/dossiers/dossierFollowUp/TimelineNode';
import React from 'react'
import { Button, Divider, Grid, Header, Item, Menu, Segment } from "semantic-ui-react";
import EditModal from "../../forms/EditModal";
import RichTextRender from "../../forms/richText/RichTextRender";
import PrintableComponent, { getPrintButton } from "../../printing/PrintableComponent";
import DossierItemForm from "./DossierItemForm";

export default class NoteNode extends React.Component {
  render() {
    const {isSelected, time, nodeStyle} = this.props
    return (
      <TimelineNode icon={'sticky note'} type={'note'} {...this.props}>
        <PrintableComponent ref={element => (this.printRef = element)}>
          <Item as={Segment} raised={isSelected} clearing contentEditable={false} style={nodeStyle}>
            <Item.Content>
              <Grid divided>
                <Grid.Row>
                  <Grid.Column width={9}>
                    <Header>{I18n.t(`components.dossier_item.note`)}</Header>
                    <Item.Description>{I18n.l("date.formats.short_human_readable", time)}</Item.Description>
                  </Grid.Column>
                  <Grid.Column width={5} floated='right' textAlign='right' className='dont-print'>
                    <Menu compact borderless secondary stackable>
                      <Menu.Item>
                        <EditModal entityName="dossierItem" EntityForm={DossierItemForm} refreshAfterSave={true}
                                   entityId={this.props.dossierItem.id} trigger={(props) => {
                          return (
                            <Button onClick={props.onClick} icon='pencil'/>)
                        }}
                        />
                      </Menu.Item>
                      <Menu.Item>
                        {getPrintButton(this.printRef)}
                      </Menu.Item>
                    </Menu>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Item.Content>
            <Item.Content>
              <Divider/>
              <RichTextRender content={this.props.dossierItem.text}/>
            </Item.Content>
          </Item>
        </PrintableComponent>
      </TimelineNode>)
  }
}
