import PropTypes from 'prop-types'
import React from 'react'
import { Button, Input } from 'semantic-ui-react'
import { connect} from 'react-redux'
import { fetchCalendarsSync } from 'actions/calendars_sync';

import moment from 'moment'

import { DateInput } from 'semantic-ui-calendar-react'

let navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
}

class Toolbar extends React.Component {
  static propTypes = {
    view: PropTypes.string.isRequired,
    views: PropTypes.arrayOf(PropTypes.string).isRequired,
    label: PropTypes.node.isRequired,
    messages: PropTypes.object,
    onNavigate: PropTypes.func.isRequired,
    onViewChange: PropTypes.func.isRequired,
  }

  render() {
    let { messages, label, date } = this.props

    return (
      <div className="rbc-toolbar">
        <Button.Group basic compact>
          <Button
            onClick={this.navigate.bind(null, navigate.TODAY)}
          >
            {messages.today}
          </Button>
          <Button
            onClick={this.navigate.bind(null, navigate.PREVIOUS)}
          >
            {messages.previous}
          </Button>
          <Button
            onClick={this.navigate.bind(null, navigate.NEXT)}
          >
            {messages.next}
          </Button>
        </Button.Group>

        <span className="rbc-toolbar-label">{label}</span>

        <Button.Group basic compact>{this.viewNamesGroup(messages)}</Button.Group>

        <DateInput dateFormat={'DD-MM-YYYY'} value={date} onChange={this.handleDateChange} popupPosition='bottom center' size='mini' className='compact'/>


        <Button.Group icon basic compact>
          <Button icon='repeat' onClick={e => this.props.fetchCalendarsSync()}/>
          { this.props.isNewEventsListVisible ?
          <Button icon='chevron down' onClick={(event) => this.props.toggleNewEntitiesList(false)} /> :
          <Button icon='plus' onClick={(event) => this.props.toggleNewEntitiesList(true)}/> }

        </Button.Group>
      </div>
    )
  }

  navigate = action => {
    this.props.onNavigate(action)
  }

  view = view => {
    this.props.onViewChange(view)
  }

  handleDateChange = (event, { value }) => {
    this.props.onNavigate(null,moment.utc(value, "DD-MM-YYYY")) // Action, date
  }

  viewNamesGroup(messages) {
    let viewNames = this.props.views
    const view = this.props.view

    if (viewNames.length > 1) {
      return viewNames.map(name => (
        <Button
          key={name}
          active={ view === name }
          onClick={this.view.bind(null, name)}
        >
          {messages[name]}
        </Button>
      ))
    }
  }
}

const mapStateToProps = (state) => {

  let date = moment().format("DD-MM-YYYY")
  if (state.ui.eventsCalendar && state.ui.eventsCalendar.date) {
    let rawDate = moment(state.ui.eventsCalendar.date, "YYYY-MM-DD")
    if (rawDate.isValid()) {
      date = rawDate.format("DD-MM-YYYY")
    }
  }

  return {
    isNewEventsListVisible: state.ui.eventsCalendar && state.ui.eventsCalendar.isNewEventsListVisible,
    date: date
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCalendarsSync: () => {
      dispatch(fetchCalendarsSync())
    },
    toggleNewEntitiesList: (opened) => {
      dispatch({type: 'UPDATE_UI_STATE', payload: {key: 'eventsCalendar', name: 'isNewEventsListVisible', value: opened }})
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar)
