import DossierForm from 'components/dossiers/DossierForm'

import EntitiesList from 'components/entities/EntitiesList';
import dotProp from "dot-prop";
import { connect } from 'react-redux'

import { getDossiers } from 'selectors/dossiers'
import DossiersList from "./DossiersList";
import DossiersListItem from "./DossiersListItem";

const mapStateToProps = (state, ownProps) => {
  let filterDossiers = (dossier)=>{
    const employmentsFilter = dotProp.get(state, `meta.dossier.filter.employment`)
    const allowedEmployment =  !employmentsFilter || employmentsFilter.length === 0 || employmentsFilter.includes(dossier.responsibleId)
    if (!allowedEmployment) return false

    const locationsFilter = dotProp.get(state, `meta.dossier.filter.location`)
    const allowedLocation =  !locationsFilter || locationsFilter.length === 0 || locationsFilter.includes(dossier.locationId)
    if (!allowedLocation) return false

    const nameFilter = dotProp.get(state, `meta.dossier.filter.name`)
    if (!nameFilter) return true
    if (!dossier.patient) return false
    const name = (dossier.patient.firstName + ' ' + dossier.patient.lastName).toLowerCase()
    return (name.search(nameFilter.toLowerCase())) !== -1
  }


  return {
    entities: getDossiers(state),
    entityName: 'dossier',
    item: DossiersListItem,
    form: DossierForm,
    listComponent: DossiersList,
    loading: state.meta.dossier.loading,
    error: state.meta.dossier.error,
    entitiesFilter: filterDossiers,
    addButton: () => {return null}
  };
}

export default connect(mapStateToProps)(EntitiesList)
