import {createConsumer} from '@rails/actioncable'

class AppChannels {
  constructor() {
    this.cable = createConsumer();
  }
}

const instance = new AppChannels();

export default instance;
