import { fk, attr } from 'redux-orm'
import Base from './Base'

class Invoice extends Base {
  static get fields() {
    return {
      id: attr(),
      createdAt: attr(),
      paid: attr(),
      identification: attr(),
      proposed: attr(),
      formattedIdentification: attr(),
      invoiceDate: attr(),
      sendInvoiceWarning: attr(),
      printInvoiceWarning: attr(),
      payedAt: attr(),
      dossierId :fk({to: 'dossier', as: 'dossier', relatedName: 'invoices'}),
    }
  }
}

Invoice.modelName = 'invoice'

export default Invoice;
