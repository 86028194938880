import React from 'react'

import { Icon } from 'semantic-ui-react'

import { connect } from 'react-redux'

class TimelinePicker extends React.Component {
  handleClick = (value) => {
    const  { onChange } = this.props
    if (onChange) {
      onChange(value)
    }
  }

  render() {
    const { value, options, selectedValue } = this.props

    const margin = 2;
    const totalLength = 100 - 2*margin;
    const lengthByOption = totalLength / (options.length + 1)

    return (
      <div className="timeline-picker__container">
        <div className="timeline-picker__options">
          { options.map( (option, index) => {
            let leftPosition = margin + (index)*lengthByOption
            let rightPosition = 100 - (margin + (index + 1)*lengthByOption)
            let selected = ( selectedValue === option.value)
            let style = {
              "left": `${leftPosition}%`,
              "right": `${rightPosition}%`,
            }
            return (<span key={option.value} className={`timeline-picker__period ${selected ? "timeline-picker__period--selected" : "" }`} style={style} onClick={() => this.handleClick(option.value) }>
              {index === 0 && <span className={"timeline-picker__option timeline-picker__option--left"}></span> }
              <span className={"timeline-picker__option-label timeline-picker__option-label--left"}>{option.leftLabel}</span>
              <span className={"timeline-picker__option-label timeline-picker__option-label--right"}>{option.rightLabel}</span>
              <span className={"timeline-picker__option timeline-picker__option--right"}></span>
            </span>)

          })}
        </div>
      </div>
    );
  }
}

export default TimelinePicker;
