
import { createSelector } from 'reselect'
import { createSelector as ormSelector } from 'redux-orm'
import orm from 'models'

import Moment from 'moment'
import { extendMoment } from 'moment-range'
import getEntities from 'selectors/entities'

const moment = extendMoment(Moment)

import { getKeyWindowSelector } from 'selectors/key_windows'

export const getEvents = ormSelector(orm, getEntities, (session) => {
  return session.event.all().toModelArray().map(event => {
    const { ref } = event
    return { ...ref}
  }).filter( (event) => { return event.startTime && event.endTime })
})


