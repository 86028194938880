import dotProp from "dot-prop";
import contactSliceSchema from 'forms/contactSliceSchema'
import { getAge } from "../birthday_to_age.js";

const contactSchema = function (state, props, isPatient) {

  let birthdayLabel = I18n.t('api.forms.contact.birthday')
  if (isPatient) {
    const contactId = dotProp.get(state, `entities.dossier.byId.${props.entityId}.patientId`)
    const birthdayString = dotProp.get(state, `entities.contact.byId.${contactId}.birthday`)
    if (birthdayString) {
      const age = getAge(birthdayString)
      birthdayLabel = birthdayLabel.concat(' ', I18n.t('api.forms.contact.age_descriptor', {age: age}))
    }
  }

  return {
    "ui:readonly": props.readonly,
    "firstName": {
      "ui:group": 'name',
      "ui:options": {
        width: 6
      }
    },
    "lastName": {
      "ui:group": 'name',
      "ui:options": {
        width: 6
      }
    },
    "email": {
      "ui:group": 'email',
      "ui:options": {
        width: 6
      }
    },
    "birthday": {
      "ui:widget": "BirthdayWidget",
      "ui:group": 'email',
      "ui:label":birthdayLabel,
      "ui:options": {
        width: 4
      }
    },
    "securityNumber": {
      "ui:group": 'securityNumber',
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:widget": isPatient? "MaskedTextWidget" : "hidden",
      "ui:options" : {
        mask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, '-', /\d/, /\d/, /\d/, '.', /\d/, /\d/],
        copyButton: true,
        charsNotToCopy: ['.', '-'],
        width : 4
      }
    },
    "contactSlices": {
      "ui:field": "SliceField",
      "ui:options": {
        "entityName": "contactSlice",
        "copiedRelationships": ["address"],
        "displayTitle": false
      },
      "items": {
        "ui:field": "NestedFormField",
        "ui:options": {
          "entityName": "contactSlice",
          "displayTitle": false,
          "inline": true
        },
        "ui:schema": contactSliceSchema(state,props)
      }
    }
  }
}

export default contactSchema;
