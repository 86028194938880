import dotProp from 'dot-prop'
import orm from 'models'
import { createSelector as ormSelector } from 'redux-orm'
import { createSelector } from 'reselect'

import getEntities from 'selectors/entities'

export const getCalendars = ormSelector(orm, getEntities, (session) => {
  return session.calendar.all().toModelArray().map(calendar => {
    const { ref } = calendar
    return { ...ref}
  })
})

export const getFirstCalendarId = createSelector([getCalendars], (calendars) => {
  return calendars[0] && calendars[0].id
})

const getCalendarsById = (state) => (Object.values(state.entities.calendar && state.entities.calendar.byId) || [])


const getCalendarIdentification = (state, props)  =>  {
  var entityModel = `entities.calendars.byId.${props.entityId}.attributes.calendarIdentification`
  return dotProp.get(state,entityModel)
}

export const getCalendarByCalendarIdentification = createSelector([getCalendarsById, getCalendarIdentification], (calendars, calendarIdentification) => {
  return (calendars.find( calendar => { return calendar.attributes.calendarIdentification === calendarIdentification }))
})

export const getCalendarsFilter = state => state.meta.calendar.filter

export const getFilteredCalendars= createSelector([getCalendars, getCalendarsFilter], (calendars, filter) => {

  return calendars.filter( (calendar) =>  { return calendar.id > 0 && ( !filter || !filter.id || filter.id === calendar.id
  )})
})
