export const SET_SETTING = 'SET_SETTING';
export function setSetting(key, value) {
  return {
    type: SET_SETTING,
    payload: {
      [key]: value
    }
  };
}

