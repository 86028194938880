import React from 'react'
import { connect } from 'react-redux'
import { Header, Segment } from "semantic-ui-react";
import { getCurrentEmployment } from "../../selectors/employments";
import EmploymentForm from "../employments/EmploymentForm";

class BasicInfoConfiguration extends React.Component {

  render() {
    let currentEmployment = this.props.currentEmployment

    if(currentEmployment) {
      return (
        <Segment>
          <Header as="h1">
            {I18n.t('components.configuration.office')}
          </Header>
          <EmploymentForm key={'employmentConfig'} entityId={currentEmployment.id}/>
        </Segment>
      )
    } else {
      return <div></div>
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentEmployment: getCurrentEmployment(state)
  }
}


export default connect(mapStateToProps)(BasicInfoConfiguration);
