import { fk, attr } from 'redux-orm'
import Base from './Base'

class GenerateConsultationError extends Base {
  static get fields() {
    return {
      id: attr(),
      matchId: attr(),
      matchType: attr(),
      errorType: attr(),
      resolutionType: attr(),
      eventId :fk({to: 'event', as: 'event', relatedName: 'generateConsultationError'}),
    }
  }
}

GenerateConsultationError.modelName = 'generateConsultationError'

export default GenerateConsultationError;
