import React from 'react'
import { Input, Label } from 'semantic-ui-react'
import { actions as formActions } from 'react-redux-form'

import { connect } from 'react-redux'
import get from 'react-redux-form/utils/get'

class SemanticTextWidget extends React.Component {
  render() {
    return (<Input value={this.props.value} onChange={this.props.handleChange} input={<input type='text' autoComplete='off'/>}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let fullModel = `${ownProps.model}.${ownProps.id}`

  return {
    value: get(state, fullModel),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  let fullModel = `${ownProps.model}.${ownProps.id}`

  return {
    handleChange: (event) => {
      dispatch(formActions.change(fullModel, event.target.value))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SemanticTextWidget);
