import React from 'react'
import { Segment, Grid, Header, Container } from 'semantic-ui-react'
import { Switch, Route } from 'react-router-dom'
import NotificationsList from 'components/notifications/NotificationsList'

export default class Notifications extends React.Component {
  render () {
    return (
      <Container>
        <Segment clearing className="min-height-90">
            <Header dividing>
              <Grid columns={2}>
                <Grid.Row verticalAlign='middle'>
                  <Grid.Column width={14}>
                    <Header as='h3'>
                      {I18n.t('components.notifications.title')}
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Header>
            <NotificationsList/>
         </Segment>
      </Container>
    )
  }
}

