import { createSelector } from 'reselect'
import { createSelector as ormSelector } from 'redux-orm'
import orm from 'models'

import getEntities from 'selectors/entities'

import dotProp from 'dot-prop'

export const getSelectableCalendars = ormSelector(orm, getEntities, (session) => {
  return session.selectableCalendar.all().toModelArray().map(selectableCalendar => {
    const { ref } = selectableCalendar
    return { ...ref}
  })
})

const getSelectableCalendarsById = (state) => (Object.values(state.entities.selectableCalendar && state.entities.selectableCalendar.byId) || [])


const getCalendarIdentification = (state, props)  =>  {
  var entityModel = `entities.calendar.byId.${props.entityId}.calendarIdentification`
  return dotProp.get(state,entityModel)
}

export const getSelectableCalendarByCalendarIdentification = createSelector([getSelectableCalendarsById, getCalendarIdentification], (selectableCalendars, calendarIdentification) => {
  return (selectableCalendars.find( calendar => { return calendar.calendarIdentification === calendarIdentification }))
})
