import { SET_PROGRESS, END_PROGRESS } from 'actions/progress'

import dotProp from 'dot-prop-immutable'

const initialState = {
  byId: {}
}

export default function progress(state = initialState, action) {
  switch (action.type) {
  case SET_PROGRESS:
    return { ...state,...{byId: {...state.byId, [action.payload.id]: action.payload.progress}}};
  case END_PROGRESS:
    let id = action.payload
    return dotProp.delete(state,`byId.${id}`);
  }
  return state
}
