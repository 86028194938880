export const DESTROY_FLASH = 'DESTROY_FLASH';
export function destroyMessage(messageKey) {
  return {
    type: DESTROY_FLASH,
    messageKey: messageKey
  };
}

export const RECEIVE_MESSAGES = 'RECEIVE_MESSAGES';
export function receiveMessages(messages) {
  return {
    type: RECEIVE_MESSAGES,
    messages: messages
  };
}
