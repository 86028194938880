import consultationSchema from "./consultationSchema.js";

const eventSchema = function(state, props) {
  return {
    "ui:order": ["summary", "startTime", "duration", "*"],
    "calendarId": {
      "ui:widget": "hidden"
    },
    "linkingStatus": {
      "ui:widget": "hidden"
    },
    "consultationId": {
      "ui:field": "NestedFormField",
      "ui:schema": consultationSchema(state, props),
      "ui:options": {
        "entityName": "consultation",
        "removable": true,
        "displayTitle": true,
        "titleKey": "api.forms.event.consultation"
      },
      "startTime": {
        "ui:widget": "DateTimeWidget"
      },
      "duration": {
        "ui:widget": "TimeDurationWidget"
      }
    }
  }
}

export default eventSchema;
