import React from 'react'
import { connect } from 'react-redux'
import { Header, Segment } from "semantic-ui-react";
import { fetchEntities } from "../../actions/entities.js";
import invoiceConfigurationSchema from "../../forms/invoiceConfigurationSchema";
import { getInvoiceConfiguration } from "../../selectors/invoice_configurations";
import SchemaForm from "../forms/SchemaForm";

class InvoiceConfiguration extends React.Component {
  componentDidMount() {
    this.props.fetchEntities()
  }

  render() {
    if(this.props.entityId) {
      return (
        <Segment basic>
          <Header dividing as="h2">{I18n.t('api.forms.invoice_configuration.title')} </Header>
          <SchemaForm entityName="invoiceConfiguration" {...this.props}/>
        </Segment>
      )
    } else {
      return <div></div>
    }
  }
}

const mapStateToProps = (state, ownProps) => {

  let configuration = getInvoiceConfiguration(state)
  return {
    uiSchema: invoiceConfigurationSchema(state, ownProps),
    entityId: configuration && configuration.id
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('invoiceConfigurations'))
    },
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(InvoiceConfiguration);
