import React from 'react'

import { connect } from 'react-redux'
import { actions as formActions } from 'react-redux-form'
import get from 'react-redux-form/utils/get'
import RichTextEditor from "../richText/RichTextEditor";

class SemanticRichTextWidget extends React.Component {
  render() {
    const { mentionOptions } = this.props.options
    return (
        <RichTextEditor serializeToHtml={this.props.serializeToHtml}
                        onChange={this.props.handleChange}
                        value={this.props.value}
                        mentionOptions = {mentionOptions}
        />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let fullModel = `${ownProps.model}.${ownProps.id}`

  return {
    value: get(state, fullModel),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  let fullModel = `${ownProps.model}.${ownProps.id}`

  return {
    handleChange: (value) => {
      dispatch(formActions.change(fullModel, value))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SemanticRichTextWidget);
