import { updateEntity, upsertEntity } from "actions/entities.js";
import { addressToString } from "address_to_string.js";
import { getAge } from "birthday_to_age.js";
import React from 'react'
import { connect } from 'react-redux'
import { findEntity } from 'selectors/entities';
import { Button, Header, Icon, Item, List, Segment } from 'semantic-ui-react';
import TimelineNode from "./TimelineNode";

class DossierNode extends React.Component {

  constructor(props) {
    super(props)
  }
  renderDossierOverview() {
    const {dossier, patient} = this.props

    return (
        <div>
          <List>
            {dossier.dossierNumber &&  <List.Item content={I18n.t('components.dossier_list_item.dossier_number') + " " + dossier.dossierNumber}/>}
            {(patient.birthday || patient.address) && <List.Item content={this.renderAgeAndAddress()}/>}
            {(patient.phone || patient.email) && <List.Item content={this.renderPhoneAndMail()}/>}
            {dossier.extraRemark && <List.Item content={dossier.extraRemark}/>}
          </List>
        </div>
    )
  }

  renderAgeAndAddress(){
    const {birthday, address} = this.props.patient
    return (
        <List divided horizontal relaxed="very">
          {birthday && <List.Item content={I18n.t('components.dossier_item.age_descriptor', {age: getAge(birthday)})}/>}
          {address && <List.Item content={addressToString(address)}/>}
        </List>
    )
  }

  renderPhoneAndMail(){
    const {phone, email} = this.props.patient
    return (
        <List divided horizontal relaxed="very">
          {phone && <List.Item content={phone}/>}
          {email && <List.Item content={email}/>}
        </List>
    )
  }

  render() {
    const {isSelected, attributes, dossier, patient} = this.props
    const archived = dossier.isArchived

    if (!dossier || !patient) {
      return <div>{I18n.t('components.dossier.not_loaded')}</div>
    }

    return (<div {...attributes}>
      <TimelineNode {...this.props} icon='user circle outline' first={true}>
        <Item as={Segment} raised={isSelected} clearing contentEditable={false}>
          <React.Fragment>
            {patient && <Item.Header as={Header}>
              {patient.firstName} {patient.lastName}
            </Item.Header>}
            <Item.Content>
              {this.renderDossierOverview()}
            </Item.Content>
            <Item.Content>
              <Button basic color={archived? 'green' : 'red'} floated={'right'} compact onClick={() =>this.props.archiveDossier(!archived)}>
                <Icon name={"archive"}/>
                {archived? I18n.t('components.dossier.restore') : I18n.t('components.dossier.archive')}
              </Button>
            </Item.Content>
          </React.Fragment>
        </Item>
      </TimelineNode>
    </div>)
  }
}

const mapStateToProps = (state, ownProps) => {
  let dossier = findEntity(state, 'dossier', ownProps.dossierId)
  let patient = findEntity(state, 'contact', dossier && dossier.patientId)

  return {
    dossier: dossier,
    patient: patient
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    archiveDossier: (archive) => {
      dispatch(updateEntity('dossier', {id: ownProps.dossierId, isArchived: archive}))
      dispatch(upsertEntity('dossier', ownProps.dossierId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DossierNode)

