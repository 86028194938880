import _ from 'lodash'

export const flattenEntity = (entity) => {
  return _.merge({}, {...entity.attributes, id: entity.id, ...flattenRelationships(entity) })
}

const flattenRelationships = (entity) => {
  let flattenedRelationships = {}

  Object.keys(entity.relationships || {}).forEach((relationshipName) => {
    let relationship = entity.relationships[relationshipName]
    if (relationship.data && !Array.isArray(relationship.data)) {
      flattenedRelationships[`${relationshipName}Id`]  = relationship.data.id
    }
  })

  return flattenedRelationships
}

const flattenSchemaRelationships = (properties) => {

  let flattenedRelationships = {}
  Object.keys(properties?.relationships?.properties || {}).forEach((relationshipName) => {
    let relationship = properties.relationships.properties[relationshipName]
    let relationshipDataProps = relationship?.properties?.data

    if (relationshipDataProps?.properties?.id) {
      flattenedRelationships[`${relationshipName}Id`] = relationshipDataProps?.properties?.id
    }

    if (relationshipDataProps?.items) {
      flattenedRelationships[relationshipName] = {  type: 'array', items: { type: "object", properties: { id: relationshipDataProps?.items?.properties?.id }}}
    }
  })

  return  { properties: flattenedRelationships }
}

export const flattenEntities = (entities)  => {
  let flattenedEntities = {}
  Object.keys(entities).forEach( (id) => {
    let entity = entities[id]
    flattenedEntities[id] = flattenEntity(entity)
  })

  return flattenedEntities
}

export const flattenSchema = (schema) => {
  return _.merge({}, schema.properties.attributes, flattenSchemaRelationships(schema.properties), { type: schema.type})
}
