import ConsultationTypeForm from 'components/superuser/consultation_types/ConsultationTypeForm';
import ConsultationTypesListContainer from 'components/superuser/consultation_types/ConsultationTypesListContainer';
import ConsultationTypesMenuContainer from 'components/superuser/consultation_types/ConsultationTypesMenuContainer';
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Segment, Sidebar } from 'semantic-ui-react'

export default class ConsultationTypes extends React.Component {
  render () {
    return (
      <div>
        <Switch>
          <Route path={Routes.superuser_consultation_type_path({id: ":id", _options: true})} render={ props => (
            <Sidebar.Pushable as={Segment} className= "min-height-90">
              <ConsultationTypesMenuContainer/>
              <Sidebar.Pusher className="left-sidebar-margin">
                <ConsultationTypeForm entityId={props.match.params.id}/>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
           )} />

          <Route path={Routes.superuser_consultation_types_path()} component={ConsultationTypesListContainer}/>
        </Switch>
      </div>
    )
  }
}

