import React from 'react'
import { connect } from 'react-redux'
import { Popup } from "semantic-ui-react";
import { Button, Grid, Header, Icon, Menu, Segment } from 'semantic-ui-react'
import DossierCertificateForm from './DossierCertificateForm'

class DossierDocuments extends React.Component {
  render() {

    let HealthFundButton = <Button>
      <Icon name='file pdf outline'/>
      {I18n.t(`components.dossier.documents.create_health_fund_certificate`)}
    </Button>


      return (
        <Segment basic>
          <Header dividing>
            <Grid columns={2}>
              <Grid.Row verticalAlign='middle'>
                <Grid.Column width={5}>
                  <Header as='h2' >{I18n.t(`components.dossier.documents.title`)} </Header>
                </Grid.Column>
                <Grid.Column textAlign="right" width={11}>
                <Menu compact borderless secondary>
                  <Menu.Item>
                    <Button href={Routes.documents_path({id: this.props.dossierId, format: 'pdf', type:'bilan', _options: true})} >
                              <Icon name='file pdf outline'/>
                              {I18n.t(`components.dossier.documents.create_bilan`)}
                    </Button>
                  </Menu.Item>
                  <Menu.Item>
                    <Button href={Routes.documents_path({id: this.props.dossierId, format: 'pdf',  type:'report', _options: true})} >
                      <Icon name='file pdf outline'/>
                      {I18n.t(`components.dossier.documents.create_report`)}
                    </Button>
                  </Menu.Item>
                  <Menu.Item>
                    {this.props.healthFund ?
                      <DossierCertificateForm trigger={HealthFundButton} dossierId = {this.props.dossierId}/>
                      :
                      <Popup trigger={HealthFundButton} header={I18n.t(`components.dossier_documents.health_fund_error`)}/>
                    }
                  </Menu.Item>
                </Menu>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
        </Segment>
      )
  }
}

const mapStateToProps = (state, ownProps) => {
  const dossier = ownProps.dossier;

  return {
    healthFund: dossier.healthFund,
    dossierId: dossier.id,
  };
}

export default connect(mapStateToProps)(DossierDocuments)
