import React from 'react'
import { NavLink } from 'react-router-dom'
import { Card } from 'semantic-ui-react'

export default class OfficesListItem extends React.Component {

  render () {
    const office = this.props.office
    return (
      <Card as={NavLink} key={office.id} to={Routes.superuser_office_path({id: office.id, _options: true})}>
        <Card.Content>
          <Card.Header>
            {office.name}
          </Card.Header>
          {/* <Card.Meta> */}
          {/*   {I18n.t('components.office_list_item.since', { date: I18n.l("date.formats.default",office.createdAt) })} */}
          {/* </Card.Meta> */}
        </Card.Content>
    </Card>)
  }
}

