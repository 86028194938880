import fetch from 'isomorphic-fetch'

export const REQUEST_CALENDARS_SYNC = 'REQUEST_CALENDARS_SYNC';
export function requestCalendarsSync() {
  return {
    type: REQUEST_CALENDARS_SYNC
  };
}


export const RECEIVE_CALENDARS_SYNC = 'RECEIVE_CALENDARS_SYNC';
export function receiveCalendarsSync() {
  return {
    type: RECEIVE_CALENDARS_SYNC
  };
}

export const FETCH_CALENDARS_SYNC= 'FETCH_CALENDARS_SYNC';
export function fetchCalendarsSync() {
  return function(dispatch) {

    dispatch(requestCalendarsSync())

    return fetch(Routes.request_sync_api_calendars_path(), {
       headers: {
         'X-Requested-With': 'XMLHttpRequest',
         'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
         'Content-Type': 'application/json',
         'Accept': 'application/json'
       },
       method: 'POST',
      'credentials': 'same-origin'}).then (
      response => response.json(),
      error => dispatch(fetchCalendarsSyncFailure(error))
    ).then (
      json => dispatch(receiveCalendarsSync())
    )
  }
}

export const FETCH_CALENDARS_SYNC_FAILURE= 'FETCH_CALENDARS_SYNC_FAILURE';
export function fetchCalendarsSyncFailure(error) {
  return {
    type: FETCH_CALENDARS_SYNC_FAILURE,
    error: error
  };
}
