import { createSelector } from 'reselect'
import { createSelector as ormSelector } from 'redux-orm'
import getEntities from 'selectors/entities'
import orm from 'models'

export const getConsultationTypes = ormSelector(orm, getEntities, (session) => {
  return session.consultationType.all().toModelArray().map(consultationType => {
    const { ref } = consultationType
    return { ...ref }
  })
})

export const getConsultationTypesFilter = state => state.meta.consultationType.filter

export const getFilteredConsultationTypes= createSelector([getConsultationTypes, getConsultationTypesFilter], (consultationTypes, filter) => {

  return consultationTypes.filter( (consultationType) =>  { return (consultationType.id > 0) && (!filter || !filter.id || ( filter.id === consultationType.id)
  )})
})
