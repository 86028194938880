import { attr, fk } from 'redux-orm'
import Base from './Base'

class Notification extends Base {
  static get fields() {
    return {
      id: attr(),
      resolvedAt: attr(),
      resolutionType: attr(),
      titleKey: attr(),
      messageKey: attr(),
      relevantObjectId : attr(),
      notificationType: attr(),
      generateConsultationErrorId :fk({to: 'generateConsultationError', as: 'generateConsultationError', relatedName: 'notification'}),
    }
  }
}

Notification.modelName = 'notification'

export default Notification;
