
const invoiceItemSchema = function(state, props) {
  const uiSchema = {
    "position": {
      "ui:widget" :"hidden"
    },
    "description": {
      "ui:options" : {
        "width": 16
      }
    },
    "price": {
      "ui:group": "price_quantity",
      "ui:widget": "PriceWidget",
      "ui:options" : {
        "width": 4
      }
    },
    "quantity": {
      "ui:group": "price_quantity",
      "ui:widget": "NumberWidget",
      "ui:options" : {
        "width": 4
      }
    },
    "unit": {
      "ui:group": "price_quantity",
      "ui:widget": "select",

      "ui:options" : {
        "width": 4,
        selectOptions: [
          {key: 'session',  value: 'session', text: I18n.t('components.invoice_form.unit.session') },
          {key: 'hours', value: 'hours', text: I18n.t('components.invoice_form.unit.hours') },
          {key: 'km',  value: 'km', text: I18n.t('components.invoice_form.unit.km') },
        ]
      }
    }
  }

  return uiSchema
}

export default invoiceItemSchema
