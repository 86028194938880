const dossierAttachmentSchema = function (state, props) {
  return {
    "ui:readonly": props.readonly,
    "text": {
      "ui:widget": "hidden"
    },
    "dossierItemType": {
      "ui:widget": "hidden"
    },
    "consultationId": {
      "ui:widget": "hidden"
    },
    "dossierId": {
      "ui:widget": "hidden"
    },
    "time": {
      "ui:group": "line",
      "ui:options": {
        "ui:width": 2
      }
    },
    "attachment": {
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:widget": "FileWidget"
    },
  }
}

export default dossierAttachmentSchema;
