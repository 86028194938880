import React from 'react'
import { Card } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

export default class OrganisationsListItem extends React.Component {

  render () {
    const organisation = this.props.organisation
    return (
      <Card as={NavLink} key={organisation.id} to={Routes.organisation_path({id: organisation.id, _options: true})}>
        <Card.Content>
          <Card.Header>
            {organisation.name}
          </Card.Header>
        </Card.Content>
    </Card>)
  }
}

