import ConsultationsListItem from 'components/consultations/ConsultationsListItem'
import EntitiesList from 'components/entities/EntitiesList';
import { connect } from 'react-redux'

import { getSortedConsultationsForListByKeyWindow } from 'selectors/consultations'

const mapStateToProps = (state, ownProps) => {
  let consultations = getSortedConsultationsForListByKeyWindow(ownProps.keyWindow)(state)

  return {
    entities: consultations,
    entityName: 'consultation',
    item: ConsultationsListItem,
    loading: state.meta?.consultation?.loading,
    error: state.meta?.consultation?.error
  };
}
export default connect(mapStateToProps)(EntitiesList);
