import { SET_CHANNEL_CONNECTED, SET_CHANNEL_DISCONNECTED } from 'actions/channels'

import dotProp from 'dot-prop-immutable'

const initialState = {
  byId: {
    calendar_sync: {
      connection_state: 'disconnected'
    },
    generate_invoices: {
      connection_state: 'disconnected'
    },
    generate_consultations: {
      connection_state: 'disconnected'
    },
    tag_events_runs: {
      connection_state: 'disconnected'
    },
  }
}

export default function channels(state = initialState, action) {
  switch (action.type) {
  case SET_CHANNEL_CONNECTED:
    return { ...state,...{byId: {...state.byId, [action.payload.id]: {connection_state: "connected" }}}};
  case SET_CHANNEL_DISCONNECTED:
    return { ...state,...{byId: {...state.byId, [action.payload.id]: {connection_state: "disconnected" }}}};
  }
  return state
}
