import React from 'react'
import { connect } from 'react-redux'
import SchemaForm from "../../forms/SchemaForm";
import dossierAttachmentSchema from "../../../forms/dossierAttachmentSchema";

class DossierAttachmentForm extends React.Component {

  render() {
    return (
      <SchemaForm entityName='dossierItem' {...this.props}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    uiSchema: dossierAttachmentSchema(state, ownProps)
  }
}

DossierAttachmentForm.defaultProps = {
  submitButton: true
}

export default connect(mapStateToProps)(DossierAttachmentForm);
