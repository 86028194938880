import { attr, fk } from 'redux-orm'
import Base from './Base'

class InvoiceConfiguration extends Base {
  static get fields() {
    return {
      id: attr(),
      officeId: fk({ to: 'office', as: 'office', relatedName: 'invoiceConfiguration' }),
      employmentId: fk({ to: 'employment', as: 'employment', relatedName: 'invoiceConfiguration' }),
      enterpriseNumber: attr(),
      iban: attr(),
      invoiceConditions: attr(),
      invoiceMessage: attr(),
      startInvoiceIdentification: attr()
    }
  }
}

InvoiceConfiguration.modelName = 'invoiceConfiguration'

export default InvoiceConfiguration;
