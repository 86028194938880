import { attr, fk } from 'redux-orm'
import Base from './Base'

class ConsultationReminder extends Base {
  static get fields() {
    return {
      id: attr(),
      minutesNotice: attr(),
      active: attr(),
      emailTemplateId :fk({to: 'emailTemplate', as: 'emailTemplate', relatedName: 'consultationReminder'}),
    }
  }
}

ConsultationReminder.modelName = 'consultationReminder'

export default ConsultationReminder;
