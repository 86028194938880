import React from 'react'

import { connect } from 'react-redux'

import { findEntity } from 'selectors/entities';
import { Item, Segment } from "semantic-ui-react";
import TimelineNode from "./TimelineNode";

export class DossierSliceNode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {collapsed: true}
  }

  render() {
    const {isSelected, dossierSlice, attributes} = this.props

    if (!dossierSlice) {
      return <div>{I18n.t('components.dossier_item.not_loaded')}</div>
    }

    return (<div {...attributes}>

      <TimelineNode {...this.props} time={dossierSlice && dossierSlice.startTime} last={true}
                    icon='user circle outline'>
        <Item as={Segment} raised={isSelected} clearing contentEditable={false}>
          {"Dossier aangemaakt"}
        </Item>
      </TimelineNode>
    </div>)
  }
}

const mapStateToProps = (state, ownProps) => {
  let dossierSlice = findEntity(state, 'dossierSlice', ownProps.sliceId)

  return {
    dossierSlice: dossierSlice
  }
}

export default connect(mapStateToProps)(DossierSliceNode)

