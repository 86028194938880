import React from 'react'
import { connect } from 'react-redux'
import { fetchEntities, filterEntities } from 'actions/entities';
import EntitiesMenu from 'components/entities/EntitiesMenu';
import { Menu, Segment } from 'semantic-ui-react'
import { NavLink, withRouter } from 'react-router-dom'
import OrganisationForm from 'components/organisations/OrganisationForm'
import { getOrganisations } from 'selectors/organisations'

const mapStateToProps = (state, ownProps) => {

  return {
    entities: getOrganisations(state, ownProps),
    entityName: 'organisation',
    form: OrganisationForm,
    item: (props) => <div>{`${props.organisation.name}`}</div>,
    loading: state.meta.organisation.loading,
    error: state.meta.organisation.error
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('organisations'))
    },
    filterEntities: (filter) => {
      dispatch(filterEntities('organisations', filter))
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EntitiesMenu));
