import React from 'react'
import { connect } from "react-redux";
import { Input } from "semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import { Table } from "semantic-ui-react";
import { TableBody } from "semantic-ui-react";
import { TableHeaderCell } from "semantic-ui-react";
import { TableRow } from "semantic-ui-react";
import { TableHeader } from "semantic-ui-react";
import { filterEntitiesBy } from "../../../actions/entities";
import { getPersistedEntities } from "../../../selectors/entities";

class DossiersList extends React.Component {
  render() {
    return (
      <Table basic={"very"}>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>
              <div className="bold-placeholder">
                <Input placeholder={I18n.t('components.admin_app.dossiers.name')} icon="search" onChange={this.changeHandler('name')}/>
              </div>
            </TableHeaderCell>
            <TableHeaderCell>
              <Dropdown text={I18n.t('components.admin_app.dossiers.responsible')} multiple search selection
                        options={this.props.employmentOptions} onChange={this.changeHandler('employment')}/>
            </TableHeaderCell>
            <TableHeaderCell>
              <Dropdown text={I18n.t('components.admin_app.dossiers.location')} multiple search selection
                        options={this.props.locationOptions} onChange={this.changeHandler('location')}/>
            </TableHeaderCell>
            <TableHeaderCell>{I18n.t('components.admin_app.dossiers.creation_date')}</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {this.props.children}
        </TableBody>
      </Table>
    )
  }

  changeHandler(by){
    return (event, props) => {
      this.props.filterDossiers(by, props.value)
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const employments = getPersistedEntities('employment')(state, ownProps);
  const locations = getPersistedEntities('location')(state, ownProps);
  const employmentOptions = employments.map((emp) => {
    return { key: emp.id, value: emp.id, text: emp.name }
  })
  const locationOptions = locations.map((loc) => {
    return { key: loc.id, value: loc.id, text: loc.name }
  })

  return {
    employmentOptions: employmentOptions,
    locationOptions: locationOptions
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    filterDossiers: (by, value) => {
      dispatch(filterEntitiesBy('dossiers', by, value ))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DossiersList);


