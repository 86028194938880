import React from 'react'
import { Sidebar, Segment, Menu, Icon } from 'semantic-ui-react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import InvoicesListContainer from 'components/invoices/InvoicesListContainer';
import InvoicesMenuContainer from 'components/invoices/InvoicesMenuContainer';
import Invoice from 'components/invoices/Invoice';

export default class Invoices extends React.Component {
  render () {
    return (
      <div>
        <Switch>
          <Route path={Routes.invoice_path({id: ":id", _options: true})} render={ props => (
            <Sidebar.Pushable as={Segment} className= "min-height-90">
              <InvoicesMenuContainer/>
              <Sidebar.Pusher className="left-sidebar-margin">
                <Invoice invoiceId={props.match.params.id}/>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
           )} />

          <Route path={Routes.invoices_path()} component={InvoicesListContainer}/>
        </Switch>
      </div>
    )
  }
}

