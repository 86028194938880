import { configureAdminStore } from "./redux_config";
import { configureSuperuserStore } from "./redux_config";
import { configureStore } from './redux_config'

// TODO Fix dom not loaded before store creation issue
// const preloadedStateJson = document.body.getAttribute('data-preloaded-state')
// const preloadedStateJson = null
// const preloadedState = preloadedStateJson ? JSON.parse(preloadedStateJson) : {}
//
// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = {}

let configuredStore

if(window.__SUPERUSER_MODE__) {
  configuredStore = configureSuperuserStore(preloadedState)
} else if(window.__ADMIN_MODE__) {
  configuredStore = configureAdminStore(preloadedState)
} else {
  configuredStore = configureStore(preloadedState)
}

const { store, persistor } = configuredStore

export default store;

