import OfficesListContainer from 'components/superuser/offices/OfficesListContainer';
import OfficesMenuContainer from 'components/superuser/offices/OfficesMenuContainer';
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Segment, Sidebar } from 'semantic-ui-react'

export default class Offices extends React.Component {
  render () {
    return (
      <div>
        <Switch>
          <Route path={Routes.superuser_office_path({id: ":id", _options: true})} render={ props => (
            <Sidebar.Pushable as={Segment} className= "min-height-90">
              <OfficesMenuContainer/>
              <Sidebar.Pusher className="left-sidebar-margin">
                {"Hier komt nog zorgverlener aanpassen"}
                {/* <OfficeForm entityId={props.match.params.id}/> */}
              </Sidebar.Pusher>
            </Sidebar.Pushable>
           )} />

          <Route path={Routes.superuser_offices_path()} component={OfficesListContainer}/>
        </Switch>
      </div>
    )
  }
}

