import TimelineNode from 'components/dossiers/dossierFollowUp/TimelineNode';
import React from 'react'
import { Button, Divider, Grid, Header, Image, Item, Menu, Segment } from "semantic-ui-react";
import EditModal from "../../forms/EditModal";
import DossierAttachmentForm from "./DossierAttachmentForm";

export default class AttachmentNode extends React.Component {
  render() {
    const { isSelected, time, nodeStyle } = this.props
    let dossierItemId = this.props.dossierItem.id
    let hrefString = Routes.download_api_dossier_item_path({ id: dossierItemId, _options: true })
    return (
      <TimelineNode icon={'attach'} type={'note'} {...this.props}>
        <Item as={Segment} raised={isSelected} clearing contentEditable={false} style={nodeStyle}>
          <Item.Content>
            <Grid divided>
              <Grid.Row>
                <Grid.Column width={9}>
                  <Header href={hrefString}>
                    {this.props.dossierItem.attachmentTitle}
                  </Header>
                  <Item.Description>{I18n.l("date.formats.short_human_readable", time)}</Item.Description>
                </Grid.Column>
                <Grid.Column width={5} floated='right' textAlign='right' className='dont-print'>
                  <Menu compact borderless secondary stackable>
                    <Menu.Item>
                      <EditModal entityName="dossierItem" EntityForm={DossierAttachmentForm} refreshAfterSave={true}
                                 entityId={this.props.dossierItem.id} trigger={(props) => {
                        return (<Button onClick={props.onClick} icon='pencil'/>)
                      }}
                      />
                    </Menu.Item>
                  </Menu>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Item.Content>
          {this.renderAttachment(hrefString)}
        </Item>
      </TimelineNode>)
  }

  renderAttachment(hrefString) {
    let url = this.props.dossierItem.attachmentExpiringUrl

    if(this.isImage(url)) {
      return (
        <Item.Content>
          <Divider/>
          <Image src={url} size="medium" href={hrefString}/>
        </Item.Content>)
    }
  }


  isImage(url) {
    if(typeof url !== 'string') return null
    return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }
}


