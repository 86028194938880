import DossierBasicInfo from 'components/dossiers/DossierBasicInfo'
import DossierFinancialInfo from 'components/dossiers/DossierFinancialInfo'
import DossierFollowUp from 'components/dossiers/dossierFollowUp/DossierFollowUp'
import RoutedTab from 'components/generics/RoutedTab'
import React from 'react'
import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'
import { findEntity } from 'selectors/entities'
import { fetchEntities } from "../../actions/entities.js";

class Dossier extends React.Component {
  componentDidMount () {
    this.props.fetchDossier();
  }
  render() {
    const { dossier } = this.props

    if (!dossier) {
      return <div>{I18n.t('components.dossier.not_loaded')}</div>
    }

    const panes = [
      { label: I18n.t('components.dossier.follow_up') , key:"follow-up-tab", render: () => <DossierFollowUp dossierId={dossier.id}/>, path: Routes.dossier_path({id: dossier.id, _options: true })},

      { label: I18n.t('components.dossier.basic_info'), key:"basic-info-tab", render: () => <DossierBasicInfo dossier={dossier}/> , path: Routes.dossier_info_path({id: dossier.id, _options: true })},

      { label: I18n.t('components.dossier.financial'), key:"financial-tab" , render: () => <DossierFinancialInfo dossier={dossier}/>, path: Routes.dossier_financial_path({id: dossier.id, _options: true }) }
    ]

    return (
      <RoutedTab panes={panes} archived={dossier.isArchived}/>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    dossier: findEntity(state, 'dossier', ownProps.dossierId)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchDossier: () => {
      dispatch(fetchEntities('dossiers', {name: 'dossier', filter: {id: ownProps.dossierId}, page: 1}))
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dossier));
