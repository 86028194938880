import { getJson } from 'api_helpers.js'

export const REQUEST_RESOURCES = 'REQUEST_RESOURCES';
export function requestResources() {
  return {
    type: REQUEST_RESOURCES
  };
}

export const RECEIVE_RESOURCES = 'RECEIVE_RESOURCES';
export function receiveResources(resources) {
  return {
    type: RECEIVE_RESOURCES,
    resources: resources
  };
}

export const FETCH_RESOURCES_FAILURE = 'FETCH_RESOURCES_FAILURE';
export function fetchResourcesFailure(error) {
  return {
    type: FETCH_RESOURCES_FAILURE,
    error: error
  };
}


export const FETCH_RESOURCES= 'FETCH_RESOURCES';
export function fetchResources() {
  return function(dispatch, getState) {

    if(getState().resources.list.length > 0) {
      return
    }

    var api_type = getState().api_type

    if (api_type === 'superuser') {
      var resourcesPath = Routes.superuser_api_resources_path()
    } else if(api_type === 'admin') {
      var resourcesPath = Routes.admin_api_resources_path()
    } else {
      var resourcesPath = Routes.api_resources_path()
    }

    dispatch(requestResources())

    return getJson(resourcesPath).then (
      response => response.json(),
      error => dispatch(fetchResourcesFailure(error))
    ).then (
      json => {
        dispatch(receiveResources(json.data));
      }
    )
  }
}
