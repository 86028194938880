import ContactForm from 'components/contacts/ContactForm'
import ContactsListItem from 'components/contacts/ContactsListItem'

import EntitiesList from 'components/entities/EntitiesList';
import { connect } from 'react-redux'

import { getContacts } from 'selectors/contacts'
import { Card } from 'semantic-ui-react'

const mapStateToProps = (state, ownProps) => {
  return {
    entities: getContacts(state),
    entityName: 'contact',
    item: ContactsListItem,
    form: ContactForm,
    listComponent: Card.Group,
    loading: state.meta.contact?.loading,
    error: state.meta.contact?.error
  };
}

export default connect(mapStateToProps)(EntitiesList)
