import React from 'react'
import { Icon, Label, Menu } from 'semantic-ui-react'
import Popup from 'popup-with-modal'
import { connect } from 'react-redux'
import { fetchEntities } from 'actions/entities';
import NotificationsList from 'components/notifications/NotificationsList'
import { getUnresolvedNotificationCount } from 'selectors/notifications'

import ScrollContext from 'contexts/ScrollContext'

class NotificationsMenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.scrollContext = React.createRef()
  }


  componentDidMount() {
    this.props.fetchEntities();
  }

  render() {
    const { notificationsCount } = this.props
    return (<Popup position='bottom right' size={'large'} flowing basic on='click'
                   trigger={<Menu.Item>
        <Icon.Group>
          <Icon size='large' name='alarm'>
          </Icon>
          {  notificationsCount > 0 ?
            <Label color='red' floating size='mini' circular>{notificationsCount}</Label> : null }
          </Icon.Group>
        </Menu.Item>}>
        <div style={{maxHeight: '80vh', overflowY: 'scroll' }} ref={this.scrollContext}>
          <ScrollContext.Provider value={this.scrollContext}>
            <NotificationsList/>
          </ScrollContext.Provider>
        </div>
      </Popup>)
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    notificationsCount: getUnresolvedNotificationCount(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('notifications', { name: 'menuItem', filter: {}, page: 1 } ))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsMenuItem);
