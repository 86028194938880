import orm from 'models'
import { createSelector as ormSelector } from 'redux-orm'
import { createSelector } from 'reselect'
import getEntities from 'selectors/entities'

export const getLocations = ormSelector(orm, getEntities, (session) => {
  return session.location.all().toModelArray().map(location => {
    const { ref } = location
    return { ...ref }
  })
})

export const getFirstLocationId = createSelector([getLocations], (locations) => {
  return locations[0] && locations[0].id
})
