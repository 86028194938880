import addressSchema from 'forms/addressSchema'

export const healthFundSchema = function(state, props) {
  const uiSchema = {
    "ui:readonly": props.readonly,
    "name": {
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:options": {
        width: 6
      }
    },
    ...addressSchema(state,props)
  }

  return uiSchema
}

export default healthFundSchema;
