import escapeHtml from "escape-html";
import React from 'react'
import { Text } from "slate";
import { createEditor } from "slate";
import { jsx } from 'slate-hyperscript'
import { Editable, Slate } from "slate-react";
import EditorElement from "./EditorElement";
import EditorLeaf from "./EditorLeaf";

export default class RichTextRender extends React.Component {
  render() {
    const renderElement = props => <EditorElement {...props} />
    const renderLeaf = props => <EditorLeaf {...props} />
    const editor = createEditor();
    return(
      <Slate editor={editor} value={deserialize(this.props.content)} >
        <Editable readOnly
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
      </Slate>
    );
  }
}

export const serialize = nodes => {
  return nodes.map(n => serializeNode(n)).join('')
}

const serializeNode = node => {

  const alignStatement = node.align ? ` align = "${node.align}"` : ``

  if (Text.isText(node)) {
    let string = escapeHtml(node.text)
    if (node.bold) {
      string = `<strong>${string}</strong>`
    }
    if (node.italic) {
      string = `<em>${string}</em>`
    }
    if (node.underline) {
      string = `<u>${string}</u>`
    }
    return string
  }

  const children = node.children.map(n => serializeNode(n)).join('')

  switch (node.type) {
    case 'block-quote':
      return `<blockquote><p${alignStatement}>${children}</p></blockquote>`
    case 'paragraph':
      return `<p${alignStatement}>${children}</p>`
    case 'heading-two':
      return `<h2${alignStatement}>${children}</h2>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    case 'list-item':
      return `<li>${children}</li>`
    default:
      return children
  }
}

const MENTION_REGEX = RegExp('(@mention{(.*?)})', 'gi');
export const deserialize = (el, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    let deserialized = []
    let skip
    for(let text of el.textContent.split(MENTION_REGEX)) {
      if (skip) {skip = false; continue;}
      skip = false
      if(text.includes('@mention')){
        let mention = text.replace('@mention{', '').replace('}','')
        deserialized.push(jsx( 'element', {identifier:mention , type: 'mention'}, jsx('text', markAttributes, text)))
        skip = true
      } else {
        deserialized.push(jsx('text', markAttributes, text))
      }
    }
    return deserialized

  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null
  }

  const nodeAttributes = { ...markAttributes }

  // define attributes for text nodes
  switch (el.nodeName) {
    case 'STRONG':
      nodeAttributes.bold = true
      break
    case 'EM':
      nodeAttributes.italic = true
      break
    case 'U':
      nodeAttributes.underline = true
      break
  }

  const children = Array.from(el.childNodes)
                        .map(node => deserialize(node, nodeAttributes))
                        .flat()

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''))
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children)
    case 'BR':
      return '\n'
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'block-quote', align: el.align }, children)
    case 'P':
      return jsx('element', { type: 'paragraph', align: el.align }, children)
    case 'H2':
      return jsx('element', { type: 'heading-two', align: el.align }, children)
    case 'OL':
      return jsx('element', { type: 'numbered-list' }, children)
    case 'UL':
      return jsx('element', { type: 'bulleted-list' }, children)
    case 'LI':
      return jsx('element', { type: 'list-item' }, children)
    default:
      return children
  }
}

