import React from 'react';

import { Grid, Icon, } from 'semantic-ui-react';

class Timeline extends React.Component {
  render() {
    const {icon, lineColor = 'grey', color = 'grey', children, last, first} = this.props;

    const isMobile = window.innerWidth <= 768;
    const iconSize = isMobile ? 'small' : 'large';

    return (
      <div>
        <Grid>
          <Grid.Row className={`Timeline-node ${last ? 'Timeline-last' : ''} ${first ? 'Timeline-first' : ''}`}>
            <Grid.Column width={1}>
              <div className="Timeline-line" style={{background: lineColor}}/>
              <Icon name={icon} size={iconSize} color={color} inverted circular
                    style={{boxShadow: `0 0 0 0.1em ${lineColor} inset`}} className="Timeline-icon"/>
            </Grid.Column>
            <Grid.Column width={15}>
              {children}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default Timeline;
