import React from 'react'
import { connect } from 'react-redux'

import { Icon, Menu } from 'semantic-ui-react'

import { updateEntity, upsertEntity } from 'actions/entities'

class MarkPayedItem extends  React.Component {

  render() {
    const { invoice } = this.props
    if (invoice.proposed) {return <span></span>}

    return  (<Menu.Item onClick={() => {this.props.togglePayedAt(invoice) } }>
      <Icon.Group size='large'>
        <Icon name='euro'/>
        { invoice.payedAt ?  <Icon corner name='check circle' color='green'/> : null }
      </Icon.Group>
    </Menu.Item>)
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    togglePayedAt: (invoice) => {
      if (invoice.payedAt) {
          dispatch(updateEntity('invoice', { id: invoice.id, payedAt: null}))
      } else {
          dispatch(updateEntity('invoice', { id: invoice.id, payedAt: new Date()}))
      }

      dispatch(upsertEntity('invoice', invoice.id))
    },
  };
}

export default connect(null, mapDispatchToProps)(MarkPayedItem);
