import React from 'react'
import { matchPath } from 'react-router'
import { NavLink } from 'react-router-dom'
import { Label } from "semantic-ui-react";
import { Menu, Tab } from 'semantic-ui-react'

class RoutedTab extends React.Component {
  render() {
    const { panes, archived } = this.props

    let tabPanes = panes.map(pane => {
      return {
        menuItem: <Menu.Item key={pane.key} as={NavLink} to={pane.path}>{pane.label}</Menu.Item>,
        render: () => {
          return <Tab.Pane basic>
            {archived ? <Label color='red' ribbon content={I18n.t('components.dossier.is_archived')}/> : null}
            {pane.render()}
          </Tab.Pane>
        }
      }
    })

    let defaultActiveIndex = 0

    panes.map((pane,index) => {
      if (matchPath(window.location.pathname, { path: pane.path, exact: true})) {
        defaultActiveIndex = index
      }
    })

    return <Tab menu={{secondary: true, pointing: true}} panes={tabPanes} className='min-height-90' defaultActiveIndex={defaultActiveIndex}/>
  }
}

export default RoutedTab;
