import React from 'react'
import { Checkbox } from 'semantic-ui-react'
import { actions } from 'react-redux-form'
import { connect } from 'react-redux'
import underscored from 'underscore.string/underscored'

import dotProp from 'dot-prop'

class SemanticCheckboxWidget extends React.Component {
  render() {
    const { checkboxValue, entityModel, options } = this.props

    return (<Checkbox toggle label={I18n.t(underscored(`${this.props.translationScope}.${this.props.id}`))} onChange={ (event, props) => {this.props.dispatch(actions.change(entityModel, this.props.checkedValue ? ( props.checked ? this.props.checkedValue : null ) : props.checked ))}} checked={checkboxValue}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var entityModel = `${ownProps.model || ''}.${ownProps.id}`
  var checkboxValue = dotProp.get(state,entityModel) || ownProps.options.emptyValue || false
  return {
    checkboxValue: checkboxValue,
    entityModel: entityModel
  }
}

export default connect(mapStateToProps)(SemanticCheckboxWidget);
