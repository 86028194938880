import { fetchEntity } from "actions/entities";
import { createEntityWithDefaults } from 'actions/entities';

import EditModal from 'components/forms/EditModal'
import dotProp from 'dot-prop'
import pluralize from "pluralize";
import React from 'react'

import ObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField'
import { connect } from 'react-redux'

import { actions } from "react-redux-form"
import { getPersistedEntities } from 'selectors/entities'

import { getUiOptions } from 'selectors/forms'
import { Dropdown, Form, Menu } from 'semantic-ui-react'
import underscored from 'underscore.string/underscored'
import NewEntityModal from "../../entities/NewEntityModal";

class SemanticSelectorField extends ObjectField {
  render() {
    const {
      uiSchema,
      entityName,
      name,
      required,
      readonly,
      entityId,
      EntityForm,
      selectOptions,
    } = this.props;

    const { "ui:options": options , "ui:placeholder": placeholder}   = uiSchema

    const { addable, editable } = (options || {})

    const selectedOption = selectOptions.find((option) => {option.key === entityId } )
    return (
      <Form.Field>
        {this.props.name === "id" ? <label htmlFor={name}>{I18n.t(underscored(this.props.translationScope))}{required ? "*" : null}</label>
        : <label htmlFor={this.props.name}>{I18n.t(`${this.props.translationScope}.${underscored(name)}`)}{required ? "*" : null}</label> }
        {readonly ?
          selectedOption && selectedOption.text || I18n.t('generic.unknown')
          : <React.Fragment>
            <Dropdown selection search options={selectOptions} onChange={this.props.handleChange}
                      value={entityId} fluid/>
            <Menu compact icon borderless secondary>
              {addable && EntityForm ?
                <NewEntityModal entityName={entityName} form={EntityForm} handleSubmitSuccess={(id) => {
                  this.props.handleChange(null, { value: id })
                }}/> : null}
              {(editable && entityId && EntityForm) ?
                <EditModal entityId={entityId} entityName={entityName} EntityForm={EntityForm}/> : null}
            </Menu>
          </React.Fragment>}

      </Form.Field>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  var entityModel = `${ownProps.model || ''}.${ownProps.name}`
  let options = ownProps.uiSchema["ui:options"];
  var entityName = options.entityName
  var fetchEntityAfterSelect = options.fetchEntityAfterSelect

  return {
    handleChange: (event, props) => {
      dispatch(actions.change(entityModel, props.value))
      if(fetchEntityAfterSelect){
        dispatch(fetchEntity(pluralize.plural(entityName), props.value))
      }
    },
    createEntityWithDefaults: () => {
      dispatch(createEntityWithDefaults(entityName))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  var options = getUiOptions(state,ownProps)
  var entityName = options.entityName

  const { displayAttribute, valueAttribute, includeBlank, EntityForm, selector, selectableOptions} = options

  var entityModel = `${ownProps.model || ''}.${ownProps.name}`
  var entityId = dotProp.get(state, entityModel)

  if (selectableOptions) {
    entityId = String(entityId)
    let translatableEntityName = underscored(entityName)
    // options for dropdown are passed directly to widget
    var optionsToDisplay = selectableOptions.map((option) => {
      return {key: option, value: option, text: I18n.t('api.forms.' + translatableEntityName  + '.options.' + option)}
    })
  } else {
    // options for dropdown are derived from entities
    let entities;
    if (selector) {
      entities = selector.call(null, state);
    } else {
      entities = getPersistedEntities(entityName)(state, ownProps);
    }

    optionsToDisplay = entities.map(mapEntitiesToSelectableOptions(displayAttribute, valueAttribute))
  }

  if (includeBlank) {
    optionsToDisplay.unshift({key: '', value: '', text: I18n.t('components.semantic_select_field.blank')})
  }

  return {
    entityId: entityId,
    entityName: entityName,
    entityModel: entityModel,
    selectOptions: optionsToDisplay,
    EntityForm: EntityForm,
  }
}

const mapEntitiesToSelectableOptions = (displayAttribute, valueAttribute) =>
{
  return entity => {
    let text = ''
    if (typeof displayAttribute === "function") {
      text = displayAttribute.call(null, entity)
    } else {
      text = entity[displayAttribute]
    }

    let value;
    if (typeof valueAttribute === "function") {
      value = valueAttribute.call(null, entity)
    } else {
      value = entity[valueAttribute || "id"]
    }

    return {key: entity.id, value: value, text: text}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SemanticSelectorField);
