import React from 'react'
import SchemaForm from 'components/forms/SchemaForm'
import { connect } from 'react-redux'
import { fetchEntities } from 'actions/entities'

import organisationSchema from 'forms/organisationSchema'

class OrganisationForm extends React.Component {
  componentDidMount() {
    this.props.fetchEntities()
  }

  render () {

    return (
      <SchemaForm entityName='organisation' {...this.props} />
      )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    uiSchema: organisationSchema(state, ownProps)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('countries'))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationForm);
