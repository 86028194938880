import { attr, fk } from 'redux-orm'
import Base from './Base'

class DossierSlice extends Base {
  static get fields() {
    return {
      id: attr(),
      createdAt: attr(),
      startTime: attr(),
      endTime: attr(),
      invoiceReceiver: attr(),
      communicationReceiver: attr(),
      dossierId: fk('dossier', 'dossierSlices'),
      locationId: fk('location', 'dossierSlices'),
      responsibleId :fk('employment', 'dossierSlices'),
      healthFundId :fk('healthFund')
    }
  }
}

DossierSlice.modelName = 'dossierSlice'

export default DossierSlice;
