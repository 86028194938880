import { createSelector } from 'reselect'
import { createSelector as ormSelector } from 'redux-orm'
import orm from 'models'
import getEntities from 'selectors/entities'

export const getOrganisations = ormSelector(orm, getEntities, (session) => {
  return session.organisation.all().toModelArray().map(organisation => {
    const { ref } = organisation
    return { ...ref}
  })
})
