import React from 'react'

import { Form, Icon } from 'semantic-ui-react'
import { actions } from 'react-redux-form'
import { connect } from 'react-redux'
import Editor from 'draft-js-plugins-editor';
import { EditorState, EditorChangeType } from 'draft-js';
import createTagPlugin, { TagSuggestions } from 'components/draft/draft-js-tag-plugin';
import createMarkdownShortcutsPlugin from 'draft-js-markdown-shortcuts-plugin';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { stateToMarkdown } from 'draft-js-export-markdown';

import dotProp from 'dot-prop'

class SemanticDraftWidget extends React.Component {

  constructor(props) {
    super(props);

    this.tagPlugin = createTagPlugin( {allowedTags: props.allowedTags});
    this.markdownShortcutsPlugin = createMarkdownShortcutsPlugin();
    this.state = {editorState: EditorState.createEmpty()}
  }

  handleChange = (editorState) => {
    this.setState({editorState})
    this.props.dispatch(actions.change(this.props.entityModel, stateToMarkdown(editorState.getCurrentContent())))
  }

  render() {
    const { editorMarkdown, entityModel } = this.props

    let editorState = null
    if (stateToMarkdown(this.state.editorState.getCurrentContent()) !== editorMarkdown)  {
      let contentState = stateFromMarkdown(editorMarkdown)
      editorState = EditorState.push(this.state.editorState, contentState, 'change-block-data')
    } else {
      editorState = this.state.editorState
    }

    const plugins = [this.tagPlugin, this.markdownShortcutsPlugin]
    return (
      <div>
        <Editor editorState={editorState} spellCheck={true} onChange={this.handleChange} plugins={plugins} ref={(element) => { this.editor = element; }} />
        {/* <TagSuggestions suggestions={[]}/> */}

      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var entityModel = `${ownProps.model || ''}.${ownProps.id}`

  var editorMarkdown = dotProp.get(state,entityModel) || '';

  var resource = state.resources.list.find((resource) => resource["attributes"].title === ownProps.formContext.entityName);

  const allowedTags = resource["attributes"].allowedTags

  return {
    editorMarkdown: editorMarkdown,
    entityModel: entityModel,
    allowedTags: allowedTags
  }
}

export default connect(mapStateToProps)(SemanticDraftWidget);
