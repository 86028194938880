import { fetchEntities } from 'actions/entities';
import EmailTemplateBatchForm from 'components/email_templates/EmailTemplateBatchForm';
import React from 'react'


import { connect } from 'react-redux'

import { getEmailTemplates } from 'selectors/email_templates'
import ConsultationReminders from "./ConsultationReminders.jsx";

class EmailTemplates extends React.Component {
  componentDidMount() {
    this.props.fetchEntities()
  }

  render () {
    const { emailTemplates } = this.props
    return ([<ConsultationReminders/>, <EmailTemplateBatchForm/>])
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    emailTemplates: getEmailTemplates(state, ownProps),
    loading: state.meta.emailTemplate.loading,
    error: state.meta.emailTemplate.error
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('emailTemplates'))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates)
