import React from 'react'
import ui from 'redux-ui'
import { Card, Icon } from 'semantic-ui-react'


class CollapsableCard extends React.Component {
  render() {
    const { title } = this.props
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header onClick={() => this.props.updateUI('isCollapsed', !this.props.ui.isCollapsed)}>
            {this.props.ui.isCollapsed ? <Icon name="caret right"></Icon> : <Icon name="caret down"></Icon>}
            {title}
          </Card.Header>
          {!this.props.ui.isCollapsed ? this.props.children : null}
        </Card.Content>
      </Card>
    )
  }
}

export default (ui({ state: { isCollapsed: true } })(CollapsableCard));
