import { updateEntity, upsertEntity } from 'actions/entities'
import GenerateConsultationErrorsListItem
  from 'components/generate_consultation_errors/GenerateConsultationErrorsListItem'
import React from 'react'
import { connect } from 'react-redux'
import MessageListItem from "./MessageListItem.jsx";


class NotificationsListItem extends React.Component {
  render() {
    const { notification } = this.props
    const error = notification.generateConsultationError
    if(notification.notificationType === 'generate_consultation_error' && error) {
      return <GenerateConsultationErrorsListItem notification={notification} generateConsultationError={error}/>
    } else if(notification.notificationType === 'consultation_reminder_no_user'){
      return(<MessageListItem notification={notification} updateNotification={this.props.updateNotification}/>)
    } else {
      return null
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateNotification: (resolutionType, notificationId, matchId = null) => {
      if(matchId) {
        dispatch(updateEntity('notification', { id: notificationId, resolutionType: resolutionType, matchId: matchId }))
      } else {
        dispatch(updateEntity('notification', { id: notificationId, resolutionType: resolutionType }))
      }

      dispatch(upsertEntity('notification', notificationId))
    }
  }
}

export default connect(null, mapDispatchToProps)(NotificationsListItem);
