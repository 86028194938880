import DestroyModal from 'components/generics/DestroyModal'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Card, Icon } from 'semantic-ui-react'

export default class ConsultationTypesListItem extends React.Component {
  handleDestroy = () => {
    this.props.destroyEntity(this.props.consultationType.id)
  }

  render () {

    const consultationType = this.props.consultationType
    return (
      <Card link key={consultationType.id}>
        <Card.Content as={NavLink} to={Routes.superuser_consultation_type_path({id: consultationType.id, _options: true})}>
          <Card.Header>
            {consultationType.name}
          </Card.Header>
          { consultationType.createdAt ?
          <Card.Meta>
            {I18n.t('components.consultation_type_list_item.since', { date: I18n.l("date.formats.default",consultationType.createdAt) })}
          </Card.Meta> :
              null
          }
        </Card.Content>
        <Card.Content extra>
          <Icon name={consultationType.icon || 'question'} size='large'/>
          <DestroyModal handleDestroy={this.handleDestroy} trigger={
            (props) => {
              return (
                <Button color='red' size='mini' compact floated='right' inverted onClick={props.onClick}>
                  <Icon name='trash'/> {I18n.t('components.consultation_type_list_item.destroy')}
                </Button>)
            }}>
            {I18n.t('components.consultation_type_list_item.destroy_warning')}
          </DestroyModal>
        </Card.Content>
    </Card>)
  }
}

