export const consultationTypeSchema = function(state, props) {
  return {
    "name": {
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:options": {
        width: 2
      }
    },
    "icon": {
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:options": {
        width: 2
      }
    },
  }
}

export default consultationTypeSchema;
