import React from 'react'
import { connect } from "react-redux";
import { Card, Icon, Menu } from 'semantic-ui-react'
import Popup from "../../popup-with-modal";
import ui from "../../redux-ui.js";
import { findEntity } from "../../selectors/entities/index.js";
import EditModal from "../forms/EditModal";
import EventForm from "./EventForm";

class Event extends React.Component {
  componentDidMount() {
  }

  onClose() {
    this.props.updateUI('eventOpen', null);
  }

  render() {
    const { event, consultation, patient } = this.props
    let open = (this.props.ui.eventOpen === event.id)
    return (
      <div>
        <Popup as={'div'} on="click" position="bottom right" style={{ padding: '0em' }}
               open={open} onClose={() => this.onClose()}
               trigger={<div>
                 {consultation && consultation.consultationType && event.linking_status !== 'ignored' ?
                   <Icon name={consultation.consultationType.icon}/>
                   : null
                 }
                 {event.summary}
               </div>}>
          <Card fluid>
            <Card.Content>
              <Card.Header>
                {event.summary}
              </Card.Header>
              <Card.Meta>
                <div>
                  <Icon name="clock"/>
                  {event.startTime && I18n.l("time.formats.hour", event.startTime)}
                  -
                  {event.endTime && I18n.l("time.formats.hour", event.endTime)}
                </div>
              </Card.Meta>
              {consultation && consultation.consultationType ?
                <Card.Description>
                  {consultation.consultationType.name}
                </Card.Description> : null}

              {patient ?
                <Card.Description>
                  {I18n.t('components.event.linked_dossier') + patient.firstName + " " + patient.lastName}
                </Card.Description> : null}
            </Card.Content>
            <Card.Content extra>
              <Menu compact icon borderless secondary size="large">
                <EditModal entityName="event"
                           entityId={event.id}
                           EntityForm={EventForm}
                />
                {/*<Menu.Item icon='trash'/>*/}
              </Menu>
            </Card.Content>
          </Card>
        </Popup>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let consultationId = ownProps.event.consultationId
  let consultation = findEntity(state, "consultation", consultationId)
  if(consultation && consultation.deleted) consultation = null
  let dossier = consultation ? findEntity(state, "dossier", consultation.dossierId) : null
  let patient = dossier ? findEntity(state, "contact", dossier.patientId) : null

  return {
    consultation: consultation,
    patient: patient
  }
}


export default ui({ key: 'eventsCalendar' })(connect(mapStateToProps, null)(Event));


