import SchemaForm from 'components/forms/SchemaForm'
import React from 'react'
import { connect } from 'react-redux'
import employmentSchema from "../../forms/employmentSchema";

class EmploymentForm extends React.Component {
  render() {
    return (
      <SchemaForm entityName="employment" uiSchema={this.props.uiSchema}
                  entityId={this.props.entityId}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    uiSchema: employmentSchema(state, ownProps)
  }
}


export default connect(mapStateToProps)(EmploymentForm);
