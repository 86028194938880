import { fetchEntities } from 'actions/entities';
import EntitiesMenu from 'components/entities/EntitiesMenu';
import React from 'react'
import { connect } from 'react-redux'
import { getConsultationTypes } from 'selectors/consultation_types'


const mapStateToProps = (state, ownProps) => {
  return {
    entities: getConsultationTypes(state,ownProps),
    entityName: 'consultationType',
    item: (props) => <div>{`${props.consultationType.name}`}</div>,
    loading: state.meta.consultationType.loading,
    error: state.meta.consultationType.error,
    apiType: 'superuser'
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('consultationTypes'))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesMenu);
