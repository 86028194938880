import ContactForm from 'components/contacts/ContactForm'
import React from 'react'

import { connect } from 'react-redux'

import { findEntity } from 'selectors/entities'
import { Grid, Header, Label, Segment } from 'semantic-ui-react'
import { fetchEntities } from "../../actions/entities.js";

class Contact extends React.Component {

  componentDidMount () {
    this.props.fetchContact();
  }

  render() {
    const { contact } = this.props

    if (!contact) {
      return <div/>
    }

    return (
      <Segment className='min-height-90'>
          <Header dividing>
            <Grid columns={2}>
              <Grid.Row verticalAlign='middle'>
                <Grid.Column width={14}>
                  <Header as='h3'>
                    {`${I18n.t('components.contact.title')}: ${contact.firstName} ${contact.lastName}`}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
        {contact.isArchived ? <Label color='red' ribbon content={I18n.t('components.contact.is_archived')}/> : null}
        <ContactForm entityId={this.props.contactId}/>
       </Segment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contact: findEntity(state,'contact', ownProps.contactId)
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContact: () => {
      dispatch(fetchEntities('contacts', {name: 'contact', filter: {id: ownProps.contactId}, page: 1}))
      dispatch(fetchEntities('contactSlices', {name: 'contact', filter: {contactId: ownProps.contactId}, page: 1}))
    },
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Contact);
