/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


import { Notifier } from '@airbrake/browser';
// Support component names relative to this directory:
// import "./routes_whitelist";
//
import '@babel/polyfill';
import '@rails/ujs';
import browserUpdate from 'browser-update';
//
import "cable";
import "stylesheets/semantic-ui-overrides";
import "stylesheets/semantic_ui_less/compiled";

const airbrake = new Notifier({projectId: 492538,
                                  projectKey: '0a743bbb8d29ed883a63dbcd0cf1bf9c'
                              });

airbrake.addFilter((notice) => {
  let environment = process.env.RAILS_ENV
  if(environment === 'development'){
    return null;
  }
  notice.context.environment = environment;
  return notice;
});

var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

document.addEventListener("DOMContentLoaded", function(event) {
  browserUpdate({
      l: I18n.locale,
      style: 'corner',
      required:{
          e: -2,
          f: -2,
          o: -2,
          o_a: -2,
          s: -1,
          c: -2,
          y: -2,
          v: -2 ,
          uc: -2,
          samsung: -2
      }
  });
});
