import React from 'react'
import SchemaForm from 'components/forms/SchemaForm'
import { connect } from 'react-redux'
import { fetchEntities } from 'actions/entities'

class ConsultationForm extends React.Component {
  componentDidMount() {
    this.props.fetchEntities();
  }

  handleSubmit(dossier) {
    this.props.createConsultation(dossier)
  }

  render () {
    const uiSchema = {
      "relationships": {
        "event": {
          "data": {
            "id": {
              "ui:field": "NestedFormField",
              "ui:options": {
                "entityName": "event"
              },
              "ui:schema": {
                "attributes" : {
                  "startTime": {
                    "ui:options": {
                      "width": 3,
                    },
                    "ui:widget": "date-time",
                  },
                  "duration": {
                    "ui:widget": "TimeDurationWidget",
                    "ui:options": {
                      "width": 3,
                    },
                  },
                },
                "relationships": {
                  "consultation" : {
                    "data": {
                      "id": {
                        "ui:widget": "hidden"
                      }
                    }
                  },
                  "calendar" : {
                    "data": {
                      "id": {
                        "ui:field": "SelectorField",
                        "ui:options": {
                          "entityName": "calendar",
                          "displayAttribute": (calendar) => `${calendar.summary}`
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "consultationType": {
          "data": {
            "id": {
              "ui:field": "SelectorField",
              "ui:options": {
                "entityName": "consultationType",
                "displayAttribute": (consultationType) => `${consultationType.name}`,
              }
            }
          }
        },
        "practioner": {
          "data": {
            "id": {
              "ui:field": "SelectorField",
              "ui:options": {
                "entityName": "employment",
                "displayAttribute": (employment) => `${employment.user.firstName} ${employment.user.lastName}`,
              }
            }
          }
        },
        "dossier": {
          "data": {
            "id": {
              "ui:field": "SelectorField",
              "ui:options": {
                "entityName": "dossier",
                "displayAttribute": (dossier) => `${dossier.patient.firstName} ${dossier.patient.lastName}`
              }
            }
          }
        },
        "receipt": {
          "data": {
            "id": {
                "ui:field": "NestedFormField",
                "ui:options": {
                  "entityName": "receipt",
                  "displayTitle": true,
                },
                "ui:schema": {
                  "attributes": {
                    "identification": {
                        "ui:options": {
                          "width": 3,
                        },
                    }
                  }
                }
            }
          }
        }
      }
    }

    return ( <SchemaForm entityName='consultation' uiSchema={uiSchema} {...this.props}/>
      )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('calendars'))
      dispatch(fetchEntities('employments'))
      dispatch(fetchEntities('consultationTypes'))
    },
  }
}

export default connect(null, mapDispatchToProps)(ConsultationForm);
