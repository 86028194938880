import React from 'react'
import { connect } from 'react-redux'
import SchemaForm from "../../forms/SchemaForm";
import dossierItemSchema from "../../../forms/dossierItemSchema";
import dotProp from "dot-prop";

class DossierItemForm extends React.Component {

  render() {
    return (
      <SchemaForm entityName='dossierItem' {...this.props}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let dossierItemType = dotProp.get(state, `entities.dossierItem.byId.${ownProps.entityId}.dossierItemType`)
  return {
    uiSchema: dossierItemSchema(state, ownProps, dossierItemType)
  }
}

DossierItemForm.defaultProps = {
  submitButton: true
}

export default connect(mapStateToProps)(DossierItemForm);
