import React from 'react'
import { List, Segment } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

export default class LocationsListItem extends React.Component {

  render () {
    const location = this.props.location
    return (
      <List.Item key={location.id} as={Segment} basic clearing>
        <NavLink to={Routes.location_path({ id: location.id, _options: true})}>
          <List.Content as={Segment}>
            <List.Header>{location.name}</List.Header>
            <List.Description></List.Description>
          </List.Content>
        </NavLink>
      </List.Item>
    )
  }
}

