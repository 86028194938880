import { SET_GLOBAL_ERROR } from 'actions/errors'

const initialState = {
  error: null,
}

export default function errors(state = initialState, action) {
  switch (action.type) {
    case SET_GLOBAL_ERROR:

      return { error: action.payload};
  }
  return state
}
