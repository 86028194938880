import { fk, attr } from 'redux-orm'
import Base from './Base'

class Calendar extends Base {
  static get fields() {
    return {
      id: attr(),
      calendarIdentification: attr(),
      summary: attr(),
      description: attr(),
      calendarType: attr(),
      color: attr()
    }
  }
}

Calendar.modelName = 'calendar'

export default Calendar;
