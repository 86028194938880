import React from 'react'
import { Grid, Icon, Menu, Sidebar } from 'semantic-ui-react'
import { Link, NavLink } from 'react-router-dom'

export default class ManagementMenu extends React.Component {
  render () {
    return (<Sidebar as={Grid} visible={true} direction="bottom" animation="push" icon='labeled' size="massive" stackable={true} padded className='compact management-menu'>
      <Grid.Row>
        <Grid.Column width={5}>

          <Menu position='left' size='massive' widths={4} icon='labeled' fluid className="overview" color='yellow' inverted>
            <Menu.Item as={ NavLink } to={Routes.compass_path()} float='left'>
              <Icon name='compass'/>
              {I18n.t('components.main_app.menu.today')}
            </Menu.Item>
            <Menu.Item as={ NavLink } to={Routes.consultations_path()}>
              <Icon name='calendar outline'/>
              {I18n.t('components.main_app.menu.consultations')}
            </Menu.Item>
            <Menu.Item as={ NavLink } to={Routes.dossiers_path()}>
              <Icon name='user circle outline'/>
              {I18n.t('components.main_app.menu.dossiers')}
            </Menu.Item>
            <Menu.Item as={ Link } to={Routes.invoices_path()} className='active' color='yellow'>
              <Icon name='calculator'/>
              {I18n.t('components.main_app.menu.management')}
            </Menu.Item>
          </Menu>
        </Grid.Column>
        <Grid.Column width={11}>
          <Menu position='right' widths={2} size='massive' icon='labeled' fluid>
            <Menu.Item as={ NavLink } to={Routes.invoices_path()}  color='yellow'>
              <Icon name='book'/>
              {I18n.t('components.management_menu.menu.invoices')}
            </Menu.Item>
            {/* <Menu.Item as={ NavLink } to={Routes.receipts_journal_path()} color='yellow'>
              <Icon name='list ol'/>
              {I18n.t('components.management_menu.menu.receipts_journal')}
            </Menu.Item> */}
            <Menu.Item as={ NavLink } to={Routes.contacts_path()} color='yellow'>
              <Icon name='address book outline'/>
              {I18n.t('components.management_menu.menu.contacts')}
            </Menu.Item>
            {/*<Menu.Item as={ NavLink } to={Routes.organisations_path()} color='yellow'>
              <Icon name='building'/>
              {I18n.t('components.management_menu.menu.organisations')}
            </Menu.Item>*/}
          </Menu>
        </Grid.Column>
      </Grid.Row>
    </Sidebar>)
  }
}
