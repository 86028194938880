import EventsCalendarContainer from 'components/agenda/EventsCalendarContainer'
import ConsultationsListContainer from 'components/consultations/ConsultationsListContainer'
import React from 'react'
import { connect } from "react-redux";
import { fetchEntities } from "../../actions/entities.js";

class Agenda extends React.Component {
  state = { visible: true }

  componentDidMount () {
    this.props.fetchEntities();
  }

  toggleVisibility = () => this.setState({ visible: !this.state.visible })

  render () {
    return (
      <div>
        <EventsCalendarContainer/>
        <ConsultationsListContainer keyWindow='eventsCalendar'/>
        {/* <Switch>
          <Route path={Routes.consultation_path({id: ':id', _options: true })} render={ props => (
            <Sidebar.Pushable as={Segment} className= "min-height-90">
              <ConsultationsMenuContainer keyWindow='eventsCalendar'/>
              <Sidebar.Pusher className="left-sidebar-margin">
                <Consultation consultationId={props.match.params.id}/>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
           )} />

         <Route path={Routes.consultations_path()} render={ () => <ConsultationsListContainer keyWindow='eventsCalendar'/>}/>
        </Switch> */}
      </div>
    )
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      //fetch the calendars, not because we actually need the calendars, but because it triggers subbing to the channels
      dispatch(fetchEntities('calendars'))
    }
  };
}

export default connect(null, mapDispatchToProps)(Agenda);
