const templateSchema = function(state, props) {
  return {
    "subject": {
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:options": {
        width: 6
      }
    },
    "body": {
      "ui:widget": "HtmlWidget",
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:options": {
        width:12,
        mentionOptions: ["patient_first_name", "patient_last_name", "consultation_date", "consultation_hour"]
      },
    },
    "emailType":  {
      "ui:widget" : "hidden"
    },
    "officeId": {
      "ui:widget" : "hidden"
    }
  }

}

const consultationReminderSchema = function(state, props) {
  const uiSchema = {
    "ui:options": {
      "entityName": "consultationReminder",
    },
    "ui:order": ["active", "minutesNotice" , "*"],
    "emailTemplateId": {
      "ui:field": "NestedFormField",
      "ui:options": {
        "entityName": "emailTemplate",
        "displayTitle": false,
        "inline": true
      },
      "ui:schema": templateSchema(state, props)
    },
    "minutesNotice":{
      "ui:field": "SelectorField",
      "ui:options": {
        "entityName": "consultationReminder",
        "width": 3,
        "selectableOptions": ['30', '60', '120', '1440', '2880', '4320'],
      }
    }
  }

  return uiSchema
}

export default consultationReminderSchema;
