import { createSelector } from 'reselect'
import { createSelector as ormSelector } from 'redux-orm'
import getEntities from 'selectors/entities'
import orm from 'models'
import { getKeyWindowSelector } from 'selectors/key_windows'

import _ from 'lodash'

const getDossier = (state, props) => props.dossier

export const getInvoicesById = state => state.entities.invoice.byId

export const getInvoices = ormSelector(orm, getEntities, (session) => {
  return session.invoice.all().toModelArray().map(invoice => {
    const { ref } = invoice
    let dossier = session.dossier.withId(invoice.dossierId)
    let patient = null;
    let responsible = null;
    let location = null;
    let locationResponsible = null

    let dossierRef = null;
    let patientRef = null;
    let responsibleRef = null;
    let locationRef = null;
    let locationResponsibleRef = null;

    if (dossier) {
      patient = session.contact.withId(dossier.patientId)
      dossierRef = dossier.ref
      responsible = session.employment.withId(dossier.responsibleId)
      location = session.location.withId(dossier.locationId)
    }

    if (patient) {
      patientRef = patient.ref
    }

    if (responsible) {
      responsibleRef = responsible.ref
    }

    if (location) {
      locationRef = location.ref
      locationResponsible = session.employment.withId(location.responsibleId)
    }

    if (locationResponsible) {
      locationResponsibleRef = locationResponsible.ref
    }

    return { ...ref,
      dossier: dossier,
      patient: patientRef,
      emailCommunications: invoice.emailCommunications?.toRefArray()
    }
  })
})

export const getSortedInvoicesForMenu = createSelector([getKeyWindowSelector('invoice', 'menu', getInvoices)],
  (invoices) => { return ( _.reverse(_.sortBy(invoices, ['formattedIdentification', (invoice) => invoice.patient?.lastName ] )))
})

export const getSortedInvoices = createSelector([getKeyWindowSelector('invoice', 'list', getInvoices)],
  (invoices) => { return ( _.reverse(_.sortBy(invoices, ['formattedIdentification', (invoice) => invoice.patient?.lastName ] )))
})

export const getInvoicesForDossier = createSelector([getKeyWindowSelector('invoice', 'dossier', getInvoices)], (invoices) => { return ( _.reverse(_.sortBy(invoices, ['formattedIdentification', (invoice) => invoice.patient?.lastName ] )))
})

