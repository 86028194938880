import { fk, attr } from 'redux-orm'
import Base from './Base'

class ConsultationType extends Base {
  static get fields() {
    return {
      id: attr(),
      name: attr(),
      icon: attr(),
      createdAt: attr(),
    }
  }
}

ConsultationType.modelName = 'consultationType'

export default ConsultationType;
