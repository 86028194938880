import {
  CLEAR_SEARCH,
  FETCH_SEARCH_RESULTS_FAILURE,
  RECEIVE_SEARCH_RESULTS,
  REQUEST_SEARCH_RESULTS,
  SET_SEARCH_ARCHIVES
} from 'actions/search_results'

const initialState = {
  results: {},
  loading: false,
  error: null,
  value: "",
  searchArchives: false
}

export default function search_results(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SEARCH_RESULTS:// start fetching search_results and set loading = true
      return { ...state,  ...{results: {}, error: null, loading: true, value: action.query}};
    case RECEIVE_SEARCH_RESULTS:// return list of search_results and make loading = false
      return { ...state, ...{results: action.results, error:null, loading: false}};
    case FETCH_SEARCH_RESULTS_FAILURE:// return error and make loading = false
      const error = "request failed";  // action.error || {message: action.error.message};//2nd one is network or server down errors
      return { ...state,  ...{results: {}, error: error, loading: false}};
    case SET_SEARCH_ARCHIVES:
      return { ...state, ...{searchArchives: action.searchArchives}};
    case CLEAR_SEARCH:
      return { ...state, ...{value: ""}};

  }
  return state
}
