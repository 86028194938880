import React from 'react'
import { Segment } from 'semantic-ui-react'

import ConsultationForm from 'components/consultations/ConsultationForm'

export default class Consultation extends React.Component {
  render () {
    return (
      <div>
         <ConsultationForm entityId={this.props.consultationId}/>
       </div>
    )
  }
}
