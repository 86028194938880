import React from 'react'
import SchemaForm from 'components/forms/SchemaForm'
import { connect } from 'react-redux'
import { fetchEntities } from 'actions/entities'
import locationSchema from 'forms/locationSchema'

class LocationForm extends React.Component {
  componentDidMount() {
    this.props.fetchEntities()
  }

  render () {
    return (
      <SchemaForm entityName='location' {...this.props}/>
      )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    uiSchema: locationSchema(state, ownProps)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('countries'))
      dispatch(fetchEntities('employments'))
    }
  }
}

LocationForm.defaultProps = {
  submitButton: true
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationForm);
