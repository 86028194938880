import SchemaForm from 'components/forms/SchemaForm'
import dotProp from "dot-prop";
import React from 'react'
import { connect } from 'react-redux'
import { Header, Segment } from "semantic-ui-react";
import { createEntityWithDefaults } from "../../actions/entities.js";
import { fetchEntities } from "../../actions/entities.js";
import consultationReminderSchema from "../../forms/consultationReminderSchema.js";

class ConsultationReminders extends React.Component {
  componentDidMount() {
    this.props.fetchEntities()
  }


  render() {
    if(this.props.entityId) {
      return (
        <Segment basic>
          <Header dividing as="h2">{"Afspraakherinneringen"} </Header>
          <SchemaForm entityName="consultationReminder" {...this.props}/>
        </Segment>
      )
    } else {
      return <div></div>
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  let firstId = dotProp.get(state, 'entities.consultationReminder.allIds')[0]
  let rem = firstId && dotProp.get(state, `entities.consultationReminder.byId.${firstId}`)
  return {
    uiSchema: consultationReminderSchema(state, ownProps),
    entityId: rem && rem.id
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('consultationReminders'))
      dispatch(fetchEntities('emailTemplates'))
    },
    // createEntityWithDefaults: (props) => {
    //
    //   let id = dispatch(createEntityWithDefaults('consultationReminder'))
    //   return id;
    // }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ConsultationReminders);
