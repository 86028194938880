import { upsertEntity } from 'actions/entities'

import { fetchResources } from 'actions/resources';
import { setSetting } from 'actions/settings'
import SemanticArrayField from 'components/forms/fields/SemanticArrayField'
import SemanticBooleanField from 'components/forms/fields/SemanticBooleanField'

import SemanticFieldTemplate from 'components/forms/fields/SemanticFieldTemplate'

import SemanticNestedFormField from 'components/forms/fields/SemanticNestedFormField'

import SemanticObjectField from 'components/forms/fields/SemanticObjectField'
import SemanticSchemaField from 'components/forms/fields/SemanticSchemaField'
import SemanticSelectorField from 'components/forms/fields/SemanticSelectorField'
import SemanticSliceField from 'components/forms/fields/SemanticSliceField'

import SemanticStringField from 'components/forms/fields/SemanticStringField'
import SemanticButtonSelectWidget from 'components/forms/widgets/SemanticButtonSelectWidget'
import SemanticCheckboxDateWidget from 'components/forms/widgets/SemanticCheckboxDateWidget'
import SemanticCheckboxWidget from 'components/forms/widgets/SemanticCheckboxWidget'
import SemanticColorWidget from 'components/forms/widgets/SemanticColorWidget'
import SemanticDateWidget from 'components/forms/widgets/SemanticDateWidget'
import SemanticDraftWidget from 'components/forms/widgets/SemanticDraftWidget'
import SemanticFileWidget from 'components/forms/widgets/SemanticFileWidget'

import SemanticGeocoderWidget from 'components/forms/widgets/SemanticGeocoderWidget'
import SemanticNumberWidget from 'components/forms/widgets/SemanticNumberWidget'
import SemanticPriceWidget from 'components/forms/widgets/SemanticPriceWidget'
import SemanticRichTextWidget from "components/forms/widgets/SemanticRichTextWidget";
import SemanticSelectWidget from 'components/forms/widgets/SemanticSelectWidget'

import SemanticTextWidget from 'components/forms/widgets/SemanticTextWidget'
import SemanticUpDownWidget from 'components/forms/widgets/SemanticUpDownWidget'

import history from 'custom_history'

import dotProp from 'dot-prop'

import pluralize from 'pluralize'
import React from 'react'
import { connect } from 'react-redux'

import { actions as formActions } from 'react-redux-form'
import ui from 'redux-ui'

import Form from "rjsf-enter-no-submit"

import { entitiesRoute } from 'route_helpers'

import { getEntityName, getSchema } from 'selectors/forms'
import { Button, Divider, Icon, Segment } from 'semantic-ui-react'
import SemanticMaskedTextWidget from "./widgets/SemanticMaskedTextWidget";
import SemanticTimeDurationWidget from "./widgets/SemanticTimeDurationWidget";

var initialized = false

class SchemaForm extends React.Component {

  componentDidMount() {
    this.props.fetchResources();
  }

  componentDidUpdate() {
    {
        if (!initialized) {
            this.props.initializeForm(this.props.schema);
            initialized = true
        }
    }
  }

  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
  }

	submitForm() {
  	this.submitButton.click();
  }

  setDropzone = (dropzone) => {
    this.props.updateUI("dropzone", dropzone)
  };

  renderForm() {
    const customFields = {
      ObjectField: SemanticObjectField,
      SchemaField: SemanticSchemaField,
      StringField: SemanticStringField,
      BooleanField: SemanticBooleanField,
      NestedFormField: SemanticNestedFormField,
      SelectorField: SemanticSelectorField,
      ArrayField: SemanticArrayField,
      SliceField: SemanticSliceField,
    }

    const customWidgets = {
      DateTimeWidget: (props) => <SemanticDateWidget type="datetime" {...props}/>,
      TimeWidget: (props) => <SemanticDateWidget type="time" {...props}/>,
      DateWidget: (props) => <SemanticDateWidget type="date" {...props}/>,
      BirthdayWidget: (props) => <SemanticDateWidget type="date" startMode="year" {...props}/>,
      TimeDurationWidget: SemanticTimeDurationWidget,
      TextWidget: SemanticTextWidget,
      MaskedTextWidget: SemanticMaskedTextWidget,
      RichTextWidget:SemanticRichTextWidget,
      HtmlWidget:(props) => <SemanticRichTextWidget serializeToHtml {...props}/>,
      PriceWidget: SemanticPriceWidget,
      NumberWidget: SemanticNumberWidget,
      CheckboxWidget: SemanticCheckboxWidget,
      CheckboxDateWidget: SemanticCheckboxDateWidget,
      DraftWidget: SemanticDraftWidget,
      ColorWidget: SemanticColorWidget,
      SelectWidget: SemanticSelectWidget,
      UpDownWidget: SemanticUpDownWidget,
      ButtonSelectWidget: SemanticButtonSelectWidget,
      FileWidget: SemanticFileWidget,

      GeocoderWidget: SemanticGeocoderWidget,
    }

    const { model, entitiesName, entityName, entityId, formState, errors, readonly} = this.props;

    let isPending = ( formState === "pending")
    let isSubmitted = ( formState === "submitted")
    let isSubmitFailed= ( formState === "submitFailed")

    return (<Segment key={`form-${entityId}`} basic loading={isPending}>
                <Form fields={customFields} widgets={customWidgets}  submitButton={this.submitButton} formContext={{entity: model, entityName: entityName, entityId: entityId, errors: errors, setDropZone: this.setDropzone}} FieldTemplate={SemanticFieldTemplate} className={`ui form ${isSubmitted ? "success" : ""} ${isSubmitFailed ? "failed" : ""}`}  {...{...{ onSubmit: () =>  { this.props.handleSubmit(this.props.afterSaveNavigation)}},...this.props}} model={model} autoComplete='off'>
                  { !this.props.readonly &&  this.props.SubmitButton &&  <button ref={(btn) => {this.submitButton=btn;}} style={{display: 'none'}}/> }
             { !this.props.readonly && !this.props.SubmitButton && this.renderStandardSubmitButton()  }
              </Form>
            </Segment>
      )
  }

  renderStandardSubmitButton() {
    return [
      <Divider key={`divide_${this.props.entityId}`}/>,
      <Button key={`button_${this.props.entityId}`} animated='vertical' color='green'>
        <Button.Content hidden>
          <Icon name='hand peace'/>
        </Button.Content>
        <Button.Content visible>
          <Icon name='checkmark'/> {I18n.t('components.entities_list.submit')}
        </Button.Content>
      </Button>];
  }

  renderSubmitButton() {
    const { SubmitButton, entityId, readonly } = this.props;
    if (SubmitButton && !readonly) {
      return <SubmitButton key={`button-${entityId}`} submit={this.submitForm}/>
    } else {
      return null
    }
  }

  render() {
    const { model, entitiesName, entityName, entityId, FormWrapper} = this.props;


    if(entityId) {
      return [FormWrapper ? <FormWrapper key={`formWrapper-${entityId}`} scrolling={true}>{this.renderForm()}</FormWrapper>  : this.renderForm(), this.renderSubmitButton()];
    } else {
      return (<div>No form</div>)
    }
  }
}

const upsertedRelationshipsFromUiSchema = (uiSchema) => {
  let collectedUpsertedRelationships = []
  Object.keys(uiSchema || {}).forEach( (fieldName) => {
    let field = uiSchema[fieldName]
    if (field) {
      if (field["ui:field"] === "NestedFormField") {
        collectedUpsertedRelationships.push(field["ui:options"]["entityName"])
        collectedUpsertedRelationships = collectedUpsertedRelationships.concat(upsertedRelationshipsFromUiSchema(field["ui:schema"]))
      } else if (field["items"] && ( field["items"]["ui:field"] === "NestedFormField")) {

        collectedUpsertedRelationships.push(field["items"]["ui:options"]["entityName"])
        collectedUpsertedRelationships = collectedUpsertedRelationships.concat(upsertedRelationshipsFromUiSchema(field["items"]["ui:schema"]))
      }
    }
  })

  return collectedUpsertedRelationships
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const entityName = ownProps.entityName
  const entitiesName = pluralize(ownProps.entityName)
  const model = `entities.${entitiesName}.byId.${ownProps.entityId}`

  const uiSchema =  ownProps.uiSchema
  const upsertedRelationships = upsertedRelationshipsFromUiSchema(uiSchema)

  return {
    initializeForm: (schema) => {
      initializeProperties(dispatch, model, schema["properties"] || {})
    },
    fetchResources: () => {
      dispatch(fetchResources())
    },
    handleAfterSaveNavigationChange: (value) => {
      return dispatch(setSetting(`${entityName}AfterSaveNavigation`, value))
    },
    handleSubmit: (afterSaveNavigation) => {
      dispatch(upsertEntity(ownProps.entityName, ownProps.entityId, upsertedRelationships, ownProps.resourceName)).then( (entityId) => {
        let dropzone = ownProps.ui.dropzone
        if(dropzone){
          dropzone.options.url = dropzone.options.url.replace(ownProps.entityId, entityId)
          dropzone.processQueue()
        }
        if(ownProps.onSubmitSuccess && entityId) {
          ownProps.onSubmitSuccess(entityId)
        } else {
          if (afterSaveNavigation === 'overview') {
            history.push(entitiesRoute(entitiesName))
          }
        }

      })
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const entityName = getEntityName(state, ownProps)
  const entitiesName = pluralize(entityName)

  let model = `entities.${entityName}.byId.${ownProps.entityId}`

  const errorModel = `meta.${entityName}.errors.${ownProps.entityId}`
  const formModel = `meta.${entityName}.forms.${ownProps.entityId}`

  const errors = dotProp.get(state, errorModel)
  const form = dotProp.get(state, formModel)


  let afterSaveNavigation = state.settings[`${ownProps.entityName}AfterSaveNavigation`] || 'edit'

  return {
    afterSaveNavigation: afterSaveNavigation,
    schema: getSchema(entityName)(state, ownProps),
    errors: errors,
    entitiesName: entitiesName,
    model: model,
    formState: form && form.state
  }
}

const initializeProperties = (dispatch, model, properties) => {
  Object.keys(properties).forEach ( (propertyName) => {
    var property = properties[propertyName]
    if (property["type"] === "object") {
      initializeProperties(dispatch, `${model}.${propertyName}`, property["properties"])
    } else {
      dispatch(formActions.setInitial(`${model}.${propertyName}`))
    }
  })
}

export default ui({ key: 'drop',  state: { dropzone: null } }) ( connect(mapStateToProps, mapDispatchToProps)(SchemaForm));
