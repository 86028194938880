import React from 'react'
import SchemaForm from 'components/forms/SchemaForm'
import { connect } from 'react-redux'
import contactSchema from 'forms/contactSchema'

class ContactForm extends React.Component {
  render () {
    return (
      <SchemaForm entityName='contact' {...this.props} />
      )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    uiSchema: contactSchema(state, ownProps, false)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('countries'))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
