import React from 'react'
import { Segment, List, Item, Statistic, Grid, Icon, Label } from 'semantic-ui-react'

export default class ConsultationsMenuItem extends React.Component {

  render () {
    const consultation = this.props.consultation
    return (
      <List.Item key={consultation.id} as={Segment} basic clearing>
        <List.Content as={Segment}>
          <Label corner='left' size='mini'>
            <Icon name={(consultation.consultationType || {}).iconName || 'question'} size='big'/>
          </Label>
          <Grid columns={10} divided>
            <Grid.Row>
              <Grid.Column width={8} textAlign='center' verticalAlign='middle'>
                { (consultation.event && consultation.event.startTime) ?  <Statistic value={I18n.l("time.formats.hour", consultation.event.startTime)} size='tiny'/> : null }
              </Grid.Column>
              <Grid.Column width={8}>
                { consultation.dossier.patient ?  <List.Header>{consultation.dossier.patient.firstName} {consultation.dossier.patient.lastName}</List.Header> : null }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </List.Content>
      </List.Item>
    )
  }
}
