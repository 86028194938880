import React from 'react'
import { ReactReduxContext, Provider } from 'react-redux'
import { Popup as SemanticPopup, Modal as SemanticModal } from 'semantic-ui-react'

export class Popup extends React.Component {
  static Header = SemanticPopup.Header
  static Content = SemanticPopup.Content

  render() {
    return (<ReactReduxContext.Consumer>
        { ({store}) => {
          return (<SemanticPopup {...this.props}>
            <Provider store={store}>
              {this.props.children}
            </Provider>
          </SemanticPopup>)
        }}
      </ReactReduxContext.Consumer>)
  }
}

export class Modal extends React.Component {

  static Header = SemanticModal.Header
  static Content = SemanticModal.Content
  static Description = SemanticModal.Description
  static Actions = SemanticModal.Actions

  render() {
    return (<ReactReduxContext.Consumer>
        { ({store}) => {
          return (<SemanticModal {...this.props}>
            <Provider store={store}>
              {this.props.children}
            </Provider>
          </SemanticModal>)
        }}
      </ReactReduxContext.Consumer>)
  }
}
