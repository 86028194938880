import React from 'react'
import { ReactReduxContext, Provider } from 'react-redux'
import { Modal } from 'semantic-ui-react'

export default class ModalWithStore extends React.Component {
  render() {
    return (<ReactReduxContext.Consumer>
        { ({store}) => {
          return (<Modal {...this.props}>
            <Provider store={store}>
              {this.props.children}
            </Provider>
          </Modal>)
        }}
      </ReactReduxContext.Consumer>)
  }
}
