import { fk, attr } from 'redux-orm'
import Base from './Base'

class Receipt extends Base {
  static get fields() {
    return {
      id: attr(),
      createdAt: attr(),
      identification: attr(),
      dossierId :fk({to: 'dossier', as: 'dossier', relatedName: 'receipt'}),
    }
  }
}

Receipt.modelName = 'receipt'

export default Receipt;
