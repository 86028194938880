import dotProp from "dot-prop";
import orm from 'models'
import { createSelector as ormSelector } from 'redux-orm'
import { createSelector } from 'reselect'
import getEntities from 'selectors/entities'

import { getKeyWindowSelector } from 'selectors/key_windows'


const getNotifications = ormSelector(orm, getEntities, (session) => {
  return session.notification.all().toModelArray().map(notification => {
    const { ref } = notification
    return { ...ref,
      generateConsultationError: session.generateConsultationError.withId(notification.generateConsultationErrorId),
      removeConsultationError: session.removeConsultationError.withId(notification.removeConsultationErrorId),
    }
  })
})
const getTotalNumberOfUnresolved = (state) => {
  return dotProp.get(state, `meta.notification.keyWindows.byWindow.menuItem.totalObjects`)
}

export const getUnresolvedNotifications = createSelector( [ getNotifications], (notifications) => {
  return notifications.filter( notification => {  return (!notification.resolvedAt )})
})
export const getMenuItemNotifications = getKeyWindowSelector('notification', 'menuItem', getUnresolvedNotifications)

export const getUnresolvedNotificationCount = createSelector([getNotifications, getTotalNumberOfUnresolved], (notifications, numberOfUnresolved) => {
  let justResolved = notifications.filter(notification => notification.resolvedAt != null).length
  return numberOfUnresolved - justResolved
})



