import React from 'react'
import SchemaForm from 'components/forms/SchemaForm'
import { connect } from 'react-redux'
import officeSchema from 'forms/officeSchema'

class OfficeForm extends React.Component {
  render () {

    return (
      <SchemaForm entityName='office' uiSchema={this.props.uiSchema} skipSubmitDropdown={true} submitButton={true} entityId={this.props.entityId}/>
      )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    uiSchema: officeSchema(state, ownProps)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OfficeForm);
