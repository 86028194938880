import { fetchEntities } from "./entities";
import { createEntityWithDefaults } from "./entities";

export function createContactWithDefaults(entity = {}) {
  return function(dispatch, getState) {
    let contactId = dispatch(createEntityWithDefaults('contact', entity, false))
    let contactSliceId = dispatch(createEntityWithDefaults('contactSlice', { contactId: contactId }))
    return contactId
  }
}

export function fetchContact(id) {
  return function(dispatch, getState) {
    dispatch(fetchEntities('contacts', { name: 'contact', filter: { id: id }, page: 1 }))
    dispatch(fetchEntities('contactSlices', { name: 'contact', filter: { contactId: id }, page: 1 }))
  }
}
