import { fetchEntities, updateEntity } from 'actions/entities'
import SchemaForm from 'components/forms/SchemaForm'

import calendarSchema from 'forms/calendarSchema'
import React from 'react'
import { connect } from 'react-redux'
import { getSelectableCalendarByCalendarIdentification } from 'selectors/selectable_calendars'

class CalendarForm extends React.Component {
  componentDidMount () {
    this.props.fetchSelectableCalendars();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.selectableCalendar !== this.props.selectableCalendar) {
      this.props.syncColor(this.props.entityId, this.props.selectableCalendar)
    }
  }

  render () {
    if (this.props.entityId) {
      return (
        <SchemaForm entityName='calendar' {...this.props}/>
        )
    } else {
      return <div></div>
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    uiSchema: calendarSchema(state, ownProps),
    selectableCalendar: getSelectableCalendarByCalendarIdentification(state, ownProps),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSelectableCalendars: () => {
      dispatch(fetchEntities('selectableCalendars'))
    },
    syncColor: (entityId, selectableCalendar) => {
      if (selectableCalendar) {
        dispatch(updateEntity('calendar', { id: entityId, color: selectableCalendar.color}))
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarForm);
