import { fk, attr } from 'redux-orm'
import Base from './Base'

class ConsultationPrice extends Base {
  static get fields() {
    return {
      id: attr(),
      price: attr(),
      startDate: attr(),
      endDate: attr(),
      practionerId: fk({to: 'employment', as: 'practioner', relatedName: 'consultationPrices'}),
      locationId: fk('location'),
      consultationTypeId: fk('consultationType'),
      officeId: fk({to: 'office', as: 'office', relatedName: 'consultationPrices'})
    }
  }
}

ConsultationPrice.modelName = 'consultationPrice'

export default ConsultationPrice;
