import React from 'react'

export default class EditorLeaf extends React.Component {
  render() {
    let {attributes, children, leaf} = this.props
    if (leaf.bold) {
      children = <strong>{children}</strong>
    }

    if (leaf.italic) {
      children = <em>{children}</em>
    }

    if (leaf.underline) {
      children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
  }
}
