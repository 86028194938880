import React from 'react'

import { connect } from 'react-redux'

import { fetchEntities } from 'actions/entities';

import { Segment, Grid, Header } from 'semantic-ui-react'
import ConsultationsListContainer from 'components/consultations/ConsultationsListContainer'
import Stats from 'components/compass/Stats'
import { Switch, Route } from 'react-router-dom'

import moment from 'moment'

class Compass extends React.Component {
  componentDidMount() {
    this.props.fetchEntities()
  }

  render () {
    return (
      <Grid padded={true}>
        <Grid.Row>
          <Grid.Column width={3}>
            <Segment>
              <Header as='h2'>{I18n.t('components.compass.stats')}</Header>
              <Stats/>
            </Segment>
          </Grid.Column>
          <Grid.Column width={13}>
            <Segment>
              <ConsultationsListContainer keyWindow={'compass'}/>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('consultations', { name: 'compass', filter: {startTime: moment().startOf('day').toISOString(),  endTime: moment().endOf('day').toISOString() }, page: 1 }))
    },
  }
}

export default connect(null, mapDispatchToProps)(Compass)
