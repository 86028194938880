import React from 'react'
import { connect } from "react-redux";
import { TableRow } from "semantic-ui-react";
import { TableCell } from "semantic-ui-react";
import { findEntity } from "../../../selectors/entities";

class DossiersListItem extends React.Component {

  render () {
    const {dossier, responsible, location} = this.props

    return (
      <TableRow>
        <TableCell>{dossier.patient && dossier.patient.firstName} {dossier.patient && dossier.patient.lastName}</TableCell>
        <TableCell>{responsible && responsible.name}</TableCell>
        <TableCell>{location && location.name}</TableCell>
        <TableCell>{I18n.l("date.formats.default",dossier.createdAt)}</TableCell>
      </TableRow>)
  }
}

const mapStateToProps = (state, ownProps) => {
  const dossier = ownProps.dossier
  const responsible = dossier && findEntity(state, 'employment', dossier.responsibleId)
  const location = dossier && findEntity(state, 'location', dossier.locationId)

  return {
    responsible: responsible,
    location: location,
  }
}

export default connect(mapStateToProps)(DossiersListItem);


