import React, { Fragment } from 'react'
import ui from 'redux-ui'
import pluralize from 'pluralize'
import { Button, Icon, Menu, Modal } from 'semantic-ui-react'
//import { Modal } from 'semantic-ui-react-with-store'
import { refreshEntities } from "../../actions/entities";
import { connect } from "react-redux";
import underscored from "underscore.string/underscored";

class EditModal extends React.Component {

  handleEditSubmitSuccess = () => {
    this.props.updateUI('modalOpen', false)
    if (this.props.refreshAfterSave) {
      this.props.refreshEntities()
    }
  }

  SubmitButton = (props) => {
     return(<Modal.Actions>
      <Button animated='vertical' color='green' inverted onClick={ (event, data) => {props.submit()}} >
            <Button.Content hidden>
      <Icon name='hand peace outline' />
      </Button.Content>
      <Button.Content visible>
      <Icon name='checkmark' /> {I18n.t('components.entities_list.submit')}
      </Button.Content>
      </Button>
       </Modal.Actions>)
  }

  render() {
    const {
      entityId,
      entityName,
      EntityForm
    } = this.props
    const entitiesName = pluralize(entityName)

    const Trigger = this.props.trigger ? this.props.trigger : this.defaultTrigger

    return (
      <Fragment>
         <Trigger key='trigger' onClick= {() => {this.props.updateUI('modalOpen', true)}}/>
        <Modal key='modal' size='fullscreen' open={this.props.ui.modalOpen} onClose={() => this.props.updateUI('modalOpen', false)}>
          <Modal.Header>{I18n.t(`components.${underscored(entitiesName)}_selector.edit`)}</Modal.Header>
          <EntityForm entityId={entityId} FormWrapper={Modal.Content} SubmitButton={this.SubmitButton} onSubmitSuccess={this.handleEditSubmitSuccess}/>
        </Modal>
        </Fragment>)

  }

  defaultTrigger = (props) => {
    return (
      <Menu.Item onClick={props.onClick}>
        <Icon name='pencil'/>
      </Menu.Item>)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const entitiesName = pluralize(ownProps.entityName)
  return {
    refreshEntities: () => {
      return dispatch(refreshEntities(entitiesName))
    },
  };
}


export default ui({state: { modalOpen: false}})(connect(null, mapDispatchToProps)(EditModal));
