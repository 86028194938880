import React from 'react'

import { Select } from 'semantic-ui-react'
import { actions } from 'react-redux-form'
import { connect } from 'react-redux'

import dotProp from 'dot-prop'

class SemanticSelectWidget extends React.Component {
  render() {
    const { selectValue, entityModel, options } = this.props
    const selectOptions = options["selectOptions"]
    const searchable = options["searchable"] || false

    return (
      <Select selection search={searchable} options={selectOptions} onChange={ (event, props) => { this.props.dispatch(actions.change(entityModel, props.value))}} value={selectValue}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var entityModel = `${ownProps.model || ''}.${ownProps.id}`
  var selectValue = dotProp.get(state,entityModel) || ownProps.options.emptyValue
  return {
    selectValue: selectValue,
    entityModel: entityModel
  }
}

export default connect(mapStateToProps)(SemanticSelectWidget);
