import { getJson, postJson } from 'api_helpers.js'
import _ from "lodash";
import { actions as formActions } from 'react-redux-form'
import { createContactWithDefaults } from "./contacts";
import { createEntityWithDefaults } from "./entities";

export const REQUEST_DOSSIERS = 'REQUEST_DOSSIERS'; export function requestDossiers() {
  return {
    type: REQUEST_DOSSIERS
  };
}

export const RECEIVE_DOSSIERS = 'RECEIVE_DOSSIERS';
export function receiveDossiers(dossiers) {
  return {
    type: RECEIVE_DOSSIERS,
    dossiers: dossiers
  };
}

export const FETCH_DOSSIERS_FAILURE = 'FETCH_DOSSIERS_FAILURE';
export function fetchDossiersFailure(error) {
  return {
    type: FETCH_DOSSIERS_FAILURE,
    error: error
  };
}

export const FETCH_DOSSIERS= 'FETCH_DOSSIERS';
export function fetchDossiers() {
  return function(dispatch) {

    dispatch(requestDossiers())

    return getJson(Routes.api_dossiers_path()).then (
      response => response.json(),
      error => dispatch(fetchDossiersFailure(error))
    ).then (
      json => {
        dispatch(receiveDossiers(json.data));
        dispatch(formActions.setPristine("dossiers"))
      }
    )
  }
}

export const CREATE_DOSSIER_FAILURE = 'CREATE_DOSSIER_FAILURE';
export function createDossierFailure(dossier) {
  return {
    type: CREATE_DOSSIER_FAILURE,
    dossier: dossier
  };
}

export const CREATE_DOSSIERS = 'CREATE_DOSSIERS';
export function createDossiers(dossiers) {
  return function(dispatch) {

    return postJson(Routes.bulk_create_api_dossiers_path(), {dossiers: dossiers}).then (
      response => response.json(),
      error => dispatch(createDossiersFailure(error))
    ).then (
      json => dispatch(fetchDossiers())
    )
  }
}

export const CREATE_DOSSIER = 'CREATE_DOSSIER';
export function createDossier(dossier) {
  return function(dispatch) {
    dispatch(createDossiers([dossier]))
  }
}
export function createDossierWithDefaults(entity = {}) {
  return function(dispatch, getState) {
    let contactId = dispatch(createContactWithDefaults())
    let dossierId = dispatch(createEntityWithDefaults('dossier', _.merge({}, { patientId: contactId }, entity), false))
    let dossierSliceId = dispatch(createEntityWithDefaults('dossierSlice', { dossierId: dossierId}))
    return dossierId
  }
}

export const UPDATE_DOSSIERS = 'UPDATE_DOSSIERS';
export function updateDossiers(dossiers) {
  return function(dispatch) {

    return postJson(Routes.bulk_update_api_dossiers_path(), {dossiers: dossiers}).then (
      response => response.json(),
      error => dispatch(updateDossiersFailure(error))
    ).then (
      json => dispatch(fetchDossiers())
    )
  }
}

export const UPDATE_DOSSIER = 'UPDATE_DOSSIER';
export function updateDossier(dossier) {
  return function(dispatch) {
    dispatch(updateDossiers([dossier]))
  }
}

export const NEW_DOSSIER = 'NEW_DOSSIER'
export function newDossier() {
  return {
    type: NEW_DOSSIER
  };
}
