import React from 'react'
import { Sidebar, Segment, Menu, Icon } from 'semantic-ui-react'
import { BrowserRouter , Switch, Route } from 'react-router-dom'
import OrganisationsListContainer from 'components/organisations/OrganisationsListContainer';
import OrganisationsMenuContainer from 'components/organisations/OrganisationsMenuContainer';
import Organisation from 'components/organisations/Organisation';

export default class Organisations extends React.Component {
  render () {
    return (
      <div>
        <Switch>
          <Route path={Routes.organisation_path({ id: ":id", _options: true})} render={ props => (
            <Sidebar.Pushable as={Segment} className= "min-height-90">
              <OrganisationsMenuContainer/>
              <Sidebar.Pusher className="left-sidebar-margin">
                <Organisation organisationId={props.match.params.id}/>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
           )} />

          <Route path={Routes.organisations_path()} component={OrganisationsListContainer}/>
        </Switch>
      </div>
    )
  }
}

