import React from 'react'
import EditorElement from "./EditorElement";
import { createEditor } from "slate";
import { Editable, Slate } from "slate-react";
import EditorLeaf from "./EditorLeaf";

export default class RichTextRender extends React.Component {
  render() {
    const renderElement = props => <EditorElement {...props} />
    const renderLeaf = props => <EditorLeaf {...props} />
    const editor = createEditor();
    return(
      <Slate editor={editor} value={JSON.parse(this.props.content)} >
        <Editable readOnly
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
      </Slate>
    );
  }
}
