import Flash from 'components/generics/Flash'
import history from 'custom_history'
import React from 'react'
import { Provider } from 'react-redux'
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { NavLink, Router } from 'react-router-dom'
import { Icon, Menu, Sidebar } from 'semantic-ui-react'
import store from 'store'
import Dossiers from "./admin/dossiers/Dossiers";
import EmploymentsListContainer from "./employments/EmploymentsListContainer";


import ErrorPage from './generics/ErrorPage'

export default class AdminApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  render() {
    if(this.state.error) {
      return (<ErrorPage redirectUrl={Routes.admin_root_path()} error={this.state.error}/>)
    } else {
      return (<Provider store={store}>
          <Router history={history}>
            <Route path="/admin/:officeSlug/*" render={(props) => {
              return (
                <div className="page-layout">
                  <Menu className={'main-menu'} color="green" inverted>
                    <Menu.Item>
                      Zorgmee: Admin
                    </Menu.Item>
                    <Menu.Menu position="right">
                      <Menu.Item as='a' href={Routes.compass_path()}>
                        <Icon size='large' name='id badge outline'/>
                      </Menu.Item>
                      <Menu.Item as={NavLink} to={Routes.destroy_user_session_path()} data-method="delete">
                        <Icon size='large' name="sign out"/>
                      </Menu.Item>
                    </Menu.Menu>
                  </Menu>
                  <div className="main">
                    <Flash/>
                    <Switch>
                      <Route path={Routes.admin_dossiers_path()} component={Dossiers}/>
                      <Route path={Routes.admin_employments_path()} component={EmploymentsListContainer}/>
                    </Switch>
                  </div>
                  <Sidebar as={Menu} visible={true} direction="bottom" animation="push" inverted={true} widths={5}
                           icon="labeled" size="massive" color="green">
                    <Menu.Item as={NavLink} to={Routes.admin_employments_path()}>
                      <Icon name="building outline"/>
                      {I18n.t('components.admin_app.menu.employments')}
                    </Menu.Item>
                    <Menu.Item as={NavLink} to={Routes.admin_dossiers_path()}>
                      <Icon name="user"/>
                      {I18n.t('components.admin_app.menu.dossiers')}
                    </Menu.Item>
                    {/*<Menu.Item as={ NavLink } to={Routes.superuser_consultation_types_path()}>
                <Icon name='clock'/>
                {I18n.t('superuser.components.superuser_app.menu.consultation_types')}
              </Menu.Item>
              <Menu.Item as={ NavLink } to={Routes.superuser_health_funds_path()}>
                <Icon name='hospital'/>
                {I18n.t('superuser.components.superuser_app.menu.health_funds')}
              </Menu.Item>*/}
                  </Sidebar>
                </div>)
            }}/>
          </Router>
        </Provider>
      )
    }
  }
}
