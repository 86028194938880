import React from 'react'
import { connect } from 'react-redux'
import { fetchEntities } from "../../actions/entities.js";
import { getCurrentEmployment } from "../../selectors/employments/index.js";
import SetupWizard from "./SetupWizard";

class SetupWizardContainer extends React.Component {

  componentDidMount() {
    this.props.fetchEntities();
  }

  render() {
    let {showSetup, user} = this.props
    if (!showSetup) {
      return null
    }
    return (<SetupWizard user={user}/>)
  }
}


const mapStateToProps = (state, ownProps) => {
  let employment = getCurrentEmployment(state)
  let user = employment && employment.user
  let showSetup = user && !user.onboardingsDone.includes("setup")
  return {
    showSetup: showSetup,
    user: user
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('employments'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupWizardContainer);
