import React from 'react'
import DateTimePicker from 'components/generics/DateTimePicker'

import { Form } from 'semantic-ui-react'

import 'semantic-ui-popup/popup.min.js'
import 'semantic-ui-transition/transition.min.js'
import moment from 'moment'

import { actions } from 'react-redux-form'
import { connect } from 'react-redux'

import dotProp from 'dot-prop'


class SemanticTimeDurationWidget extends React.Component {
  render() {
    const formatter =
      {
        time: function(date, settings, forCalendar) {
          return moment(date).format("HH:mm") },
        cell: function (cell, date, cellOptions) {
            if (cellOptions.disabled) {
              cell.remove()
            }
            return true
          }
      }
    const { entityModel, timeDuration } = this.props

    return (
      <DateTimePicker type='time' formatter={formatter} maxDate={moment({ hour: 3, minutes: 59}).toDate()} onChange={ (date, text, mode) => { this.props.dispatch(actions.change(entityModel, text))}} >
        <Form.Input defaultValue={timeDuration} icon='time' />
      </DateTimePicker>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var entityModel = `${ownProps.model || ''}.${ownProps.id}`
  var timeDuration = dotProp.get(state,entityModel)
  return {
    timeDuration: timeDuration,
    entityModel: entityModel
  }
}

export default connect(mapStateToProps)(SemanticTimeDurationWidget);
