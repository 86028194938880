import React from 'react'
import 'semantic-ui-calendar/dist/calendar.min.js'

export default class DateTimePicker extends React.Component {
  componentDidMount() {
    const { text, ...config } = this.props;
    this.$el = $(this.el);
    config["text"] = {
      days: Object.values(I18n.t('date.abbr_day_names')),
      months: Object.values(I18n.t('date.month_names')).slice(1,11),
      monthsShort: Object.values(I18n.t('date.abbr_month_names')).slice(1,11),
      today: I18n.t('date.today'),
      now: I18n.t('date.now'),
    },
    config["popupOptions"] = {
      position: 'top center',
      prefer: 'adjacent',
    }
    this.$el.calendar(config);
  }

  componentWillUnmount() {
    this.$el.unbind().removeData();
  }

  render() {
    return (
      <div ref={el => this.el = el}>{this.props.children}</div>
    )
  }
}
