import isUuid from 'is_uuid'

import _ from 'lodash'
import orm from 'models'
import { createSelector as ormSelector } from 'redux-orm'
import { createSelector } from 'reselect'
import getEntities from 'selectors/entities'

export const getDossiers = ormSelector (orm, getEntities, (session) => {
  const dossiers = session.dossier.all ().toModelArray ()
                        .filter (dossier => !dossier.isArchived)
                        .map (dossier => {
                          const {ref} = dossier
                          return {
                            ...ref,
                            patient: session.contact.withId (dossier.patientId),
                            responsible: session.employment.withId (dossier.responsibleId)
                          }
                        })

  return _.sortBy(dossiers, (dossier) => { return `${dossier.patient?.firstName} ${dossier.patient?.lastName}`  })
})

export const getDossier = (state, id) => {
  let session = orm.session(state.entities)
  let dossier = session['dossier'].withId(id)
  return {
    ...dossier.ref,
    patient: session.contact.withId(dossier.patientId),
    responsible: session.employment.withId(dossier.responsibleId)
  }
}

export const getDossiersFilter = state => state.meta.dossier.filter

export const getFilteredDossiers = createSelector([getDossiers, getDossiersFilter], (dossiers, filter) => {

  return dossiers.filter( (dossier) =>  { return (!isUuid(dossier.id)) && ( !filter || ( filter.length === 0) || ( `${dossier.patient.firstName} ${dossier.patient.lastName}`.toLowerCase().indexOf(filter.toLowerCase()) > -1) || ( `${dossier.patient.lastName} ${dossier.patient.firstName}`.toLowerCase().indexOf(filter.toLowerCase()) > -1)
  )})
})
