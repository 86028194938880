import React from 'react'
import SchemaForm from 'components/forms/SchemaForm'
import { connect } from 'react-redux'

import { getCurrentOfficeId } from 'selectors/offices'

import emailTemplateBatchSchema from 'forms/emailTemplateBatchSchema'

class EmailTemplateBatchForm extends React.Component {

  render () {


    if(this.props.officeId) {
      return (
        <SchemaForm batch='emailTemplate' entityName='office' resourceName='emailTemplateBatch' uiSchema={this.props.uiSchema} submitButton={true} entityId={this.props.officeId}/>
      )
    } else {
      return <div></div>
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    officeId: getCurrentOfficeId(state),
    uiSchema: emailTemplateBatchSchema(state, ownProps)
  }
}

const mapDispatchToProps = (dispatch, state) => {
  return {
  }
}

EmailTemplateBatchForm.defaultProps = {
  submitButton: true
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplateBatchForm);
