import EntitiesList from 'components/entities/EntitiesList';
import React from 'react'
import { connect } from 'react-redux'
import { getDossierItemsJson } from 'selectors/dossier_items'
import { Button, Icon, Menu } from "semantic-ui-react";
import { createEntityWithDefaults, fetchEntities } from "../../../actions/entities";
import NewEntityModal from "../../entities/NewEntityModal";
import DossierAttachmentForm from "./DossierAttachmentForm";
import DossierItemForm from "./DossierItemForm";
import DossierItemListItem from "./DossierItemListItem";


const mapStateToProps = (state, ownProps) => {
  const dossierId = ownProps.dossierId;

  const AddButton = (props) => {return(<Button onClick={props.onClick}>
    <Icon name="plus"/>
    {I18n.t(`components.dossier_items_list.new`)}
  </Button>)}

  return {
    entities: getDossierItemsJson(state, {dossierId: dossierId}),
    entityName: 'dossierItem',
    item: DossierItemListItem,
    form: DossierItemForm,
    loading: state.meta?.dossierItem?.loading,
    error: state.meta?.dossierItem?.error,
    addButton: AddButton
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const dossierId = ownProps.dossierId;


  const createAttachmentWithDefaults = () => {
    return dispatch(createEntityWithDefaults('dossierItem', {
      dossierItemType: "attachment",
      dossierId: ownProps.dossierId,
      time: new Date().toISOString()
    }))
  }

  const addAttachment =
    <Menu.Item>
      <NewEntityModal entityName="dossierItem"
                      createEntityWithDefaults={createAttachmentWithDefaults}
                      form={DossierAttachmentForm} trigger={(props) => {
        return (<Button onClick={props.onClick}>
          <Icon name="attach"/>
          {I18n.t(`components.dossier_item.new_attachment`)}
        </Button>)
      }}
      />
    </Menu.Item>

  return {
    extraActions: () => {
      return addAttachment
    },

    fetchExtraEntities: () => {
      dispatch(fetchEntities('consultations', {name: 'dossier', filter: {dossierId: dossierId}, page: 1}))
      dispatch(fetchEntities('dossierItems', { name: 'dossier', filter: {dossierId: dossierId}, page: 1}))
    },
    createEntityWithDefaults: () => {
      return dispatch(createEntityWithDefaults('dossierItem', {dossierItemType: "note", dossierId: ownProps.dossierId, time: new Date().toISOString()}))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesList)
