import { fk, attr } from 'redux-orm'
import Base from './Base'

class Consultation extends Base {
  static get fields() {
    return {
      id: attr(),
      noInvoice: attr(),
      dossierId: fk({to: 'dossier', as: 'dossier', relatedName: 'consultations'}),
      practionerId: fk({to: 'employment', as: 'practioner', relatedName: 'consultations'}),
      consultationTypeId: fk({to: 'consultationType', as: 'consultationType', relatedName: 'consultations'}),
      invoiceItemId: fk({to: 'invoiceItem', as: 'invoiceItem', relatedName: 'consultations'}),

      // receiptId :fk({to: 'receipt', as: 'receipt', relatedName: 'consultations'}),
    }
  }
}

Consultation.modelName = 'consultation'

export default Consultation;
