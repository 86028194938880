import { updateEntity, upsertEntity } from 'actions/entities'
import GenerateConsultationErrorButtons from 'components/generate_consultation_errors/GenerateConsultationErrorButtons'
import React from 'react'
import { connect } from 'react-redux'
import { findEntity } from 'selectors/entities'
import { getTagsFromEvent as getConsultationTypeTags } from 'selectors/event_consultation_type_tags'
import { getTagsFromEvent as getDossierTags } from 'selectors/event_dossier_tags'
import { Button, Card } from 'semantic-ui-react'

import underscored from 'underscore.string/underscored'
import CollapsableCard from "../notifications/CollapsableCard.jsx";

class GenerateConsultationErrorsListItem extends React.Component {

  renderSuggestions() {
    if (this.props.matchType === 'Dossier') {
      return this.renderDossierSuggestions()
    } else if (this.props.matchType === 'ConsultationType') {
      return this.renderConsultationTypeSuggestions()
    }
  }

  renderDossierSuggestions() {
    if (this.props.tags && (this.props.tags.length < 10)) {
      return this.props.tags.map((tag) => {
        return tag.dossier && tag.dossier.patient && tag.dossier.patient.firstName ?  <Button key={tag.id} onClick={ () => this.props.updateGenerateConsultationError('resolved', this.props.generateConsultationError.id, tag.dossier.id)}>
          {`${tag.dossier.patient.firstName} ${tag.dossier.patient.lastName}`}
        </Button> : null
      })
    }
  }

  renderConsultationTypeSuggestions() {
    return this.props.tags.map((tag) => {
      return tag.consultationType && tag.consultationType.name ?  <Button key={tag.id} onClick={ () => this.props.updateGenerateConsultationError('resolved', this.props.generateConsultationError.id, tag.consultationType.id)}>
        {`${tag.consultationType.name}`}
      </Button> : null
    })
  }

  render() {
    const {event, error, notification} = this.props
    const matchType = underscored(error.matchType)


    let title = I18n.t(notification.titleKey)
    title = event ? title + " " + event.summary : title

    return (
      <CollapsableCard title={title}>
        <Card.Content key="notification_card_content">
          <Card.Meta>
            {event && event.startTime ? I18n.l("time.formats.long", event.startTime) : null}
          </Card.Meta>
          <Card.Description>
            {I18n.t(`components.generate_consultation_errors_list_item.error_descriptions.${matchType}.${this.props.errorType}`, {
              match: this.props.match,
              correction: this.props.correction
            })}
          </Card.Description>

          <div style={{ paddingTop: 10 }}>
            {I18n.t('components.generate_consultation_errors_list_item.match_to')}
            {this.renderSuggestions(error)}
            <GenerateConsultationErrorButtons errorId={error.id} matchType={matchType} summary={event && event.summary}
                                              showDropDownImmediately={this.props.tags.length === 0}/>
          </div>
        </Card.Content> ,
        <Card.Content key="notification_card_ignore_button">
          <div style={{ paddingTop: 10 }}>
            <Button color="red" floated="right" onClick={(event) => {
              this.props.updateGenerateConsultationError('ignored', this.props.generateConsultationError.id)
            }}>{I18n.t(`components.notifications_list_item.ignore`)}</Button>
          </div>
        </Card.Content>
      </CollapsableCard>)
  }

  renderBody () {
    const error = this.props.error
    const event = this.props.event
    const matchType = underscored(error.matchType)

    return (
        [<Card.Content key='notification_card_content'>
          <Card.Meta>
            {event && event.startTime ? I18n.l("time.formats.long", event.startTime) : null }
          </Card.Meta>
          <Card.Description>
            {I18n.t(`components.generate_consultation_errors_list_item.error_descriptions.${matchType}.${this.props.errorType}`, { match: this.props.match, correction: this.props.correction })}
          </Card.Description>

          <div style={{paddingTop: 10 }}>
            {I18n.t('components.generate_consultation_errors_list_item.match_to')}
            {this.renderSuggestions(error)}
          <GenerateConsultationErrorButtons errorId={error.id} matchType={matchType} summary={event.summary} showDropDownImmediately={this.props.tags.length === 0}/>
          </div>
        </Card.Content > ,
          <Card.Content key='notification_card_ignore_button'>
            <div style={{paddingTop: 10 }}>
            <Button color='red'  floated='right' onClick={ (event) => { this.props.updateGenerateConsultationError('ignored', this.props.generateConsultationError.id)}}>{I18n.t(`components.notifications_list_item.ignore`)}</Button>
            </div>
          </Card.Content>]
      )
  }


}

const mapStateToProps = (state, ownProps) => {
  const error = ownProps.generateConsultationError
  const matchType = error.matchType
  const event = findEntity(state, 'event', error.eventId)

  var tags = []
  if (matchType === 'Dossier') {
    tags = (event && getDossierTags(event.id)(state, ownProps)) || []
  } else if (matchType === 'ConsultationType') {
    tags = (event && getConsultationTypeTags(event.id)(state, ownProps)) || []
  } else {
    // throw `Invalid matchType ${matchType}`
  }
matchType
  const match = (tags[0] || {}).match || ''
  const correction  = (tags[0] || {}).needle || ''

  return {
    error: error,
    tags: tags,
    matchType: matchType,
    errorType: error.errorType,
    correction: correction,
    match: match,
    event: event
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateGenerateConsultationError: (resolutionType, generateConsultationErrorId, matchId = null) => {

      if(matchId) {
        dispatch(updateEntity('generateConsultationError', { id: generateConsultationErrorId, resolutionType: resolutionType, matchId: matchId }))
      } else {
        dispatch(updateEntity('generateConsultationError', { id: generateConsultationErrorId, resolutionType: resolutionType }))
      }


      dispatch(upsertEntity('generateConsultationError', generateConsultationErrorId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GenerateConsultationErrorsListItem);
