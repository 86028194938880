import { connectRouter } from 'connected-react-router'
import orm from 'models'
import { combineReducers } from 'redux'
import { createReducer } from 'redux-orm'
import { persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage'
import channels from './channels'
import errors from './errors'
import flash from './flash'

import modelReducer from './form/model-reducer'
import createMetaReducer from './meta'
import progress from './progress'
import resources from './resources'
import searchResults from './search_results'
import settingsReducer from './settings'
import uiReducer from './ui'


export const createRootReducer = (history) => { return (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    state = undefined
  } // Move this to logic for clearing

  return combineReducers({
    router: connectRouter(history),
    flash: flash,
    api_type: () => { return 'standard' },
    errors: errors,
    entities: (state, action) => {
      const intermediateState = createReducer(orm) (state, action)
      const finalState = modelReducer(`entities`, {})(intermediateState, action)
      return finalState;
    },
    meta: createMetaReducer(orm),
    resources: resources, // TODO: make as entity
    searchResults: searchResults,
    channels: channels,
    progress: progress,
    settings:  persistReducer({key: 'settings', storage: localStorage}, settingsReducer),
    // ui: persistReducer({ key: 'ui', storage: sessionStorage, blacklist: ['mainModal'] }, uiReducer),
    //
    ui: uiReducer // TODO Maybe persist with blacklist for DossierEditor
  })(state,action)
}}


