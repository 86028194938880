import React from 'react'
import { Sidebar, Item, Menu, Icon} from 'semantic-ui-react'
import { Switch, Route, Link, NavLink } from 'react-router-dom'

export default class OverviewMenu extends React.Component {
  render () {
    return (
      <Sidebar as={Menu} visible={true} direction="bottom" animation="push" widths={5} icon='labeled' size="massive" className='joyride-step-1 management-menu' stackable={true}>

        <Menu.Item as={ NavLink } to={Routes.compass_path()} color="orange">
          <Icon name='compass'/>
          {I18n.t('components.main_app.menu.today')}
        </Menu.Item>
        <Menu.Item as={ NavLink } to={Routes.consultations_path()} color="green">
          <Icon name='calendar outline'/>
          {I18n.t('components.main_app.menu.consultations')}
        </Menu.Item>
        <Menu.Item as={ NavLink } to={Routes.dossiers_path()} color="blue">
          <Icon name='user circle outline'/>
          {I18n.t('components.main_app.menu.dossiers')}
        </Menu.Item>
        <Menu.Item as={ NavLink } to={Routes.invoices_path()} color="yellow">
          <Icon name='calculator'/>
          {I18n.t('components.main_app.menu.management')}
        </Menu.Item>
      </Sidebar>)
  }
}
