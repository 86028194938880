import { updateEntity } from "actions/entities";


import dotProp from 'dot-prop'
import React from 'react'
import DropzoneComponent from 'react-dropzone-component'
import { connect } from 'react-redux'
import underscored from "underscore.string/underscored";

class SemanticFileWidget extends React.Component {
  render() {
    const componentConfig = {
        iconFiletypes: ['.jpg', '.png'],
        showFiletypeIcon: true,
        postUrl: this.props.route
    };

    const djsConfig = {
      paramName: this.props.paramName,
      headers: {
        'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content'),
      },
      autoProcessQueue: false,
      maxFilesize: 5,
      maxFiles: 1,
      dictDefaultMessage: I18n.t("components.semantic_file_widget.dict_default_message"),
      dictFallbackMessage: I18n.t("components.semantic_file_widget.dict_fallback_message"),
      dictFallbackText: I18n.t("components.semantic_file_widget.dict_fallback_text"),
      dictFileTooBig: I18n.t("components.semantic_file_widget.dict_file_too_big"),
      dictInvalidFileType: I18n.t("components.semantic_file_widget.dict_invalid_file_type"),
      dictResponseError: I18n.t("components.semantic_file_widget.dict_response_error"),
      dictCancelUpload: I18n.t("components.semantic_file_widget.dict_cancel_upload"),
      dictCancelUploadConfirmation: I18n.t("components.semantic_file_widget.dict_cancel_upload_confirmation"),
      dictRemoveFile: I18n.t("components.semantic_file_widget.dict_remove_file"),
      dictMaxFilesExceeded: I18n.t("components.semantic_file_widget.dict_max_files_exceeded"),
      addRemoveLinks: false
    }

    const displayExistingFile = (
      dropzone,
      mockFile,
      imageUrl,
    ) => {
      dropzone.files.push(mockFile)
      dropzone.emit("addedfile", mockFile);
      dropzone.emit("complete", mockFile);

      let onDone = (thumbnail) => {
        dropzone.emit("thumbnail", mockFile, thumbnail);
      };
      mockFile.dataURL = imageUrl;

      dropzone.createThumbnailFromUrl(
        mockFile,
        dropzone.options.thumbnailWidth,
        dropzone.options.thumbnailHeight,
        dropzone.options.thumbnailMethod,
        dropzone.options.fixOrientation,
        onDone,
        "Anonymous"
      );
    }

      const eventHandlers = {
        init: dropzone => {
          this.props.formContext.setDropZone(dropzone);
          this.dropzone = dropzone;
          let url = this.props.expiringUrl
          if(url){
            let filename = url.substring(url.lastIndexOf('/')+1);
            let mockFile = { name: filename, accepted:true };
            displayExistingFile(dropzone, mockFile, "/images/missing.png")
          }
        },
        addedfile: (file) => {
          file.previewElement.classList.add("dz-complete");
          this.props.formContext.setDropZone(this.dropzone);
        },
        maxfilesexceeded: (file) => {
          this.dropzone.removeAllFiles()
          this.dropzone.addFile(file)
        },
        success: (file, response) => {
          let entity = {id: response.data.id, ...response.data.attributes}
          this.props.dispatch(updateEntity(this.props.entityName ,entity)) }
      }

    return (
      <DropzoneComponent config={componentConfig} djsConfig={djsConfig} eventHandlers={eventHandlers}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const underScoredEntityName =  underscored(ownProps.formContext.entityName) || ''

  if (!Routes[`${ownProps.id}_api_${underScoredEntityName}_path`]) {
    throw `Route not found: ${ownProps.id}_api_${underScoredEntityName}_path`
  }

  var entityModel = `${ownProps.model || ''}.${ownProps.id}ExpiringUrl`
  var expiringUrl = dotProp.get(state,entityModel)
  const route = Routes[`${ownProps.id}_api_${underScoredEntityName}_path`]({id: ownProps.formContext.entityId, _options: true})

  return {
    route: route,
    paramName: ownProps.id,
    entityId: ownProps.formContext.entityId,
    entityName: ownProps.formContext.entityName,
    expiringUrl: expiringUrl
  }
}

export default connect(mapStateToProps)(SemanticFileWidget);
