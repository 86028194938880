import { fetchEntities } from 'actions/entities';
import { fetchResources } from 'actions/resources'

import NewEntityModal from 'components/entities/NewEntityModal';
import InfiniteScroll from 'components/generics/InfiniteScroll';

import isUuid from 'is_uuid'

import pluralize from 'pluralize'
import React from 'react'
import { connect } from 'react-redux'
import { Button, Grid, Header, Icon, List, Menu, Segment } from 'semantic-ui-react'
import underscored from 'underscore.string/underscored'

var n = 0
class EntitiesList extends React.Component {

  componentDidMount () {
    this.props.fetchEntities();
  }

  componentDidUpdate(prevProps) {
    // TODO Clean this dirty hack
    if(this.props.fetchUpdatedEntities && n < 2) {
      n = n + 1
      this.props.fetchUpdatedEntities();
    }
  }

  renderEntities(entities) {
    var EntityItem = this.props.item
    const entitiesFilter = this.props.entitiesFilter || (() => true)
    return entities.filter(entitiesFilter).filter( (entity) => { return (!isUuid(entity.id)) }).map((entity) => {
      var entityProps = {}
      entityProps[this.props.entityName] = entity
      return <EntityItem key={entity.id} destroyEntity={this.props.destroyEntity} {...entityProps}/>;
    });
  }

  renderList() {
    const { loading, entityName, noneMessage, onLoadMore, activePage, totalPages } = this.props
    const entitiesName = pluralize(entityName)

    const list = (this.props.entities || [])

    var ListComponent = this.props.listComponent || List

    if (list.length >0) {
      if (onLoadMore) {
        return (
          <InfiniteScroll onBottomVisible={ () => onLoadMore(activePage, totalPages)}>
            <ListComponent>
              {this.renderEntities(list)}
            </ListComponent>
          </InfiniteScroll>
        )
      } else {
        return (
          <ListComponent>
            {this.renderEntities(list)}
          </ListComponent>
        )
      }
      return (<h4>{noneMessage || I18n.t(`components.${underscored(entitiesName)}_list.none`, { defaults: [{scope: 'components.entities_list.none' }]})}</h4>)
    } else {
    }
  }

  renderAddButton(ownProps){
    if (ownProps.addButton) return (ownProps.addButton)
    return(
      (props) => {return(
        <Button onClick={props.onClick} disabled={ownProps.readonly}>
          <Icon name='plus'/>
          {I18n.t(`components.entities_list.add`)}
        </Button>
    )})
  }

  render () {
    const { loading, error,  hideHeader, extraActions: ExtraActions, readonly} = this.props

    const entitiesName = pluralize(this.props.entityName)
    const AddButton = this.renderAddButton(this.props)

    if(error) {
      return <div className="alert alert-danger">Error: {error.message}</div>
    }

    return (
      <Segment basic loading={loading}>
        { hideHeader ? null :
        <Header dividing>
          <Grid columns={2}>
            <Grid.Row verticalAlign='middle'>
              <Grid.Column width={10}>
                <Header as='h2' >{I18n.t(`components.${underscored(entitiesName)}_list.title`)} </Header>
              </Grid.Column>
              <Grid.Column textAlign="right" width={6}>
                { this.props.form ?
                <Menu compact borderless secondary>
                  { ExtraActions && <ExtraActions></ExtraActions> }
                  <Menu.Item>
                    <NewEntityModal entityName={this.props.entityName} form={this.props.form} trigger={AddButton}
                                    createEntityWithDefaults={this.props.createEntityWithDefaults}/>
                  </Menu.Item>
                </Menu> : null }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Header>}
        { this.renderList() }
      </Segment>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  const entityName = ownProps.entityName
  const entitiesName = pluralize(ownProps.entityName)
  return {
    fetchEntities: () => {
      dispatch(fetchResources())
      if (entitiesName !== 'invoices' && entitiesName !== 'consultations' && entitiesName !== 'events' && entitiesName !== 'dossierItems') {
        dispatch(fetchEntities(entitiesName))
      }
      if (ownProps.fetchExtraEntities) {
        ownProps.fetchExtraEntities()
      }
    },
  };
}

export default connect(null, mapDispatchToProps)(EntitiesList);
