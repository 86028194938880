import React from 'react'
import { Button, Card, Icon } from 'semantic-ui-react'
import DestroyModal from 'components/generics/DestroyModal'


export default class CalendarListItem extends React.Component {
  handleDestroy = () => {
    this.props.destroyEntity(this.props.calendar)
  }

  render () {
    const calendar = this.props.calendar
    return (
      <Card key={calendar.id} style={{boxShadow: `0 0 0 1px #d4d4d5,0 2px 0 0 ${calendar.color},0 1px 3px 0 #d4d4d5`}} >
      <Card.Content>
        <Card.Header>
          {calendar.summary}
        </Card.Header>
        <Card.Meta>{I18n.t(`components.calendar_list.calendar_type.${calendar.calendarType}`) }</Card.Meta>
      </Card.Content>
      <Card.Content extra>
        <Icon name='calendar' size='large' style={{color: calendar.color}}/>
        <DestroyModal
          handleDestroy={this.handleDestroy}
          trigger={(props) => { return(
            <Button color='red' size='mini' compact floated='right' inverted onClick={props.onClick}>
              <Icon name='trash'/> {I18n.t('components.calendar_list.destroy')}
            </Button>)
          }}>
        {I18n.t('components.calendar_list.destroy_warning')}
        </DestroyModal>
      </Card.Content>
    </Card>)
  }
}


