import React from 'react'
import { connect } from 'react-redux'

import dotProp from 'dot-prop'

import underscored from 'underscore.string/underscored'

import { actions as formActions } from "react-redux-form"

class SemanticTimelineWidget extends React.Component {
  render() {

    const { selectValue, entityModel, options } = this.props
    const selectOptions = options["selectOptions"]

    return (
      <div class="timeline-selector__container">
        <div class="timeline-selector__dates">
          <div class="timeline-selector__line">
            { selectOptions.map( (option) => {
              return (<div class="timeline__date">{option.value}</div>)
            })}
          </div>
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  var entityModel = `${ownProps.model || ''}.${ownProps.id}`
  var selectValue = dotProp.get(state,entityModel) || ownProps.options.emptyValue
  return {
    selectValue: selectValue,
    entityModel: entityModel
  }
}

export default connect(mapStateToProps)(SemanticTimelineWidget)
