import addressSchema from 'forms/addressSchema'

export const phoneMask = (rawValue) => {
  if(rawValue.replace(/[\s_+]/g,'').length <= 10) {
    return ['+', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ',  /\d/, /\d/]
  } else {
    return ['+', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ',/\d/, /\d/, ' ', /\d/, /\d/]
  }
}
const contactSliceSchema = function(state, props) {
  const uiSchema = {
    "gender": {
      "ui:widget": "hidden",
    },
    "startTime": {
      "ui:widget": "hidden",
    },
    "endTime": {
      "ui:widget": "hidden",
    },
    "phone": {
      "ui:group": 'email',
      "ui:options": {
        width: 6
      }
    },
    ...addressSchema(state,props)
  }

  return uiSchema
}

export default contactSliceSchema;
