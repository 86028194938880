import orm from 'models'
import { createSelector as ormSelector } from 'redux-orm'
import { createSelector } from 'reselect'
import getEntities from 'selectors/entities'

export const getOffices = ormSelector(orm, getEntities, (session) => {
  return session.office.all().toModelArray().map(office => {
    const { ref } = office
    return { ...ref}
  })
})

export const getCurrentOffice = createSelector([getOffices],
  (offices) => {
    return Object.values(offices).find( office => office.current)
  })

export const getCurrentOfficeId = createSelector([getCurrentOffice], office => office?.id)
