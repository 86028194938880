import React from 'react'
import { Label } from 'semantic-ui-react'
import { fromJS } from 'immutable';

export default class Tag extends React.Component {
  render() {
    const { entityKey,
      children,
      decoratedText,
      className,
      contentState,
    } = this.props;

    return (<Label color="pink">{children}</Label>);
  }
}

