import { fk, attr } from 'redux-orm'
import Base from './Base'

class Country extends Base {
  static get fields() {
    return {
      id: attr(),
      key: attr(),
      flag: attr(),
      value: attr(),
      text: attr(),
    }
  }
}

Country.modelName = 'country'

export default Country;
