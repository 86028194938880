import { createSelector } from 'reselect'
import { createSelector as ormSelector } from 'redux-orm'
import getEntities from 'selectors/entities'
import orm from 'models'

export const getHealthFunds = ormSelector(orm, getEntities, (session) => {
  return session.healthFund.all().toModelArray().map(healthFund => {
    const { ref } = healthFund
    return { ...ref }
  })
})
