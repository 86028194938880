import HealthFundForm from 'components/superuser/health_funds/HealthFundForm';
import HealthFundsListContainer from 'components/superuser/health_funds/HealthFundsListContainer';
import HealthFundsMenuContainer from 'components/superuser/health_funds/HealthFundsMenuContainer';
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Segment, Sidebar } from 'semantic-ui-react'

export default class HealthFunds extends React.Component {
  render () {
    return (
      <div>
        <Switch>
          <Route path={Routes.superuser_health_fund_path({id: ":id", _options: true})} render={ props => (
            <Sidebar.Pushable as={Segment} className= "min-height-90">
              <HealthFundsMenuContainer/>
              <Sidebar.Pusher className="left-sidebar-margin">
                <HealthFundForm entityId={props.match.params.id}/>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
           )} />

          <Route path={Routes.superuser_health_funds_path()} component={HealthFundsListContainer}/>
        </Switch>
      </div>
    )
  }
}

