import { SET_SETTING } from 'actions/settings'

import dotProp from 'dot-prop-immutable'

const initialState = {
}

export default function settings(state = initialState, action) {
  switch (action.type) {
    case SET_SETTING:
      return { ...state,...action.payload};
  }
  return state;
}
