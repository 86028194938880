import history from 'custom_history'

import logic from 'logic'
import { createRootReducer } from 'reducers'
import { createRootReducer as createAdminRootReducer } from 'reducers/admin'
import { createRootReducer as createSuperuserRootReducer } from 'reducers/superuser'

import { applyMiddleware, compose, createStore } from 'redux'
// Devtools
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogicMiddleware } from 'redux-logic'
import { persistStore } from 'redux-persist'
// Redux stuff
import thunk from 'redux-thunk'

const indexResource = (resourceName, attributes) => {
  return ({ resources , indexDocument, state }) => {
    Object.keys(resources).forEach(id => {
      let resource = resources[id]
      attributes.forEach( (attribute) => {
        if (resource.attributes[attribute]) {
          indexDocument(id, resource.attributes[attribute])
        }
      })
    })
  }
}


export const configureStore = initialState => {

  let appliedMiddlewares = null

  let middlewares = null

  const logicMiddleware = createLogicMiddleware(logic);

  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    appliedMiddlewares = applyMiddleware(thunk, logicMiddleware)
    middlewares = composeWithDevTools(appliedMiddlewares)
  } else {
    appliedMiddlewares = applyMiddleware(thunk, logicMiddleware)
    middlewares = compose( appliedMiddlewares)
  }

  var store = createStore(createRootReducer(history), initialState, middlewares)

  let persistor = null
  if (process.env.NODE_ENV !== 'test') {
    persistor = persistStore(store);
  }

  return { store, persistor }
}

export const configureSuperuserStore = initialState => {
  const thunkApplied = applyMiddleware(thunk)
  let middlewares = null

  if (process.env.NODE_ENV === 'development') {
    middlewares = composeWithDevTools(thunkApplied)
  } else {
    middlewares = compose( thunkApplied)
  }

  var store = createStore(createSuperuserRootReducer(history), initialState, middlewares)

  return { store };
}

export const configureAdminStore = initialState => {
  const thunkApplied = applyMiddleware(thunk)
  let middlewares = null

  if (process.env.NODE_ENV === 'development') {
    middlewares = composeWithDevTools(thunkApplied)
  } else {
    middlewares = compose( thunkApplied)
  }

  var store = createStore(createAdminRootReducer(history), initialState, middlewares)

  return { store };
}

