import { fk, attr } from 'redux-orm'
import Base from './Base'

class Organisation extends Base {
  static get fields() {
    return {
      id: attr(),
      name: attr(),
      organisationType: attr(),
      enterpriseNumber: attr(),
      email: attr(),
      addressId: fk({to: 'address', as: 'address', relatedName: 'organisation'})
    }
  }
}

Organisation.modelName = 'organisation'

export default Organisation;
