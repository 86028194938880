import React from 'react'

import 'semantic-ui-popup/popup.min.js'
import 'semantic-ui-transition/transition.min.js'

import { actions } from 'react-redux-form'
import { connect } from 'react-redux'

import moment from 'moment'

import dotProp from 'dot-prop'
import { DateInput, DateTimeInput, TimeInput } from "semantic-ui-calendar-react";

moment.locale('nl')

class SemanticDateWidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dateString: props.date || ''
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.date !== this.props.date) {
      this.setState({dateString: this.props.date || ''})
    }
  }

  parseDate = (value) => {
    if(value.length > 0) {
      const formats = [ "DDMMYYYY", "DD-MM-YYYY",  "DD/MM/YYYY", "DD-M-YYYY",  "DD/M/YYYY",  "D-MM-YYYY",  "D/MM/YYYY", "D-M-YYYY",  "D/M/YYYY"]
      for (var i = 0; i < formats.length; i++) {
        var format = formats[i] + this.props.formatTimeSuffix
        var parsedDate  = moment(value, format, true)
        if(parsedDate.isValid()) {
          return parsedDate
        }
      }
      return null
    } else {
      return null
    }
  }

  handleChange = (event, { value }) => {
    const { entityModel, date } = this.props
    this.setState({dateString: value});
    if (!value) {
      return this.props.dispatch(actions.change(entityModel, ''))
    }

    if (this.parseDate(value)) {
      return this.props.dispatch(actions.change(entityModel, this.parseDate(value).format("YYYY-MM-DD HH:mm:ssZ")))
    }
  }

  render() {
    const { entityModel, date } = this.props
    const { dateString } = this.state
      switch (this.props.type){
          case "time":
              return (
                      <TimeInput dateFormat={'DD-MM-YYYY'} initialDate={date} closable
                                 value={dateString} onChange={this.handleChange} popupPosition='bottom center'>
                      </TimeInput>
              )
          case "date":
              return (
                      <DateInput dateFormat={'DD-MM-YYYY'} initialDate={date} closable startMode={this.props.startMode}
                                 value={dateString} onChange={this.handleChange} popupPosition='bottom center'>
                      </DateInput>
              )
          case "datetime":
              return (
                      <DateTimeInput dateFormat={'DD-MM-YYYY'} initialDate={date} closable
                                 value={dateString} onChange={this.handleChange} popupPosition='bottom center'>
                      </DateTimeInput>
              )
          case "duration":
              return (
                      <TimeInput dateFormat={'DD-MM-YYYY'} initialDate={date} closable
                                     value={dateString} onChange={this.handleChange} popupPosition='bottom center'>
                      </TimeInput>
              )
          default:
              return (
                      <DateInput dateFormat={'DD-MM-YYYY'} initialDate={date} closable
                                 value={dateString} onChange={this.handleChange} popupPosition='bottom center'>
                      </DateInput>
              )
      }


  }
}

const mapStateToProps = (state, ownProps) => {
  var entityModel = `${ownProps.model || ''}.${ownProps.id}`
  var formatTimeSuffix = (ownProps.type === 'datetime') ? " HH:mm" : ""
  var rawDate = moment(dotProp.get(state, entityModel), "YYYY-MM-DD HH:mm:ssZ")
  if (rawDate.isValid()) {
    var date = rawDate.format("DD-MM-YYYY" + formatTimeSuffix)
  }
  return {
    date: date,
    entityModel: entityModel,
    formatTimeSuffix: formatTimeSuffix
  }
}


export default connect(mapStateToProps)(SemanticDateWidget);
