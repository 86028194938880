import React from 'react'
import SchemaForm from 'components/forms/SchemaForm'
import { connect } from 'react-redux'

import { fetchEntities } from 'actions/entities'
import dossierCommunicationSchema from 'forms/dossierSchema'

class DossierCommunicationForm extends React.Component {
  componentDidMount() {
    this.props.fetchEntities()
  }

  render () {
    return (
      <SchemaForm entityName='dossier' {...this.props}/>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    uiSchema: dossierCommunicationSchema(state, ownProps),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('employments'))
      dispatch(fetchEntities('locations'))
      dispatch(fetchEntities('countries'))
      dispatch(fetchEntities('contacts'))
      dispatch(fetchEntities('organisations'))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null )(DossierCommunicationForm);
