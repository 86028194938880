import { createSelector as ormSelector } from 'redux-orm'
import orm from  'models'
import getEntities from 'selectors/entities'

export const getTagsFromEvent = (id) => {
    return ormSelector(orm, getEntities, (session) => {
        return session.eventConsultationTypeTag.all().filter((tag) => { return (tag.eventId === id) } ).toModelArray().map(tag => {
            const { ref } = tag
            return { ...ref,
                consultationType: session.consultationType.withId(tag.consultationTypeId)}
        })
    })
}