import React from 'react'
import { connect } from 'react-redux'
import { fetchEntities, filterEntities } from 'actions/entities';
import EntitiesMenu from 'components/entities/EntitiesMenu';
import { Menu, Segment } from 'semantic-ui-react'
import { NavLink, withRouter } from 'react-router-dom'
import LocationForm from 'components/locations/LocationForm'

import { getLocations } from 'selectors/locations'

const mapStateToProps = (state, ownProps) => {
  return {
    entities: getLocations(state),
    entityName: 'location',
    form: LocationForm,
    item: (props) => <div>{`${props.location.name}`}</div>,
    loading: state.meta?.location?.loading,
    error: state.meta?.location?.error
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('locations'))
    },
    filterEntities: (filter) => {
      dispatch(filterEntities('locations', filter))
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EntitiesMenu));
