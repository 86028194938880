import React from 'react'
import { Sidebar, Item, Menu, Icon, Container, Grid, Segment, Header, Message, Button } from 'semantic-ui-react'

class ErrorPage extends React.Component {
  render() {
    const { error, redirectUrl } = this.props
    return (<div><Menu>
          <Menu.Item as='a' href={redirectUrl}>
            ZorgMee
          </Menu.Item>
        </Menu>
          <Grid verticalAlign="middle" textAlign="center" relaxed centered columns={1} className="min-height-90 full-height">
            <Grid.Column tablet={10} mobile={16} computer={6}>
              <Message error>
                <Header as='h2' icon textAlign='center'>
                  <Icon name='treatment' circular/>
                  <Header.Content>
                    {I18n.t('components.main_app.error_header')}
                  </Header.Content>
                  <Header.Subheader>
                    {I18n.t('components.main_app.error_subheader')}
                  </Header.Subheader>
                </Header>
              </Message>
              { error ?  <Message error> {error.message} </Message> : null }
              <Button as='a' href={redirectUrl} floated="right" color="green">
                  {I18n.t('components.main_app.continue')}
                  <Icon name="angle double right"/>
              </Button>
          </Grid.Column>
        </Grid></div>)
  }
}

export default ErrorPage;
