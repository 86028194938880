import React from 'react'
import { fetchEntities, filterEntities } from 'actions/entities'
import { connect } from 'react-redux'
import { Card, Grid, Input, Select, Form, Container } from 'semantic-ui-react'
import { getCalendars} from 'selectors/calendars'
import { getDossiers } from 'selectors/dossiers'
import { getPractioners } from 'selectors/employments'
import { getConsultationTypes } from 'selectors/consultation_types'
import NewEvent from 'components/agenda/NewEvent'
import { getFilteredDossiers } from 'selectors/dossiers'
import { getFilteredCalendars} from 'selectors/calendars'
import { getFilteredConsultationTypes } from 'selectors/consultation_types'
import _ from 'lodash'

class NewEventsList extends React.Component {
  componentDidMount() {
    this.props.fetchEntities();
  }

  renderEvents() {
    const { filteredDossiers, filteredConsultationTypes, filteredCalendars } = this.props

  const events = _.flattenDeep(filteredDossiers.map( (dossier) => {
        return filteredConsultationTypes.map( (consultationType) => {
          return filteredCalendars.map( (calendar) => {
            return {
              dossier: dossier,
              consultationType: consultationType,
              calendar: calendar
            }
          })
      })
    }))


    return events.slice(0,12).map ( (eventProps) => <NewEvent {...eventProps}/>)
  }

  render () {
    const { dossiers, consultationTypes, employments, calendars, calendar} = this.props

    const selectOptionsCalendars = calendars.map((calendar) => { return { key: calendar.id, value: calendar.id, text: calendar.summary, icon: 'calendar' }})
    const selectOptionsConsultationTypes = consultationTypes.map((consultationType) => { return { key: consultationType.id, value: consultationType.id, text: consultationType.name, icon: consultationType.icon}})

    return (
      <Grid divided padded>
        <Grid.Row centered>
          <Grid.Column width={4} textAlign={'center'}>
            <Form>
              <Form.Field>
                <Select searchable options={selectOptionsCalendars} defaultValue={selectOptionsCalendars[0] && selectOptionsCalendars[0].value} onChange={(event, data) => this.props.selectCalendar(data.value)}/>
              </Form.Field>
              <Form.Field>
                <Select searchable options={selectOptionsConsultationTypes} defaultValue={selectOptionsConsultationTypes[0] && selectOptionsConsultationTypes[0].value} onChange={(event, data) => { this.props.selectConsultationType(data.value)}}/>
              </Form.Field>
              <Form.Field>
                <Input icon='search' placeholder={I18n.t('components.entities_list.search')} transparent onChange={(event) => this.props.filterDossiers(event.target.value)}/>
              </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column width={12}>
            <Card.Group>
              { this.renderEvents() }
            </Card.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    calendars: getCalendars(state),
    filteredCalendars: getFilteredCalendars(state),
    filteredDossiers: getFilteredDossiers(state),
    consultationTypes: getConsultationTypes(state),
    filteredConsultationTypes: getFilteredConsultationTypes(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () =>
    {
      dispatch(fetchEntities('calendars'))
      dispatch(fetchEntities('dossiers'))
      dispatch(fetchEntities('consultationTypes'))
    },
    filterDossiers: (filter) => {
      dispatch(filterEntities('dossiers', filter))
    },
    selectCalendar: (id) => {
      dispatch(filterEntities('calendars', { id: id}))
    },
    selectConsultationType: (id) => {
      dispatch(filterEntities('consultationTypes', { id: id}))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewEventsList)
