import React from "react";

import underscored from 'underscore.string/underscored'

import { Button, Grid, Header, Menu } from 'semantic-ui-react';

import { createEntityWithDefaults, deleteEntity, updateEntity } from 'actions/entities';

import { connect } from 'react-redux'

import pluralize from 'pluralize'

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { findEntity } from 'selectors/entities'


import { getDefaultRegistry, getUiOptions, retrieveSchema, toIdSchema, } from "react-jsonschema-form/lib/utils";

import ObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField'


const Handle = SortableHandle(() => <Menu.Item icon='resize vertical' />)

// Used in the two templates
function DefaultArrayItem(props) {
  const btnStyle = {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: "bold",
  };
  return (

    <Grid.Row key={props.sortingIndex} >
      <Grid.Column width={props.hasToolbar ? 14 : 16 }>
        {props.children}
      </Grid.Column>

      {props.hasToolbar && !props.readonly && (
        <Grid.Column width="2" >
          <Menu compact icon borderless secondary>
            <Handle/>
            {props.hasRemove && (
              <Menu.Item icon='trash' disabled={props.disabled || props.readonly} onClick={props.onDropIndexClick(props.sortingIndex)} />
            )}
          </Menu>
          <Menu compact icon borderless secondary>
            {(props.hasMoveUp || props.hasMoveDown) && (props.hasMoveUp ?
              <Menu.Item icon='arrow up' disabled={props.disabled || props.readonly} onClick={props.onReorderClick(props.sortingIndex, props.sortingIndex - 1)} /> : <Menu.Item icon='minus'/>

            )}

            {(props.hasMoveUp || props.hasMoveDown) && (props.hasMoveDown ?
              <Menu.Item icon='arrow down' disabled={props.disabled || props.readonly} onClick={props.onReorderClick(props.sortingIndex, props.sortingIndex + 1)} /> : <Menu.Item icon='minus'  />
            )}

          </Menu>
        </Grid.Column>
      )}
    </Grid.Row>
  );
}

const SortableArrayItem = SortableElement(DefaultArrayItem)

const SortableArrayField = SortableContainer(DefaultNormalArrayFieldTemplate)

function DefaultNormalArrayFieldTemplate(props) {
  return (
    <Grid padded="vertically" divided>
      <Grid.Row>
        <Grid.Column>
          <Header as='a'>
            {I18n.t(`api.forms.${underscored(props.entityName)}.title`)}
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={1}>
          <div>
            {props.canAdd && props.onAddClick && (
              <Button as='a' basic floated='right' icon="plus" onClick={props.onAddClick} disabled={props.disabled || props.readonly} />
            )}
          </div>
        </Grid.Column>
        <Grid.Column width={15}>
          <Grid>
            { !(props.items && props.items.length > 0) && props.readonly && I18n.t('generic.empty') }
            {props.items && props.items.map(item => <SortableArrayItem key={item.id} {...item}/>)}
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

class SemanticArrayField extends ObjectField {
  static defaultProps = {
    uiSchema: {},
    sortedItems: [],
    idSchema: {},
    required: false,
    disabled: false,
    readonly: false,
    autofocus: false,
  };

  get itemTitle() {
    const { schema } = this.props;
    return schema.items.title || schema.items.description || "Item";
  }

  isItemRequired(itemSchema) {
    if (Array.isArray(itemSchema.type)) {
      // While we don't yet support composite/nullable jsonschema types, it's
      // future-proof to check for requirement against these.
      return !itemSchema.type.includes("null");
    }
    // All non-null array item types are inherently required by design
    return itemSchema.type !== "null";
  }

  canAddItem(formItems) {
    const { schema, uiSchema } = this.props;
    let { addable } = getUiOptions(uiSchema);
    if (addable !== false) {
      // if ui:options.addable was not explicitly set to false, we can add
      // another item if we have not exceeded maxItems yet
      if (schema.maxItems !== undefined) {
        addable = formItems.length < schema.maxItems;
      } else {
        addable = true;
      }
    }
    return addable;
  }

  onAddClick = event => {
    event.preventDefault();

    this.props.addItem(this.props.entityName, { [`${this.props.formContext.entityName}Id`]: this.props.formContext.entityId, position: this.props.sortedItems.length })
  };

  onDropIndexClick = index => {
    return event => {
      if (event) {
        event.preventDefault();
      }
      var item = this.props.sortedItems[index];
      if(item) {
        this.props.deleteEntity(this.props.entityName, item.id)
      }
    };
  };

  onSortEnd = ({ oldIndex , newIndex } ) => {
    const { sortedItems, entityName } = this.props;
    var item = sortedItems[oldIndex];
    var swappedItem = sortedItems[newIndex];
    if(item && swappedItem) {
      this.props.swapItems(entityName, item, swappedItem);
    }
  }

  onReorderClick = (index, newIndex) => {
    return event => {
      if (event) {
        event.preventDefault();
        event.target.blur();
      }
      const { sortedItems, entityName } = this.props;
      var item = sortedItems[index];
      var swappedItem = sortedItems[newIndex];
      if(item && swappedItem) {
        this.props.swapItems(entityName, item, swappedItem);
      }
    };
  };

  onChangeForIndex = index => {
    return value => {
      const { sortedItems, onChange } = this.props;
      const newsortedItems = sortedItems.map((item, i) => {
        // We need to treat undefined items as nulls to have validation.
        // See https://github.com/tdegrunt/jsonschema/issues/206
        const jsonValue = typeof value === "undefined" ? null : value;
        return index === i ? jsonValue : item;
      });
      onChange(newsortedItems, { validate: false });
    };
  };

  onSelectChange = value => {
    this.props.onChange(value, { validate: false });
  };

  render() {
    const {
      schema,
      uiSchema,
      idSchema,
      registry = getDefaultRegistry(),
    } = this.props;
    const { definitions } = registry;
    if (!schema.hasOwnProperty("items")) {
      return (<div>schema: {JSON.stringify(schema)} is missing items</div>
      );
    }
    return this.renderNormalArray();
  }

  renderNormalArray() {
    const {
      schema,
      uiSchema,
      sortedItems,
      errorSchema,
      idSchema,
      name,
      required,
      disabled,
      readonly,
      autofocus,
      registry = getDefaultRegistry(),
      formContext,
      onBlur,
      onFocus,
    } = this.props;
    const title = schema.title === undefined ? name : schema.title;
    const { ArrayFieldTemplate, definitions, fields } = registry;
    const { TitleField, DescriptionField } = fields;
    const itemsSchema = retrieveSchema(schema.items, definitions);
    const arrayProps = {
      canAdd: this.canAddItem(sortedItems),
      items: sortedItems.map((item, index) => {
        const itemSchema = retrieveSchema(schema.items, definitions, item);
        const itemErrorSchema = errorSchema ? errorSchema[index] : undefined;
        const itemIdPrefix = idSchema.$id + "_" + index;
        const itemIdSchema = toIdSchema(
          itemSchema,
          itemIdPrefix,
          definitions,
          item
        );
        return this.renderArrayFieldItem({
          index,
          canMoveUp: index > 0,
          canMoveDown: index < sortedItems.length - 1,
          name: `${item.id}.id`,
          itemSchema: itemSchema,
          itemIdSchema,
          itemErrorSchema,
          itemData: item,
          itemUiSchema: uiSchema.items,
          autofocus: autofocus && index === 0,
          onBlur,
          onFocus,
        });
      }),
      className: `field field-array field-array-of-${itemsSchema.type}`,
      DescriptionField,
      disabled,
      idSchema,
      uiSchema,
      onAddClick: this.onAddClick,
      onSortEnd: this.onSortEnd,
      readonly,
      required,
      schema,
      title,
      entityName: this.props.entityName,
      TitleField,
      formContext,
      sortedItems,
      useDragHandle: true,
      helperClass: 'ui form grid'
    };

    // Check if a custom render function was passed in
    return (
      <React.Fragment>
        <div className='ui divider'/>
        <SortableArrayField {...arrayProps} />
      </React.Fragment>
    )
  }

  renderArrayFieldItem(props) {
    const {
      index,
      canRemove = true,
      canMoveUp = true,
      canMoveDown = true,
      name,
      itemSchema,
      itemData,
      itemUiSchema,
      itemIdSchema,
      itemErrorSchema,
      autofocus,
      onBlur,
      onFocus,
      arrayModel,
      formContext
    } = props;

    const {
      disabled,
      readonly,
      uiSchema,
      registry = getDefaultRegistry(),
    } = this.props;
    const { fields: { SchemaField } } = registry;
    const { orderable, removable } = {
      orderable: true,
      removable: true,
      ...uiSchema["ui:options"],
    };
    const has = {
      moveUp: orderable && canMoveUp,
      moveDown: orderable && canMoveDown,
      remove: removable && canRemove,
    };
    has.toolbar = Object.keys(has).some(key => has[key]);

    return {
      children: (
        <SchemaField
          schema={itemSchema}
          uiSchema={itemUiSchema}
          errorSchema={itemErrorSchema}
          idSchema={itemIdSchema}
          required={this.isItemRequired(itemSchema)}
          onChange={this.onChangeForIndex(index)}
          onBlur={onBlur}
          onFocus={onFocus}
          registry={this.props.registry}
          disabled={this.props.disabled}
          readonly={this.props.readonly}
          autofocus={autofocus}
          model={this.props.arrayModel}
          name={name}
          formContext={formContext}
        />
      ),
      className: "array-item",
      disabled,
      hasToolbar: has.toolbar,
      hasMoveUp: has.moveUp,
      hasMoveDown: has.moveDown,
      hasRemove: has.remove,
      itemData,
      sortingIndex: index,
      index, // Index is being used for sortable hoc, sortingIndex for the arrows different so they don't interfere
      onDropIndexClick: this.onDropIndexClick,
      onReorderClick: this.onReorderClick,
      readonly,
    };
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    swapItems: (entityName, firstItem, secondItem) => {
      dispatch(updateEntity(entityName, { id: firstItem.id, position: secondItem.position }))
      dispatch(updateEntity(entityName, { id: secondItem.id, position: firstItem.position }))
    },
    deleteEntity: (relationshipEntityName, relationshipEntityId) => {
      dispatch(deleteEntity(relationshipEntityName, relationshipEntityId))
    },
    addItem: (entityName, entity) => {
      dispatch(createEntityWithDefaults(entityName, entity))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  let parent = findEntity(state, ownProps.formContext.entityName, ownProps.formContext.entityId)

  var entityName = ownProps.uiSchema["ui:options"].entityName

  var entitiesName = pluralize(entityName)

  const arrayModel = `entities.${entityName}.byId`
  var items = []
  if (parent) {
    items = parent[entitiesName]?.toRefArray().filter( (item) => { return (item.deleted !== "1")})
  }

  var sortedItems = items && items.map( (item,index) => { return {...item, ...{trackingIndex: index}}}).sort( (firstItem, secondItem) => { return (firstItem.position - secondItem.position) })

  return {
    parent: parent,
    entityName: entityName,
    arrayModel: arrayModel,
    sortedItems: sortedItems
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SemanticArrayField);
