import React from "react";

import { Segment, Label } from 'semantic-ui-react';

class Note extends React.Component {
  render () {
    return (
      <Segment>
        <Label as='a' color='red' ribbon className={'unselectable'}>{I18n.t('components.note.title')}</Label>
        {this.props.children}
      </Segment>
    )
  }
}

export default Note;
