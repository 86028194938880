import { fetchEntities, filterEntities } from 'actions/entities';
import ContactForm from 'components/contacts/ContactForm'
import EntitiesMenu from 'components/entities/EntitiesMenu';
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getContacts } from 'selectors/contacts'

const mapStateToProps = (state, ownProps) => {
  return {
    entities: getContacts(state),
    entityName: 'contact',
    item: (props) => <div>{`${props.contact.firstName} ${props.contact.lastName}`}</div>,
    form: ContactForm,
    loading: state.meta?.contact?.loading,
    error: state.meta?.contact?.error
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('contacts'))
    },
    filterEntities: (filter) => {
      dispatch(filterEntities('contacts', filter))
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EntitiesMenu));
