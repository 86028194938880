import React from 'react'
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { CardDescription } from "semantic-ui-react";
import { CardContent } from "semantic-ui-react";
import { fetchEntities } from "../../actions/entities.js";
import { findEntity } from "../../selectors/entities/index.js";
import CollapsableCard from "./CollapsableCard.jsx";


class MessageListItem extends React.Component {

  componentDidMount() {
    this.props.fetchEntities();
  }
  render() {
    return (
      <CollapsableCard title={this.props.title}>
        <CardContent>
          <CardDescription>
            {this.props.message}
          </CardDescription>
        </CardContent>
        <CardContent>
          <Button color="green" floated='right'
                  onClick={() => this.props.updateNotification('resolved', this.props.notification.id)}>
            {I18n.t(`generic.ok`)}
          </Button>
        </CardContent>

      </CollapsableCard>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const notification = ownProps.notification
  const consultation = findEntity(state, 'consultation', notification.relevantObjectId)
  const dossier = consultation && consultation.dossier
  const patient = dossier && findEntity(state, 'contact', dossier.patientId)
  const event = consultation && findEntity(state, 'event', consultation.eventId)
  const startTime = event? I18n.l("time.formats.long", event.startTime) :''
  const name = patient ? patient.firstName + ' ' + patient.lastName : ''


  const title = I18n.t(notification.titleKey)
  const message = I18n.t(notification.messageKey, {date: startTime, name: name})

  return {
    title: title,
    message: message
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('consultations', { name: 'consultation', filter: { id: ownProps.notification.relevantObjectId }, page: 1}))
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(MessageListItem);

