import { updateEntity, upsertEntity } from 'actions/entities'
import React from 'react'

import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Grid, Icon, Label, List, Segment, Statistic } from 'semantic-ui-react'
import { findEntity } from "../../selectors/entities";

class ConsultationsListItem extends React.Component {

  render () {
    const { consultation, dossier, patient } = this.props
    if(dossier && patient) {
    return (
      <List.Item key={consultation.id} as={Segment} basic clearing>
        <List.Content as={Segment}>
          <Label corner='left'>
            <Icon name={(consultation.consultationType || {}).icon || 'question'} size='big'/>
          </Label>
          <Grid divided>
            <Grid.Row>
              <Grid.Column width={3} textAlign='center' verticalAlign='middle'>
                  { (consultation.event && consultation.event.startTime) ?  <Statistic value={I18n.l("date.formats.human_readable", consultation.event.startTime)} size='tiny'/> : null }
              </Grid.Column>
              <Grid.Column width={3} textAlign='center' verticalAlign='middle'>
                  { (consultation.event && consultation.event.startTime) ?  <Statistic value={I18n.l("time.formats.hour", consultation.event.startTime)} size='tiny'/> : null }
              </Grid.Column>
              <Grid.Column width={8}>
                 <NavLink to={Routes.dossier_path({id: consultation.dossier.id, _options: true})}>
                  <List.Header>{patient.firstName} {patient.lastName}</List.Header>
                  <List.Description>{(consultation.consultationType || {}).name || I18n.t('components.consultations_list.unknown_consultation_type')}</List.Description>
                 </NavLink>
              </Grid.Column>
              <Grid.Column width={2}>
                { this.renderInvoiceCheck() }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </List.Content>
      </List.Item>
    )
    } else {
      return null
  }
}

  renderInvoiceCheck() {
    const { consultation, invoice } = this.props

    if (invoice && !invoice.proposed) {
      return  (
        <NavLink to={Routes.invoice_path({ id: invoice.id, _options: true})}>
          <Icon.Group size='large'>
            <Icon name='book'/> <Icon corner name='check circle' color='green'/>

          </Icon.Group>
        </NavLink> )
    } else {
      return  (<Icon.Group size='large' onClick={() => {this.props.toggleNoInvoice(consultation) }}>
        <Icon name='book' link={true}/>
        { consultation.noInvoice && <Icon corner name='ban' color='red'/> }
      </Icon.Group>)
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const consultation = ownProps.consultation
  const dossier = consultation.dossier

  const patient = dossier && findEntity(state, 'contact', dossier.patientId)
  const invoiceItem = findEntity(state, 'invoiceItem', consultation.invoiceItemId)
  const invoice = invoiceItem && invoiceItem.invoiceId
  return {
    invoiceItem: invoiceItem,
    invoice: invoice,
    dossier: dossier,
    patient: patient
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNoInvoice: (consultation) => {
      dispatch(updateEntity('consultation', {id: consultation.id, noInvoice: !consultation.noInvoice}))
      dispatch(upsertEntity('consultation', consultation.id))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationsListItem);
