export const getSearchResults = (state) => {
  let results = ((state.searchResults || {}).results || {})

  if(!state.searchResults.searchArchives && state.searchResults.value.length > 2 && _.isEmpty(results)){
    results = {
      ...results, "archive": {
        "name": "Archief",
        "results": [
          {
            "title": I18n.t('components.search_menu_item.no_results'),
            "description": I18n.t('components.search_menu_item.search_archives'),
            "archivebutton": "true"
          }
        ]
      }
    }
  }
  return results
}
