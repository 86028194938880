import React from 'react'
import { Modal } from 'semantic-ui-react-with-store'

class SetupWizardStep extends React.Component {

  render() {
    let { open, text, text2, content, nextButton } = this.props

    return (
      <Modal size="large" open={open} dimmer={'blurring'}>
         <Modal.Header>{I18n.t('components.setup_wizard.title')}</Modal.Header>
         <Modal.Content> {text} </Modal.Content>
         {text2? <Modal.Content> {text2} </Modal.Content> : null }
         <Modal.Content>{content} </Modal.Content>
         <Modal.Actions>{nextButton}</Modal.Actions>
       </Modal>
    )
  }
}

export default SetupWizardStep;
