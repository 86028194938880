import React from 'react'
import { Card } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

export default class ContactsListItem extends React.Component {

  render () {
    const contact = this.props.contact
    return (
      <Card as={NavLink} key={contact.id} to={Routes.contact_path({id: contact.id, _options: true})}>
        <Card.Content>
          <Card.Header>
            {contact.firstName} {contact.lastName}
          </Card.Header>
          <Card.Meta>
            {contact.createdAt && I18n.t('components.contacts_list_item.since', { date: I18n.l("date.formats.default", contact.createdAt) })}
          </Card.Meta>
        </Card.Content>
    </Card>)
  }
}

