import dotProp from "dot-prop";
import React from 'react'
import { connect } from "react-redux";
import ui from 'redux-ui'
import { Popup as SemanticPopup } from 'semantic-ui-react'

class Popup extends React.Component {

    closeIfNoModalOpen(open){
        if(!open) {
            this.props.updateUI('popupOpen', false)
            this.props.onClose()
        }
    }

    render() {
        const { modalOpen,  style, onClose, ...rest } = this.props
        const {uiKey, defaultState, defaultKey, mountUI, updateUI, WrappedComponent, dispatch,
            ...filteredProps} = rest

        return ( <SemanticPopup style={{ zIndex: 999, ...style }}
                        open={this.props.ui.popupOpen}
                        onClose={() => this.closeIfNoModalOpen(modalOpen)}
                        onOpen={() => this.props.updateUI('popupOpen', true)} {...filteredProps}>
            {this.props.children}
        </SemanticPopup>)
    }
}

const mapStateToProps = (state) => {
    let modalOpen = false
    Object.entries(dotProp.get(state, 'ui')).forEach(uiKey => {
        if ((uiKey[0].includes("NewEntityModal")||(uiKey[0].includes("EditModal"))) && uiKey[1].modalOpen) {
            modalOpen = true
        }
    })

    return {
        modalOpen: modalOpen
    };
}

export default ui( {state: { popupOpen: false}})(connect(mapStateToProps)(Popup));
