const addressSchema = function(state, props) {
  const countriesOptions = Object.keys(state.entities.country.byId).map( (countryId) => { return state.entities.country.byId[countryId] })

  return {
    "addressId": {
      "ui:field": "NestedFormField",
      "ui:options": {
        "entityName": "address",
        "removable": true,
        "displayTitle": true
      },
      "ui:schema": {
        "inputAddress": {
          "ui:group": "inputAddress",
          "ui:widget": "GeocoderWidget",
          "ui:options": {
            width: 12,
          }
        },
        "street": {
          "ui:group": "street" ,
          "ui:options": {
            width: 8,
          }
        },
        "houseNumber": {
          "ui:group": "street",
          "ui:options": {
            width: 2,
          }
        },
        "bus": {
          "ui:group": "street",
          "ui:options": {
            width: 2,
          }
        },
        "postalCode": {
          "ui:group": "city",
          "ui:options": {
            width: 2,
          }
        },
        "city": {
          "ui:group": "city",
          "ui:options": {
            width: 6,
          }
        },
        "country": {
          "ui:placeholder": "",
          "ui:options": {
            selectOptions: countriesOptions,
            searchable: true,
            width: 6,
          },
          "ui:widget": "select",
        }
      }
    }
  }
}

export default addressSchema;
