import { fk, attr } from 'redux-orm'
import Base from './Base'

class EmailCommunication extends Base {
  static get fields() {
    return {
      id: attr(),
      createdAt: attr(),
      subject: attr(),
      body: attr(),
      emailCommunicationType: attr(),
      invoiceId :fk('invoice'),
      userId :fk('user'),
      employmentId :fk('employment'),
      dossierId :fk('dossier')
    }
  }
}

EmailCommunication.modelName = 'emailCommunication'

export default EmailCommunication;
