import moment from "moment/moment.js";
import React from 'react'
import ui from 'redux-ui'
import { DateInput } from 'semantic-ui-calendar-react'
import { Button, Form, Header, Icon } from 'semantic-ui-react'
import { Modal } from 'semantic-ui-react-with-store'

class DossierCertificateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {start: '' ,datesRange:''}
  }

  handleStartChange = (event,props) => {
    this.setState({start: props.value})
  }

  handleSubmit = () => {
   this.props.updateUI('open', false)
  }

  render() {
    let initialDate = moment().startOf('year').format("DD-MM-YYYY")
    return (
      <Modal size='mini'
      trigger={this.props.trigger} closeIcon
      open={this.props.ui.open}
      onClose={() => this.props.updateUI('open', false)}
      onOpen={() => this.props.updateUI('open', true)}
      >
        <Header content={I18n.t('components.dossier.documents.create_health_fund_certificate')} />
        <Modal.Content>
          <Form>
            <Form.Field>
                <label>{I18n.t('components.dossier.documents.health_fund_certificate_from')}</label>
                <DateInput dateFormat={'DD-MM-YYYY'} value={this.state.start} initialDate={initialDate} onChange={this.handleStartChange} popupPosition='bottom center' size='mini' className='compact'/>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green'
          href={Routes.documents_path({id: this.props.dossierId, start_date: this.state.start, format: 'pdf', _options: true, type:'healthfund'})}
          onClick={this.handleSubmit}>
            <Icon name='file pdf outline' /> {I18n.t('generic.download')}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default ui({state: { open: false}})(DossierCertificateForm);
