import { fetchEntities, filterEntities } from 'actions/entities';
import DossierForm from 'components/dossiers/DossierForm'
import EntitiesMenu from 'components/entities/EntitiesMenu';
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'


import { getFilteredDossiers } from 'selectors/dossiers'

const mapStateToProps = (state, ownProps) => {

  return {
    entities: getFilteredDossiers(state),
    entityName: 'dossier',
    form: DossierForm,
    item: (props) => <div>{`${props.dossier.patient?.firstName} ${props.dossier.patient?.lastName}`}</div>,
    loading: state.meta.dossier?.loading,
    error: state.meta.dossier?.error
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('dossiers'))
    },
    filterEntities: (filter) => {
      dispatch(filterEntities('dossiers', filter))
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EntitiesMenu));
