import React from 'react'
import SchemaForm from 'components/forms/SchemaForm'
import { connect } from 'react-redux'
import { fetchEntities } from 'actions/entities'

import emailCommunicationSchema from 'forms/emailCommunicationSchema'

class EmailCommunicationForm extends React.Component {
  componentDidMount() {
    this.props.fetchEntities()
  }

  render () {
    return (
      <SchemaForm entityName='emailCommunication' {...this.props}/>
      )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    uiSchema: emailCommunicationSchema(state, ownProps)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('emailTemplates'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailCommunicationForm);
