import DossiersListContainer from 'components/admin/dossiers/DossiersListContainer';
import React from 'react'

export default class Dossiers extends React.Component {
  render () {
    return (
      <div>
        <DossiersListContainer/>
      </div>
    )
  }
}

