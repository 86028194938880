import fetch from 'isomorphic-fetch'

export const FETCH_INVOICES_GENERATE = 'FETCH_INVOICES_GENERATE';
export function fetchInvoicesGenerate() {
  return function(dispatch) {
    return fetch(Routes.generate_api_invoices_path(), {
       headers: {
         'X-Requested-With': 'XMLHttpRequest',
         'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
         'Content-Type': 'application/json',
         'Accept': 'application/json'
       },
       method: 'POST',
      'credentials': 'same-origin'}).then (
      response => response.json()
    ).then ()
  }
}
