import { fetchEntities } from 'actions/entities';

import NotificationsListItem from 'components/notifications/NotificationsListItem';

import ScrollContext from 'contexts/ScrollContext'
import dotProp from 'dot-prop'
import React from 'react'
import { connect } from 'react-redux'
import { getMenuItemNotifications } from 'selectors/notifications'
import { List, Segment, Visibility } from 'semantic-ui-react'

class NotificationsList extends React.Component {
  componentDidMount () {
    this.props.fetchEntities();
  }

  renderNotifications(notifications) {
    return notifications.map((notification) => {
      return <NotificationsListItem key={notification.id} notification={notification}/>;
    });
  }

  render () {
    const list = (this.props.notifications || [])
    const { loading, error, totalPages, activePage, onLoadMore, context } = this.props

    if(error) {
      return <div className="alert alert-danger">Error: {error.message}</div>
    }

    return (
      <Segment basic loading={loading}>
        <ScrollContext.Consumer>
          { scrollContext =>
              <Visibility onBottomVisible={(event, visibility) => onLoadMore(activePage, totalPages)} context={scrollContext.current} continuous={true} >
            { (list.length > 0) ?
              <List>
                {this.renderNotifications(list)}
              </List> : <span>{I18n.t('components.notifications_list.none')}</span>
            }
          </Visibility>
          }
        </ScrollContext.Consumer>
      </Segment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  return {
    notifications: getMenuItemNotifications(state),
    totalPages: dotProp.get(state, 'meta.notification.keyWindows.byWindow.menuItem.totalPages'),
    activePage: dotProp.get(state, 'meta.notification.keyWindows.byWindow.menuItem.page'),
    loading: state.meta.notification.loading,
    error: state.meta.notification.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadMore: (activePage, totalPages) => {
      if( activePage < totalPages ) {
        dispatch(fetchEntities('notifications', { name: 'menuItem', filter: {}, page: activePage + 1, append: true }))
      }
    },
    fetchEntities: () => {
      dispatch(fetchEntities('notifications', { name: 'menuItem', filter: {}, page: 1, append: true }))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);
