import { clearNewEntities, createEntityWithDefaults } from 'actions/entities';
import pluralize from 'pluralize'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import ui from 'redux-ui'
import { Button, Icon, Menu } from 'semantic-ui-react'
import { Modal } from 'semantic-ui-react-with-store'
import underscored from 'underscore.string/underscored'
import { refreshEntities } from "../../actions/entities";


class NewEntityModal extends React.Component {
  SubmitButton = (props) => {

    return(<Modal.Actions>
      <Button animated='vertical' color='green' inverted onClick={ (event, data) => {props.submit()}} >
            <Button.Content hidden>
      <Icon name='hand peace outline' />
      </Button.Content>
      <Button.Content visible>
      <Icon name='checkmark' /> {I18n.t('components.entities_list.submit')}
      </Button.Content>
      </Button>
       </Modal.Actions>)
  }

  handleCancel= () => {
    this.props.clearNewEntities()
    this.props.updateUI('modalOpen', false)
  }

  handleSubmitSuccess = (id) => {
    this.props.updateUI('modalOpen', false)
    if(this.props.handleSubmitSuccess){
      this.props.handleSubmitSuccess(id)
    }
    this.props.clearNewEntities()
    if(this.props.refreshAfterSave) {
      this.props.refreshEntities()
    }
  }


  render () {
    const EntityForm = this.props.form
    const Trigger = this.props.trigger ? this.props.trigger : this.defaultTrigger

    let SubmitButton = null
    if (this.props.SubmitButton) {
      SubmitButton =  ((props) => <this.props.SubmitButton onCancel={this.handleCancel} {...props}/>)

    } else {
      SubmitButton = this.SubmitButton
    }

    const entitiesName = pluralize(this.props.entityName)

    return (<Fragment>
      <Trigger key='trigger' onClick={() => { let id = this.props.createEntityWithDefaults(); this.props.updateUI('modalOpen', true); this.props.updateUI('modalEntityId',id) }} />
      <Modal key='modal' size='fullscreen' open={this.props.ui.modalOpen} onClose={() => this.props.updateUI('modalOpen', false)} closeIcon>
        <Modal.Header>{I18n.t(`components.${underscored(entitiesName)}_list.new`)}</Modal.Header>
        { this.props.ui.modalEntityId &&
        <EntityForm entityId={this.props.ui.modalEntityId} FormWrapper={Modal.Content} SubmitButton={SubmitButton} onSubmitSuccess={(id) => this.handleSubmitSuccess(id)}/> }
      </Modal>
    </Fragment>)
  }

  defaultTrigger = (props) => {
    return (
      <Menu.Item onClick={props.onClick}>
        <Icon name='add'/>
      </Menu.Item>)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  const entityName = ownProps.entityName
  const entitiesName = pluralize(ownProps.entityName)
  return {

    clearNewEntities: () => {
      return dispatch(clearNewEntities())

    },
    createEntityWithDefaults: () => {
      if(ownProps.createEntityWithDefaults) {
        return ownProps.createEntityWithDefaults(ownProps)
      } else {
        return dispatch(createEntityWithDefaults(entityName))

      }
    },
    refreshEntities: () => {
      return dispatch(refreshEntities(entitiesName))
    }
  };
}

export default ui({ state: { modalOpen: false, modalEntityId: null }})(connect(null, mapDispatchToProps)(NewEntityModal));
