export const SET_CHANNEL_CONNECTED= 'SET_CHANNEL_CONNECTED';
export function setChannelConnected(id) {
  return {
    type: SET_CHANNEL_CONNECTED,
    payload: {
      id: id,
    }
  };
}

export const SET_CHANNEL_DISCONNECTED= 'SET_CHANNEL_DISCONNECTED';
export function setChannelDisconnected(id) {
  return {
    type: SET_CHANNEL_DISCONNECTED,
    payload: {
      id: id,
    }
  };
}
