import React from 'react'
import { Form } from 'semantic-ui-react'
import { connect } from 'react-redux'

class SemanticBasicInputWidget extends React.Component {
  render() {
    return (<Form.TextArea value={this.props.value} onChange={this.props.handleChange}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let fullModel = `${ownProps.model}.${ownProps.id}`

  return {
    value: get(state, fullModel),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  let fullModel = `${ownProps.model}.${ownProps.id}`

  return {
    handleChange: (event) => {
      dispatch(formActions.change(fullModel, event.target.value))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SemanticBasicInputWidget);
