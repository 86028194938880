import React from 'react'
import { DropdownItem } from "semantic-ui-react";
import { DropdownMenu } from "semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import { Menu } from "semantic-ui-react";
import { Editor } from "slate";
import { Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { useSlate } from "slate-react";
import { useFocused } from "slate-react";
import { useSelected } from "slate-react";

export const withMentions = editor => {
  const { isInline, isVoid, markableVoid } = editor

  editor.isInline = element => {
    return element.type === 'mention' ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'mention' ? true : isVoid(element)
  }

  editor.markableVoid = element => {
    return element.type === 'mention' || markableVoid(element)
  }

  return editor
}


export const Mention = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  const style = {
    padding: '3px 3px 2px',
    margin: '0 1px',
    verticalAlign: 'baseline',
    display: 'inline-block',
    borderRadius: '4px',
    backgroundColor: selected && focused ? '#dcdcdc': '#eee',
    fontSize: '0.9em',
  }
  // See if our empty text child has any styling marks applied and apply those
  if(element.children[0].bold) {
    style.fontWeight = 'bold'
  }
  if(element.children[0].italic) {
    style.fontStyle = 'italic'
  }

  if(element.children[0].underline) {
    style.textDecoration = 'underline';
  }
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element.identifier.replace(' ', '-')}`}
      style={style}
    >
      {I18n.t(`components.rich_text.${element.identifier}`)}
      {children}
    </span>
  )
}

export const MentionsButton = (props) => {
  const editor = useSlate()
  const mentionOptions = props.mentionOptions
  return (
    <Menu.Item>
      <Dropdown item text={I18n.t('components.rich_text.add_mention')}>
        <DropdownMenu>
          {mentionOptions.map(option => {
            return (
              <DropdownItem
                key={`mention-option-${option}`}
                onClick={event => {
                event.preventDefault()
                insertMention(editor, option)
                ReactEditor.focus(editor)
              }}>{I18n.t(`components.rich_text.${option}`)}</DropdownItem>)
          })}
        </DropdownMenu>
      </Dropdown>
    </Menu.Item>
  )
}

const insertMention = (editor, identifier) => {
  const marks = Editor.marks(editor)
  const mention = {
    type: 'mention',
    identifier,
    children: [{ text: `@mention{${identifier}}`, ...marks }]
  }
  Transforms.insertNodes(editor, mention)
  Transforms.move(editor)
  for(const mark in marks) {
    Editor.addMark(editor, mark, marks[mark])
  }


}
