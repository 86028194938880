import SchemaForm from 'components/forms/SchemaForm'
import React from 'react'
import { connect } from 'react-redux'
import { fetchEntities } from "../../actions/entities.js";
import eventSchema from "../../forms/eventSchema";

class EventForm extends React.Component {
  componentDidMount () {
    this.props.fetchEntities();
  }

  render () {
    return (
      <SchemaForm entityName='event' {...this.props}/>
      )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    uiSchema: eventSchema(state, ownProps)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('dossiers'))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventForm);
