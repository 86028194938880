import ContactForm from "../components/contacts/ContactForm";

const relationshipSchema = function (state, props) {
  let uiSchema = {
    "relationshipType": {
      "ui:group": "line",
      "ui:field": "SelectorField",
      "ui:options": {
        "entityName": "relationship",
        "width": 3,
        "selectableOptions": ['standardparent', 'stepparent', 'gp', 'partner', 'other'],
      }
    },
    "contactId": {
      "ui:group": "line",
      "ui:field": "SelectorField",
      "ui:options": {
        "width": 4,
        "entityName": "contact",
        "addable": true,
        "editable": true,
        "EntityForm": ContactForm,
        "displayAttribute": (contact) => `${contact.firstName} ${contact.lastName}`,
        "fetchEntityAfterSelect": true
      }
    }
  }

  return uiSchema
}

export default relationshipSchema;
