import addressSchema from 'forms/addressSchema'

const officeSchema = function(state, props) {
  return {
    "name": {
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:options": {
        width: 6
      }
    },
    "rizivNumber": {
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:widget": "MaskedTextWidget",
      "ui:options" : {
        mask: [/\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/]
      }
    },
    ...addressSchema
  }
}

export default officeSchema;
