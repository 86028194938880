import fetch from 'isomorphic-fetch'

export function postFile(route, file) {
  var data = new FormData()
  data.append('file', file)

  return fetch(route, {
    method: 'POST',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content'),
    },
    body: data,
    'credentials': 'same-origin'
  }
  )
}

export function postJson(route, body) {
  return fetch(route,
    { body: JSON.stringify(body),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content'),
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      method: 'POST',
      'credentials': 'same-origin'})
}

export function getJson(route) {
  return fetch(route, {'credentials': 'same-origin'})
}

export function putJson(route, body) {
  return fetch(route,
    { body: JSON.stringify(body),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content'),
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      method: 'PUT',
      'credentials': 'same-origin'})
}

export function deleteRequest(route) {
  return fetch(route,
    { method: 'DELETE',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content'),
      },
      'credentials': 'same-origin'})
}
