import addressSchema from 'forms/addressSchema'

const setupLocationSchema = function(state, props) {
  const uiSchema = {
    "ui:readonly": props.readonly,
    "name": {
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:options": {
        width: 6
      }
    },
    "primaryColor": {
      "ui:widget": "hidden"
    },

    "logo": {
      "ui:widget": "hidden"
    },
    "responsibleId": {
      "ui:widget": "hidden"
    },
    ...addressSchema(state, props)
  }

  return uiSchema
}

export default setupLocationSchema;
