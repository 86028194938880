import { connect } from 'react-redux'
import { Card } from 'semantic-ui-react'

import { getCalendars } from 'selectors/calendars';
import { destroyEntity, refreshEntities } from 'actions/entities';

import EntitiesList from 'components/entities/EntitiesList';
import CalendarListItem from 'components/calendars/CalendarListItem';

import CalendarForm from './CalendarForm';
import { refreshEventsInCurrentView } from "../../actions/events";
import { refreshConsultationsInCurrentView } from "../../actions/consultations";

const mapStateToProps = (state, ownProps) => {
  return {
    entities: getCalendars(state, ownProps),
    entityName: 'calendar',
    item: CalendarListItem,
    form: CalendarForm,
    listComponent: Card.Group,
    loading: state.meta.contact?.loading,
    error: state.meta.contact?.error
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    destroyEntity: (calendar) => {
      dispatch(destroyEntity('calendar', calendar.id)).then(
        () => {
          dispatch(refreshEntities('calendars'))
          dispatch(refreshEventsInCurrentView())
          dispatch(refreshConsultationsInCurrentView())
          dispatch(refreshEntities('notifications', {name: 'menuItem', filter: {}, page: 1},
            ['eventDossierTag', 'generateConsultationError', 'eventConsultationTypeTag']));
        }
      )

    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesList)
