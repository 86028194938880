import React from 'react'
import { Menu, Popup } from 'semantic-ui-react'

export default class PdfItem extends  React.Component {
  render() {
    const { invoice } = this.props
    var message = invoice.printInvoiceWarning
    if (message) {
        return <Popup trigger={<Menu.Item icon='file pdf outline' />} header={I18n.t(`components.pdf_item.error.${message}`)}/>

    } else {
      return <Menu.Item icon='file pdf outline' href={Routes.invoice_path({id: invoice.id, format: 'pdf', _options: true})} as='a'/>
    }
  }
}
