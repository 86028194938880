const calendarSchema = function(state, props) {
  const uiSchema = {
    "calendarIdentification": {
      "ui:field": "SelectorField",
      "ui:group": "line",
      "ui:options": {
        width: 4,
        "entityName": "selectableCalendar",
        "displayAttribute": (calendar) => calendar.summary,
        "valueAttribute": (calendar) => calendar.calendarIdentification
      },
    },
    "calendarType": {
      "ui:widget": "select",
      "ui:group": "line",
      "ui:options": {
        width: 4,
        searchable: true,
        selectOptions: [
          {key: 'consultations', value: 'consultations', text: I18n.t('components.calendar_form.calendar_type.consultations') },
          {key: 'other', value: 'other', text: I18n.t('components.calendar_form.calendar_type.other') },
        ]
      }
    },
    "color": {
      "ui:group": "line",
      "ui:options": {
        width: 2
      },
      "ui:widget": "color"
    },
  }

  return uiSchema
}

export default calendarSchema;
