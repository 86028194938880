import React from 'react'
import { Message } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { destroyMessage } from 'actions/flash'

class Flash extends React.Component {

  renderMessages(messages) {
    return this.props.flash.messages.map((message) => <Message content={message.content} onDismiss={() => this.props.destroyMessage(message.key)} key={message.key} info={message.type === 'notice'} success={message.type === 'success'} warning={message.type === 'alert'} error={message.type === 'error'}/>
    )
  }

  render() {
    return (
      <div>
        {this.renderMessages(this.props.flash.messages) }
      </div>
    )
  }
}

const mapStateToProps = (state) => {

  return {
    flash: state && state.flash
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    destroyMessage: (messageKey) => {
      dispatch(destroyMessage(messageKey))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Flash);

