import React from 'react'
import { Input } from 'semantic-ui-react'

import { connect } from 'react-redux'
import { actions } from 'react-redux-form'

import dotProp from 'dot-prop'

class SemanticPriceWidget extends React.Component {
  render() {
    const { priceValue, options, entityModel } = this.props

    // lang='en-150' for both komma's and dots as currency. https://www.ctrl.blog/entry/html5-input-number-localization
    return (
      <Input icon='eur' value={priceValue} type='number' step='1.00' lang='en-150' onChange={ (event, props) => { this.props.dispatch(actions.change(entityModel, props.value))}}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var entityModel = `${ownProps.model || ''}.${ownProps.id}`
  var priceValue = dotProp.get(state,entityModel)
  return {
    priceValue: priceValue,
    entityModel: entityModel
  }
}

export default connect(mapStateToProps)(SemanticPriceWidget);
