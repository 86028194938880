import { createBrowserHistory } from 'history';
import { matchPath } from 'react-router-dom'

const history = createBrowserHistory();

let initialMatch = matchPath(window.location.pathname, { path: '/:officeSlug/*' })

var currentOfficeSlug = ''
if (initialMatch) {
  if (initialMatch.params.officeSlug === 'admin') {
    initialMatch = matchPath(window.location.pathname, {path: '/admin/:officeSlug/*'})
  }

  currentOfficeSlug = initialMatch.params.officeSlug;
}

if (currentOfficeSlug && currentOfficeSlug !== 'superuser') {
  Routes.configure({
    default_url_options: { office_slug: currentOfficeSlug}
  })
}

//history.listen((location, action) => {
//   const match = matchPath(location.pathname, { path: '/:officeSlug#<{(|' })
//   Routes.configure({
//     default_url_options: { office_slug: match.params.officeSlug }
//   })
// })


export default history;
