import { Modifier, EditorState } from 'draft-js';
import getSearchText from '../utils/getSearchText';
import getTypeByTrigger from '../utils/getTypeByTrigger';

const addTag = (editorState, tag, tagPrefix, tagTrigger, entityMutability) => {
  const contentStateWithEntity = editorState.getCurrentContent().createEntity(
    getTypeByTrigger(tagTrigger), entityMutability, { tag }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const currentSelectionState = editorState.getSelection();
  const { begin, end } = getSearchText(editorState, currentSelectionState, tagTrigger);

  // get selection of the @tag search text
  const tagTextSelection = currentSelectionState.merge({
    anchorOffset: begin,
    focusOffset: end,
  });

  let tagReplacedContent = Modifier.replaceText(
    editorState.getCurrentContent(),
    tagTextSelection,
    `${tagPrefix}${tag.get('name')}`,
    null, // no inline style needed
    entityKey
  );

  // If the tag is inserted at the end, a space is appended right after for
  // a smooth writing experience.
  const blockKey = tagTextSelection.getAnchorKey();
  const blockSize = editorState.getCurrentContent().getBlockForKey(blockKey).getLength();
  if (blockSize === end) {
    tagReplacedContent = Modifier.insertText(
      tagReplacedContent,
      tagReplacedContent.getSelectionAfter(),
      ' ',
    );
  }

  const newEditorState = EditorState.push(
    editorState,
    tagReplacedContent,
    'insert-tag',
  );
  return EditorState.forceSelection(newEditorState, tagReplacedContent.getSelectionAfter());
};

export default addTag;
