export const UPDATE_UI_STATE = 'UPDATE_UI_STATE';
export const MOUNT_UI_STATE = 'MOUNT_UI_STATE';

export function updateUI(key, name, value) {
  return {
    type: UPDATE_UI_STATE,
    payload: {
      key,
      name,
      value
    }
  };
};

export function mountUI(key, value) {
  return {
    type: MOUNT_UI_STATE,
    payload: {
      key,
      value
    }
  };
};

