import store from "../store";
import moment from "moment/moment";
import { refreshEntities } from "./entities";

export function refreshConsultationsInCurrentView() {
  return function (dispatch) {
    if(store.getState().ui.eventsCalendar) {
      let date = store.getState().ui.eventsCalendar.date
      let view = store.getState().ui.eventsCalendar.view
      let startTime = moment(date).startOf(view).toISOString()
      let endTime = moment(date).endOf(view).toISOString()
      dispatch(refreshEntities('consultations', { name: 'eventsCalendar', filter: { startTime: startTime, endTime: endTime}, page: 1 }))
    }
  }
}