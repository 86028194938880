import { attr, fk } from 'redux-orm'
import Base from './Base'

class EventDossierTag extends Base {
  static get fields() {
    return {
      id: attr(),
      dossierId :fk({to: 'dossier', as: 'dossier', relatedName: 'eventDossierTag'}),
      eventId :fk({to: 'event', as: 'event', relatedName: 'eventDossierTags'}),
    }
  }
}

EventDossierTag.modelName = 'eventDossierTag'

export default EventDossierTag;
