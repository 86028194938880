import { fk, attr } from 'redux-orm'
import Base from './Base'

class Address extends Base {
  static get fields() {
    return {
      id: attr(),
      createdAt: attr(),
      city: attr(),
      country: attr(),
      bus: attr(),
      lattitude: attr(),
      longitude: attr(),
      street: attr(),
      houseNumber: attr(),
      postalCode: attr()
    }
  }
}

Address.modelName = 'address'

export default Address;
