import React from 'react'
import { connect } from 'react-redux'
import ui from 'redux-ui'
import { Button, Header, Icon} from 'semantic-ui-react'
import { Modal } from 'semantic-ui-react-with-store'

class MainModal extends React.Component {
  render() {
    return (
      <Modal basic size='small' open={this.props.ui.isMainModalVisible}>
        <Header icon='sign in' content={I18n.t('components.main_modal.session_modal.title')} />
        <Modal.Content>
          <p>{I18n.t('components.main_modal.session_modal.text')}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' inverted href={Routes.user_google_oauth2_omniauth_authorize_path()}>
            <Icon name='checkmark' /> {I18n.t('components.main_modal.session_modal.login')}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default connect()(ui({ key: 'mainModal',  state: { isMainModalVisible: false } })(MainModal));
