import React from 'react'
import { connect } from 'react-redux'
import { fetchEntities, filterEntities } from 'actions/entities';
import EntitiesMenu from 'components/entities/EntitiesMenu';
import { Menu, Segment } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

import { getSortedInvoicesForMenu } from 'selectors/invoices'
import InvoiceForm from 'components/invoices/InvoiceForm'

import dotProp from 'dot-prop'

const mapStateToProps = (state, ownProps) => {
  return {
    entities: getSortedInvoicesForMenu(state),
    entityName: 'invoice',
    form: InvoiceForm,
    item: (props) => <div>{`${props.invoice.formattedIdentification} - ${props.invoice.patient?.lastName} ${props.invoice.patient?.firstName}`}{props.invoice.proposed ? "*" : "" }</div>,
    loading: state.meta.invoice?.loading,
    error: state.meta.invoice?.error,
    totalPages: dotProp.get(state, 'meta.invoice.keyWindows.byWindow.list.totalPages'),
    activePage: dotProp.get(state, 'meta.invoice.keyWindows.byWindow.list.page'),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('invoices', { name: 'menu', filter: {}, page: 1, append: true }))
      dispatch(fetchEntities('contacts'))
      dispatch(fetchEntities('emailCommunications'))
    },
    onLoadMore: (activePage, totalPages) => {
      if( activePage < totalPages ) {
        dispatch(fetchEntities('invoices', { name: 'menu', filter: {}, page: activePage + 1, append: true }))
      }
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EntitiesMenu));
