import { fk, attr } from 'redux-orm'
import Base from './Base'

class HealthFund extends Base {
  static get fields() {
    return {
      id: attr(),
      name: attr(),
      phone: attr(),
      email: attr(),
      addressId :fk('address')
    }
  }
}

HealthFund.modelName = 'healthFund'

export default HealthFund;
