import AppChannels from 'cable.js'
import store from 'store.js'
import { refreshEntities } from 'actions/entities'
import { setProgress, endProgress } from 'actions/progress'
import { setChannelConnected, setChannelDisconnected} from 'actions/channels'


export default function subscribeTagEventsRuns(calendarId) {
    AppChannels.tagEventsRuns = AppChannels.tagEventsRuns || {};

    if(!AppChannels.tagEventsRuns[calendarId]) {
      AppChannels.tagEventsRuns[calendarId] = AppChannels.cable.subscriptions.create({ channel: "TagEventsRunsChannel", id: calendarId }, {
        connected: function() {
          store.dispatch(setChannelConnected('tag_events_runs'))
        },

        disconnected: function() {
          store.dispatch(setChannelDisconnected('tag_events_runs'))
        },

        received: function(data) {
          switch (data.status) {
            case 'tagging_started':
              break;
            case 'tagging_running':
              store.dispatch(setProgress(`tag_events_run_${data.service_id}`, data.events_tagged, data.events_total, I18n.t(`channels.tag_events_run.statuses.${data.status}`), data.color));
              break;
            case 'tagging':
              store.dispatch(setProgress(`tag_events_run_${data.service_id}`, data.events_tagged, data.events_total, I18n.t(`channels.tag_events_run.statuses.${data.status}`), data.color));
              break;
            case 'ok':
              store.dispatch(setProgress(`tag_events_run_${data.service_id}`, data.events_tagged, data.events_total, I18n.t(`channels.tag_events_run.statuses.${data.status}`), data.color));
              store.dispatch(endProgress(`tag_events_run_${data.service_id}`));
            case 'failed':
              store.dispatch(endProgress(`tag_events_run_${data.service_id}`));
              break;
          }
        }
      });
    }
}
