import { createLogic } from 'redux-logic';

import formActions from 'react-redux-form/action-types';
import { actions as formActionCreators } from 'react-redux-form';
import moment from 'moment'

import dotProp from 'dot-prop';

const paidLogic = createLogic({
  type: formActions.CHANGE,
  process({ getState, action }, dispatch, done) {
    if (action.model.startsWith("entities.invoices") && action.model.endsWith("paid") && action.value && !dotProp.get(getState(), action.model.replace(/paid$/,'payedAt'))) {
      dispatch(formActionCreators.change(action.model.replace(/paid$/,'payedAt'), moment.utc((new Date())).format("YYYY-MM-DD")))
      done();
    } else  {
      done(); // only when performing multiple dispatches (done in signature)
    }
  }
})

export default [paidLogic];
