import RjsForm from "react-jsonschema-form"
import { findDOMNode } from "react-dom";

export default class Form extends RjsForm {
    componentDidMount() {
        const root = findDOMNode(this);
        root && root.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        const root = findDOMNode(this);
        root && root.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown = (event) => {
        // Prevent Enter press from activating submit action
        if (event.keyCode === 13) {
            const target = event.target;
            if(!target.ariaMultiLine){
                event.preventDefault();
                target.click();
            }
        }
    }
}
