import React from 'react'
import { Progress } from 'semantic-ui-react'
import { connect } from 'react-redux'
import hex2rgb from 'hex2rgb'

class ProgressBars extends React.Component {
  renderProgress(progress, id) {
    const color = progress.color || '#de7a22'
    let value = parseInt(progress.value || 0)
    let total = 0
    if (progress.total) {
      total = parseInt(progress.total)
    }


    if (total > 0) {
      return <Progress size="tiny"  key={`progress${id}`} value={value}  total={total} className={`color-${color.replace('#','')}`} progress='ratio' active label={progress.label} style={{backgroundColor: hex2rgb(color,0.1).css}} />
    } else {
      return <Progress size="tiny" key={`progress${id}`} percent={100} className={`color-${color.replace('#','')}`} active label={progress.label} style={{backgroundColor: hex2rgb(color,0.1).css}} />
    }

  }

  render() {
    const { progresses } = this.props
    return (
      <div>
        {Object.keys(progresses).map ( (id) => {
          var progress = progresses[id]
          return this.renderProgress(progress, id)
        })}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    progresses: state.progress.byId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBars);
