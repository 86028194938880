const consultationPriceBatchSchema = function (state, props) {
  return {
    "consultationPrices": {
      "ui:options": {
        "entityName": "consultationPrice",
        "displayTitle": false
      },
      "items": {
        "ui:field": "NestedFormField",
        "ui:options": {
          "entityName": "consultationPrice",
          "displayTitle": false,
          "inline": true
        },
        "ui:schema": {
          "ui:options": {
            "ui:width": 8
          },
          "position": {
            "ui:widget" :"hidden"
          },
          "price" : {
            "ui:group": "line",
            "ui:widget": "PriceWidget",
            "ui:options": {
              "ui:width": 2
            }
          },
          "startDate" : {
            "ui:group": "line",
            "ui:options": {
              "ui:width": 2
            }
          },
          "endDate" : {
            "ui:group": "line",
            "ui:options": {
              "ui:width": 2
            }
          },
          "practionerId": {
            "ui:group": "subLine",
            "ui:field": "SelectorField",
            "ui:widget": "hidden",
            "ui:options": {
              "ui:width": 2,
              "entityName": "employment",
              "displayAttribute": (employment) => `${employment.user.firstName} ${employment.user.lastName}`,
            }
          },
          "officeId": {
            "ui:widget": "hidden",
          },
          "locationId": {
            "ui:field": "SelectorField",
            "ui:group": "line",
            "ui:options": {
              "ui:width": 2,
              "entityName": "location",
              "displayAttribute": (location) => `${location.name}`,
              "includeBlank": true,
            }
          },
          "consultationTypeId": {
            "ui:field": "SelectorField",
            "ui:group": "line",
            "ui:options": {
              "ui:width": 2,
              "entityName": "consultationType",
              "displayAttribute": (consultationType) => `${consultationType.name}`,
              "includeBlank": true,
            }
          },
        }
      }
    },
  }
}

export default consultationPriceBatchSchema;

