import React from 'react'

import { Form, Label } from 'semantic-ui-react'
import underscored from 'underscore.string/underscored'

export default class SemanticFieldTemplate extends React.Component {
  render () {
    const {id, classNames, label, help, required, description, errors, children, entityName, formContext, displayLabel} = this.props;

    //dirty hack to know if the field is a single field (=> then display errors), or the field contains multiple fields (=> don't display errors)
    //@future-Friedl: so sorry for leaving tech debt, I hope you are smarter by the next time you read this and know how to solve this properly
    let errorsToDisplay = Array.isArray(errors) ? errors : null

    const {"ui:options": options, "ui:placeholder": placeholder, "ui:label": customLabel} = this.props.uiSchema
    const {width} = (options || {})
    const localEntityName = entityName || formContext?.entityName

    const labelToDisplay = customLabel ? customLabel : I18n.t(`api.forms.${underscored(localEntityName)}.${underscored(label)}`)

    return (
      <Form.Field className={classNames} width={width || 16}>
        {displayLabel ? <label htmlFor={id}>{labelToDisplay}{required ? "*" : null}</label> : null}
        {description}
        {children}
        {errorsToDisplay ? <Label basic color='red' pointing>{errorsToDisplay}</Label> : null}
        {help}
      </Form.Field>
    );
  }
}
