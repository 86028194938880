import orm from 'models'

import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { createSelector as ormSelector } from 'redux-orm'
import { createSelector } from 'reselect'
import getEntities from 'selectors/entities'
import { getKeyWindowSelector } from 'selectors/key_windows'

const moment = extendMoment(Moment)

const getConsultations = ormSelector(orm, getEntities, (session) => {
  return session.consultation.all().toModelArray()
                .filter(consultation => !consultation.deleted)
                .map(consultation => {
                  const { ref } = consultation
                  return {
                    ...ref,
                    dossier: session.dossier.withId(consultation.dossierId),
                    consultationType: session.consultationType.withId(consultation.consultationTypeId),
                    event: session.event.withId(consultation.eventId)
                  }
                })
})

export const getSortedConsultationsForListByKeyWindow = (keyWindow) => {
  return createSelector([getKeyWindowSelector('consultation', keyWindow, getConsultations)], (consultations) => _.sortBy(consultations, [(consultation) => consultation.event && consultation.event.startTime] ))
}

export const getSortedConsultationsForList = createSelector([getConsultations], (consultations) => _.sortBy(consultations, [(consultation) => consultation.event && consultation.event.startTime] ))

export const getSortedConsultations = createSelector([getConsultations], (consultations) => _.sortBy(consultations, [(consultation) => consultation.event && consultation.event.startTime] ))

const getFilter = (state, props) => props.filter || state.meta.consultation.filter

const getDateRange = (state, props) => moment.range(props.startDate, props.endDate)

const getDossier = (state, props) => props.dossier

const getInvoice = (state, props) => props.invoice

export const getConsultationsInDateRange = createSelector([getKeyWindowSelector('consultation', 'stats', getConsultations), getDateRange],
  (consultations, dateRange) => {
    return consultations.filter( consultation => consultation.event && consultation.event.startTime && dateRange.contains(moment(consultation.event.startTime)))
  }
)

export const getConsultationsForDossier = createSelector([getSortedConsultations, getDossier],
  (consultations, dossier) => {
    return consultations.filter( consultation => consultation.dossier.id === dossier.id)
  }
)

export const getFilteredConsultations = createSelector([getSortedConsultations, getFilter], (consultations, filter) => {
  if (!filter) {
    return consultations
  }

  const { startDate, endDate } = filter

  if (startDate) {
    consultations = consultations.filter( (consultation) => (consultation.event && consultation.event.startTime && (new Date(consultation.event.startTime) >= new Date(startDate))))
  }

  if (endDate) {
    consultations = consultations.filter( (consultation) => (consultation.event && consultation.event.endTime && (new Date(consultation.event.endTime) <= new Date(endDate))))
  }

  return consultations
})

export const getFilteredConsultationsForDossier =  createSelector([getFilteredConsultations, getDossier], (consultations, dossier) => {
  return consultations.filter(consultation => consultation.dossier.id === dossier.id)
})

export const getConsultationsForInvoice = createSelector([getSortedConsultations, getInvoice],
  (consultations, invoice) => {
    return consultations.filter(consultation => { return (consultation.invoiceItem && consultation.invoiceItem.invoice && ( consultation.invoiceItem.invoice.id === invoice.id))}).sort ((consultationA, consultationB) => ( consultationA.event && consultationB.event && consultationA.event.startTime && consultationB.event.startTime && ( consultationA.event.startTime >= consultationB.event.startTime)  ))
  }
)
