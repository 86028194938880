import { getJson } from 'api_helpers.js'

export const REQUEST_SEARCH_RESULTS = 'REQUEST_SEARCH_RESULTS';
export function requestSearchResults(query) {
  return {
    type: REQUEST_SEARCH_RESULTS,
    query: query
  };
}

export const RECEIVE_SEARCH_RESULTS = 'RECEIVE_SEARCH_RESULTS';
export function receiveSearchResults(results) {
  return {
    type: RECEIVE_SEARCH_RESULTS,
    results: results
  };
}

export const FETCH_SEARCH_RESULTS_FAILURE = 'FETCH_SEARCH_RESULTS_FAILURE';
export function fetchSearchResultsFailure(error) {
  return {
    type: FETCH_SEARCH_RESULTS_FAILURE,
    error: error
  };
}

export const SET_SEARCH_ARCHIVES = 'SET_SEARCH_ARCHIVES';
export function setSearchArchives(searchArchives) {
  return {
    type: SET_SEARCH_ARCHIVES,
    searchArchives: searchArchives
  };
}

export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export function clearSearch() {
  return {
    type: CLEAR_SEARCH,
  };
}

export const FETCH_SEARCH_RESULTS= 'FETCH_SEARCH_RESULTS';
export function fetchSearchResults(query) {
  return function(dispatch, getState) {

    dispatch(requestSearchResults(query))
    const searchArchives = getState().searchResults.searchArchives
    const searchResultsPath = Routes.api_search_results_path({query: query, searchArchives: searchArchives})

    return getJson(searchResultsPath).then (
      response => response.json(),
      error => dispatch(fetchSearchResultsFailure(error))
    ).then (
      json => {
        dispatch(receiveSearchResults(json));
      }
    )
  }
}
