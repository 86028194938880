import React from 'react'
import { connect } from 'react-redux'
import { Card } from 'semantic-ui-react'

import EntitiesList from 'components/entities/EntitiesList';
import OrganisationsListItem from 'components/organisations/OrganisationsListItem'
import OrganisationForm from 'components/organisations/OrganisationForm'

import { getOrganisations } from 'selectors/organisations'

const mapStateToProps = (state, ownProps) => {
  return {
    entities: getOrganisations(state, ownProps),
    entityName: 'organisation',
    item: OrganisationsListItem,
    form: OrganisationForm,
    listComponent: Card.Group,
    loading: state.meta.organisation.loading,
    error: state.meta.organisation.error
  };
}

export default connect(mapStateToProps)(EntitiesList)
