import React from 'react'
import {TwitterPicker as Picker} from 'react-color'

import { Input, Icon } from 'semantic-ui-react'
import { actions as formActions } from 'react-redux-form'
import { connect } from 'react-redux'

import dotProp from 'dot-prop'

class SemanticColorWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        displayColorPicker: false,
    };

    this.wrapperRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }


  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ displayColorPicker: false })
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleChange = () => {
    this.setState({ displayColorPicker: false })
  };


  render() {
    var entityModel = `${this.props.model || ''}.${this.props.id}`

    return (
      <div ref={this.wrapperRef}>
        <Input
          value={this.props.color}
          onClick={this.handleClick}
          onChange={this.handleChange}
          icon="paint brush"
          style={{ color: this.props.color }}
        />

        {this.state.displayColorPicker && (
          <Picker
            color={this.props.color}
            onChange={(color, event) => {
              this.props.dispatch(formActions.change(entityModel, color.hex));
            }}
            styles={{
              default: {
                input: {
                  padding: "1px 2px",
                },
              },
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  var entityModel = `${ownProps.model || ''}.${ownProps.id}`
  var color = dotProp.get(state,entityModel) || '#6AB187'
  return {
    color: color,
    entityModel: entityModel
  }
}

export default connect(mapStateToProps)(SemanticColorWidget);
