import { createSelector as ormSelector } from "redux-orm/lib/redux";
import { createSelector } from 'reselect'
import orm from "../../models";
import { getCurrentEmployment } from "../employments";
import getEntities from "../entities";


export const getInvoiceConfigurations = ormSelector(orm, getEntities, (session) => {
  return session.invoiceConfiguration.all().toModelArray().map(conf => {
    const { ref } = conf
    return { ...ref, }
  })
})



export const getInvoiceConfiguration =
  createSelector([getInvoiceConfigurations, getCurrentEmployment],
                 (configurations, employment) => {
                   return configurations.filter(conf => conf.responsibleId === employment.id)[0]
                 }
  )

