import AppChannels from 'cable.js'
import store from 'store.js'
import { endProgress, setProgress } from 'actions/progress'
import { setChannelConnected, setChannelDisconnected } from 'actions/channels'
import { refreshEventsInCurrentView } from "../actions/events";


export default function subscribeCalendarSync(calendarId) {
    AppChannels.calendarSyncs = AppChannels.calendarSyncs || {};

    if(!AppChannels.calendarSyncs[calendarId]) {
      AppChannels.calendarSyncs[calendarId] = AppChannels.cable.subscriptions.create({ channel: "CalendarSyncChannel", id: calendarId }, {
        connected: function() {
          store.dispatch(setChannelConnected('calendar_sync'))
        },

        disconnected: function() {
          store.dispatch(setChannelDisconnected('calendar_sync'))
        },

        received: function(data) {
          switch (data.status) {
            case 'sync_started':
              break;
            case 'sync_running':
              store.dispatch(setProgress(`sync_${data.calendar_sync_id}`, data.events_synced, data.events_total, I18n.t(`channels.calendar_sync.statuses.${data.status}`) + data.summary, data.color));
              break;
            case 'syncing':
              store.dispatch(setProgress(`sync_${data.calendar_sync_id}`, data.events_synced, data.events_total, I18n.t(`channels.calendar_sync.statuses.${data.status}`) + data.summary, data.color));
              break;
            case 'ok':
              store.dispatch(setProgress(`sync_${data.calendar_sync_id}`, data.events_synced, data.events_total, I18n.t(`channels.calendar_sync.statuses.${data.status}`), data.color));
              store.dispatch(endProgress(`sync_${data.calendar_sync_id}`));
              store.dispatch(refreshEventsInCurrentView())
              // Called when there's incoming data on the websocket for this channel
            case 'failed':
              store.dispatch(endProgress(`sync_${data.calendar_sync_id}`));
              store.dispatch(refreshEventsInCurrentView())
              break;
          }


        }
      });
    }
}
