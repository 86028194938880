import { fk, attr } from 'redux-orm'
import Base from './Base'

class SelectableCalendar extends Base {
  static get fields() {
    return {
      id: attr(),
      calendarIdentification: attr(),
      summary: attr(),
      description: attr(),
      color: attr()
    }
  }
}

SelectableCalendar.modelName = 'selectableCalendar'

export default SelectableCalendar;
