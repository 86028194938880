const emailCommunicationSchema = function(state, props) {

  return {
    "ui:readonly": props.readonly,
    "subject": {
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:options": {
        width: 6
      },
    },
    "body": {
      "ui:widget": "DraftWidget",
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:options": {
        width: 8
      },
    },
    "emailCommunicationType": {
      "ui:widget" : "hidden"
    },
    "dossierId": {
      "ui:widget" : "hidden"
    },
    "invoiceId": {
      "ui:widget" : "hidden"
    }
  }
}

export default emailCommunicationSchema;

