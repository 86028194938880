export * from './Address'
export * from './Calendar'
export * from './Contact'
export * from './ContactSlice'
export * from './Consultation'
export * from './ConsultationType'
export * from './ConsultationPrice'
export * from './Country'
export * from './Dossier'
export * from './DossierItem'
export * from './DossierSlice'
export * from './EmailCommunication'
export * from './Employment'
export * from './Event'
export * from './HealthFund'
export * from './Invoice'
export * from './InvoiceConfiguration'
export * from './InvoiceItem'
export * from './Location'
export * from './Office'
export * from './Organisation'
export * from './User'
export * from './SelectableCalendar'
export * from './ConsultationReminder'
export * from './EmailTemplate'
export * from './EventConsultationTypeTag'
export * from './EventDossierTag'
export * from './GenerateConsultationError'
export * from './Notification'
export * from './Receipt'
export * from './Relationship'
export * from './RemoveConsultationError'

import { ORM } from 'redux-orm'
import Address from './Address'
import Calendar from './Calendar'
import Consultation from './Consultation'
import ConsultationPrice from './ConsultationPrice'
import ConsultationReminder from './ConsultationReminder'
import ConsultationType from './ConsultationType'
import Contact from './Contact'
import ContactSlice from './ContactSlice'
import Country from './Country'
import Dossier from './Dossier'
import DossierItem from './DossierItem'
import DossierSlice from './DossierSlice'
import EmailCommunication from './EmailCommunication'
import EmailTemplate from './EmailTemplate'
import Employment from './Employment'
import Event from './Event'
import EventConsultationTypeTag from './EventConsultationTypeTag'
import EventDossierTag from './EventDossierTag'
import GenerateConsultationError from './GenerateConsultationError'
import HealthFund from './HealthFund'
import Invoice from './Invoice'
import InvoiceConfiguration from "./InvoiceConfiguration"
import InvoiceItem from './InvoiceItem'
import Location from './Location'
import Notification from './Notification'
import Office from './Office'
import Organisation from './Organisation'
import Receipt from './Receipt'
import Relationship from "./Relationship";
import RemoveConsultationError from './RemoveConsultationError'
import SelectableCalendar from './SelectableCalendar'
import User from './User'

const orm = new ORM()
orm.register(Address, Calendar, Contact, ContactSlice, ConsultationType, ConsultationPrice, Country, Location, Dossier,
             DossierSlice, DossierItem, HealthFund, EmailCommunication, Employment, Office, Organisation, User, Invoice,
             InvoiceConfiguration, InvoiceItem, SelectableCalendar, Consultation, Event, ConsultationReminder,
             EmailTemplate, EventConsultationTypeTag, EventDossierTag, GenerateConsultationError, Notification,
             Receipt, Relationship, RemoveConsultationError)

export default orm;
