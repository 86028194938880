import { connect } from 'react-redux'
import { Card } from 'semantic-ui-react'

import { createEntityWithDefaults, fetchEntities } from 'actions/entities';
import EntitiesList from 'components/entities/EntitiesList';
import InvoicesListItem from 'components/invoices/InvoicesListItem'
import InvoiceForm from 'components/invoices/InvoiceForm'

import { getInvoicesForDossier } from 'selectors/invoices'


const mapStateToProps = (state, ownProps) => {
  return {
    entities: getInvoicesForDossier(state, ownProps),
    entityName: 'invoice',
    item: InvoicesListItem,
    form: InvoiceForm,
    listComponent: Card.Group,
    loading: state.meta.invoice.loading,
    error: state.meta.invoice.error
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchExtraEntities: () => {
      dispatch(fetchEntities('invoices', { name: `dossier`, filter: { dossierId: ownProps.dossierId }, page: 1 }))
    },
    fetchUpdatedEntities: () => {
      dispatch(fetchEntities('invoices', { name: `dossier`, filter: { dossierId: ownProps.dossierId }, page: 1 }))
    },
    createEntityWithDefaults: (props) => {
      return dispatch(createEntityWithDefaults('invoice', { dossierId: ownProps.dossierId} ))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesList);
