import React from 'react'
import SemanticDateWidget from 'components/forms/widgets/SemanticDateWidget'
import SemanticCheckboxWidget from 'components/forms/widgets/SemanticCheckboxWidget'
import { connect } from 'react-redux'

import { Form } from 'semantic-ui-react'


import dotProp from 'dot-prop'

class SemanticCheckboxDateWidget extends React.Component {
  render() {
    return (
      <Form.Group>
        <Form.Field width={4}>
          <SemanticCheckboxWidget {...this.props} checkedValue={new Date()}/>
        </Form.Field>
        <Form.Field width={4}>
          { this.props.date ?
            <SemanticDateWidget type={'date'} {...this.props}/> : null }
        </Form.Field>
      </Form.Group>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
   var entityModel = `${ownProps.model || ''}.${ownProps.id}`
   var date = dotProp.get(state,entityModel)
   return {
     date: date,
     entityModel: entityModel
   }
}


export default connect(mapStateToProps)(SemanticCheckboxDateWidget);
