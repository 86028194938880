import React from 'react'
import { Form } from 'semantic-ui-react'
import { connect } from 'react-redux'
import dotProp from 'dot-prop'

import PropTypes from "prop-types";

import {
  getWidget,
  getUiOptions,
  isSelect,
  optionsList,
  getDefaultRegistry,
} from "react-jsonschema-form/lib/utils";

class SemanticStringField extends React.Component {
  render() {
  const {
    schema,
    name,
    uiSchema,
    idSchema,
    formData,
    required,
    disabled,
    readonly,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    model,
    translationScope,
    registry = getDefaultRegistry(),
  } = this.props;
  const { title, format } = schema;
  const { widgets, formContext } = registry;
  const enumOptions = isSelect(schema) && optionsList(schema);
  const defaultWidget = format || (enumOptions ? "select" : "text");
  const { widget = defaultWidget, placeholder = "", ...options } = getUiOptions(
    uiSchema
  );

  const Widget = getWidget(schema, widget, widgets);

  if (readonly) {
    return (
      <div>{this.props.value}</div>
    )
  }  else {
    return (
      <Widget
        options={{ ...options, enumOptions }}
        schema={schema}
        id={idSchema && idSchema.$id}
        label={title === undefined ? name : title}
        value={formData}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        required={required}
        disabled={disabled}
        readonly={readonly}
        formContext={formContext}
        autofocus={autofocus}
        registry={registry}
        placeholder={placeholder}
        model={model}
        translationScope={translationScope}
      />
    );
  }
  }
}

SemanticStringField.defaultProps = {
  uiSchema: {},
  disabled: false,
  readonly: false,
  autofocus: false,
};

const mapStateToProps = (state, ownProps) => {
    var entityModel = `${ownProps.model || ''}.${ownProps.idSchema.$id}`

    var value = dotProp.get(state, entityModel)

    return {
      value: value || I18n.t('generic.unknown')
    }
}

export default connect(mapStateToProps)(SemanticStringField);
