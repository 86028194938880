import contactSchema from 'forms/contactSchema'
import dossierSliceSchema from 'forms/dossierSliceSchema'
import relationshipSchema from "./relationshipSchema";

const dossierSchema = function(state, props, showDossierNumber) {
  return {
    "ui:order": ["patientId", "dossierNumber", "extraRemark" , "*"],
    "ui:readonly": props.readonly,
    "patientId": {
      "ui:field": "NestedFormField",
      "ui:options": {
        "entityName": "contact",
        "titleKey": "components.dossier_form.patient"
      },
      "ui:schema": contactSchema(state, props, true)
    },
    "extraRemark":{
      "ui:widget": "TextWidget"
    },
    "dossierNumber":{
      "ui:widget": showDossierNumber? "TextWidget" : "hidden",
      "ui:options": {
        width: 4
      }
    },
    "dossierSlices": {
      "ui:field": "SliceField",
      "ui:options": {
        "entityName": "dossierSlice",
        "displayTitle": false
      },
      "items": {
        "ui:field": "NestedFormField",
        "ui:options": {
          "entityName": "dossierSlice",
          "displayTitle": false,
          "inline": true
        },
        "ui:schema": dossierSliceSchema(state,props, 'dossier')
      }
    },
    "isArchived": {
      "ui:widget": "hidden",
    },
    "relationships": {
      "ui:field": "ArrayField",
      "ui:options": {
        "entityName": "relationship",
        "displayTitle": false
      },
      "items": {
        "ui:field": "NestedFormField",
        "ui:options": {
          "entityName": "relationship",
          "displayTitle": false,
          "inline": true
        },
        "ui:schema": relationshipSchema(state,props, 'dossier')
      }
    }
  }
}

export default dossierSchema;
