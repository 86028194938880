import React from 'react'

import { Card } from 'semantic-ui-react'
import { DragSource } from 'react-dnd'

class NewEvent extends React.Component {
  render () {
    const { dossier, consultationType, employment, connectDragSource, calendar } = this.props

    var style = {
      backgroundColor: calendar && calendar.color,
      borderRadius: '0px',
      opacity: 0.8,
      border: '0px',
      display: 'block'
    };

    if(dossier && dossier.patient) {
      return (connectDragSource(
        <div style={{paddingTop: '10px'}}>
          <Card>
            <div className={'rbc-event'} style={style}>
              <div className="rbc-event-content">
                {`${consultationType.name} ${dossier.patient.firstName} ${dossier.patient.lastName}`}

              </div>
            </div>
          </Card>
        </div>))
    } else return null;
  }
}

const collect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

export default DragSource('event',{ beginDrag: (props) => { return  {...props,...{ new: true}}}}, collect)(NewEvent)
// export default NewEvent
