import { fk, attr } from 'redux-orm'
import Base from './Base'

class InvoiceItem extends Base {
  static get fields() {
    return {
      id: attr(),
      deleted: attr(),
      price: attr(),
      quantity: attr(),
      position: attr(),
      description: attr(),
      unit: attr(),
      invoiceId: fk('invoice', 'invoiceItems')
    }
  }
}

InvoiceItem.modelName = 'invoiceItem'

export default InvoiceItem;
