import React from 'react'
import { Dropdown, Menu, Item} from 'semantic-ui-react'
import { connect } from 'react-redux'

import { fetchEntities } from 'actions/entities';

import { Route, NavLink } from 'react-router-dom'
import { getOffices } from 'selectors/offices'

class OfficeSelector extends React.Component {
  componentDidMount() {
    this.props.fetchOffices();
  }

  render () {
    if (this.props.offices.length < 2) {
      return (

        <Route path="/:officeSlug/*" render={(props) =>
          <Menu.Item>
          {(this.props.offices.filter( (office) => office.slug === props.match.params.officeSlug )[0] || {}).name || I18n.t('components.office_selector.unselected')}
        </Menu.Item>
        }/>

    )
      
    } else {
      return (
        <Route path="/:officeSlug/*" render={(props) =>
          <Dropdown item text={(this.props.offices.filter( (office) => office.slug === props.match.params.officeSlug )[0] || {}).name || I18n.t('components.office_selector.unselected')} loading={this.props.loading}>
            <Dropdown.Menu>
              {this.props.offices.map( (office) => <Dropdown.Item as={'a'} href={`/${office.slug}/${props.match.params[0].replace(/\/\d*$/,'')}`} text={office.name} key={office.slug} active={office.slug === props.match.params.officeSlug}/>)}
            </Dropdown.Menu>
          </Dropdown>
        }/>
    )
      
    }


  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    offices: getOffices(state),
    loading: state.meta.office.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOffices: () => {
      dispatch(fetchEntities('offices'))
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(OfficeSelector);
