import Dossier from 'components/dossiers/Dossier';
import DossiersListContainer from 'components/dossiers/DossiersListContainer';
import DossiersMenuContainer from 'components/dossiers/DossiersMenuContainer';
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Segment, Sidebar } from 'semantic-ui-react'

export default class Dossiers extends React.Component {
  render () {
    return (
      <div>
        <Switch>
          <Route exact path={Routes.dossiers_path()} render={() => <DossiersListContainer apiType={'admin'}/>}/>
          <Route path='*/:id' render={ props => (
            <Sidebar.Pushable as={Segment} className= "min-height-90">

              <DossiersMenuContainer/>
              <Sidebar.Pusher className="left-sidebar-margin">
                <Dossier key={props.match.params.id} dossierId={props.match.params.id}/>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
           )} />

        </Switch>
      </div>
    )
  }
}

