import { clearSearch } from "actions/search_results";
import { setSearchArchives } from "actions/search_results";
import { fetchSearchResults } from 'actions/search_results'
import debounce from 'debounce'
import dotProp from "dot-prop";
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { getSearchResults } from 'selectors/search_results'
import { Menu, Search } from 'semantic-ui-react'
import ui from "../../redux-ui.js";

class SearchMenuItem extends React.Component {

  handleResultSelect = (e, { result }) => {
    if (result.url) {
      this.handleClose()
      this.props.history.push(result.url)
    } else if(result.archivebutton === "true"){
      this.props.setSearchArchives(true)
      this.props.search(this.props.value)
    }
  }

  handleSearchChange = (e, { value }) => {
    this.props.search(value, this.props.searchArchives)
    this.props.updateUI('open', value.length > 2)
  }

  handleClose = () => {
    this.props.updateUI('open', false)
    this.props.setSearchArchives(false)
    this.props.clearSearch()
  }

  render() {
    const { results, value, search} = this.props
    return (
      <Menu.Item>
        <Search
          category
          onResultSelect={this.handleResultSelect}
          onSearchChange={debounce(this.handleSearchChange, 500,  true)}
          results={results}
          minCharacters={3}
          placeholder={I18n.t('components.search_menu_item.placeholder')}
          search={search}
          value={value}
          open={this.props.ui.open}
          onBlur={this.handleClose}
          onFocus={this.handleSearchChange}
          noResultsMessage={I18n.t('components.search_menu_item.no_results')}
        />
      </Menu.Item>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    results: getSearchResults(state),
    value: dotProp.get(state.searchResults.value),
  }
}

const mapDispatchToProps = (dispatch) => {

  return {
    search: (text) => {
      dispatch(fetchSearchResults(text))
    },
    setSearchArchives: (searchArchives) => {
      dispatch(setSearchArchives(searchArchives))
    },
    clearSearch: () => {
      dispatch(clearSearch())
    }
  }
}

export default ui({state: { open: false}})(connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchMenuItem)));
