const actionTypes = {
  BLUR: 'FORM_BLUR',
  CHANGE: 'FORM_CHANGE',
  FOCUS: 'FORM_FOCUS',
  RESET: 'FORM_RESET',
  VALIDATE: 'FORM_VALIDATE',
  SET_DIRTY: 'FORM_SET_DIRTY',
  SET_ERRORS: 'FORM_SET_ERRORS',
  SET_INITIAL: 'FORM_SET_INITIAL',
  SET_PENDING: 'FORM_SET_PENDING',
  SET_PRISTINE: 'FORM_SET_PRISTINE',
  SET_SUBMITTED: 'FORM_SET_SUBMITTED',
  SET_SUBMIT_FAILED: 'FORM_SET_SUBMIT_FAILED',
  SET_TOUCHED: 'FORM_SET_TOUCHED',
  SET_UNTOUCHED: 'FORM_SET_UNTOUCHED',
  SET_VALIDITY: 'FORM_SET_VALIDITY',
  SET_VALIDATING: 'FORM_SET_VALIDATING',
  SET_FIELDS_VALIDITY: 'FORM_SET_FIELDS_VALIDITY',
  SET_VIEW_VALUE: 'FORM_SET_VIEW_VALUE',
  RESET_VALIDITY: 'FORM_RESET_VALIDITY',
  BATCH: 'FORM_BATCH',
  NULL: null,
  ADD_INTENT: 'FORM_ADD_INTENT',
  CLEAR_INTENTS: 'FORM_CLEAR_INTENTS',
};

export default actionTypes;
