import { bulkUpsert } from 'actions/entities';

import subscribeCalendarSync from 'channels/calendarSync';
import subscribeGenerateConsultationsRuns from 'channels/generateConsultations';
import subscribeTagEventsRuns from 'channels/tagEventsRun';
import { createLogic } from 'redux-logic';

const subscribeCalendarLogic = createLogic({
  type: bulkUpsert('calendar', {}).type,
  process({ getState, action }, dispatch, done) {
    if (action.payload) {
      Object.keys(action.payload).forEach( (calendarId) => {
        subscribeCalendarSync(calendarId)
        subscribeGenerateConsultationsRuns(calendarId)
        subscribeTagEventsRuns(calendarId)
      })
    }
    done();
  }
})

// TODO delete calendar unsubscribe

export default [subscribeCalendarLogic];
