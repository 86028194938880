import pluralize from 'pluralize'
import underscored from 'underscore.string/underscored'
import { Model } from 'redux-orm'
import uuid from 'uuid'
import isUuid from 'is_uuid'
import { attr } from 'redux-orm'

class Base extends Model {
  copy() {
    let { ref } = this
    let { id: id, ...attributes} = ref
    return this.getClass().create(attributes)
  }

  static reducer(action, Base, session) {
    switch(action.type) {
      case `BULK_UPSERT_${underscored(Base.modelName).toUpperCase()}`: {
        Object.values(action.payload).forEach((entity) => {
          Base.upsert(entity)
        })
        break;
      }
      case `BULK_CREATE_${underscored(Base.modelName).toUpperCase()}`: {
        Object.values(action.payload).forEach((entity) => {
          Base.create(entity)
        })
        break;
      }
      case `BULK_REPLACE_${underscored(Base.modelName).toUpperCase()}`: {
        action.payload.oldEntities.forEach((oldEntityId) => {
          Base.withId(oldEntityId).delete()
        })
        Object.values(action.payload.entities).forEach((entity) => {
          Base.create(entity)
        })
        break;
      }
      case `CREATE_${underscored(Base.modelName).toUpperCase()}`: {
        Base.create(action.payload)
        break;
      }
      case `COPY_${underscored(Base.modelName).toUpperCase()}`: {
        Base.withId(action.payload.id).copy(action.payload.newId)
        break;
      }
      case `UPDATE_${underscored(Base.modelName).toUpperCase()}`: {
        Base.withId(action.payload.id).update(action.payload)
        break;
      }
      case `DELETE_ALL_${underscored(Base.modelName).toUpperCase()}`: {
        Base.all().delete()
        break;
      }
      case `DELETE_ALL_NEW_${underscored(Base.modelName).toUpperCase()}`: {
        Base.all().filter((record) => { return isUuid(record.id) }).delete()
        break;
      }
      case `DELETE_${underscored(Base.modelName).toUpperCase()}`: {
        if (action.payload.soft) {
          Base.withId(action.payload.id).update({ deleted: "1" })
        } else {
          Base.withId(action.payload.id).delete()
        }
        break;
      }
    }
  }

  static nextId() {
    return uuid.v4();
  }

  static advanceNextId(id) {
    // uuids are not deterministic
    // no-op
  }
}

Base.options = {
  mapName: 'byId',
  arrName: 'allIds'
}

export default Base;
