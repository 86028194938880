import { fk, attr } from 'redux-orm'
import Base from './Base'

class Location extends Base {
  static get fields() {
    return {
      id: attr(),
      name: attr(),
      primaryColor: attr(),
      logoExpiringUrl: attr(),
      website: attr(),
      phone: attr(),
      email: attr(),
      addressId: fk({to: 'address', as: 'address', relatedName: 'location'}),
      responsibleId: fk({to: 'contact', as: 'responsible', relatedName: 'location'})
    }
  }
}

Location.modelName = 'location'

export default Location;
