import React from 'react'

import { Segment } from 'semantic-ui-react';
import Timeline from 'components/dossiers/dossierFollowUp/Timeline';

class TimelineNode extends React.Component {
  static defaultProps = {
    first: false,
    last: false
  };

  removeNode = () => {
    const {editor, node} = this.props
    editor.command('removeNodeByKey', node.key)
  }

  render() {
    const {attributes, icon, time, first, last, type, children} = this.props

    return (
      <div {...attributes}>
        <Timeline icon={icon} last={last} first={first} time={time}>
          <React.Fragment>
            <Segment basic style={{paddingTop: '1.5em', paddingLeft: '1.5em'}}
                     className={`editor-node ${type} editorial`}>
              {children}
            </Segment>
          </React.Fragment>
        </Timeline>
      </div>
    )
  }
}

export default TimelineNode
