import addressSchema from 'forms/addressSchema'

const organisationSchema = function(state, props) {

  const uiSchema = {
    "name" : {
      "ui:options": {
        width: 6
      }
    },
    "email" : {
      "ui:options": {
        width: 6
      }
    },
    "organisationType" : {
      "ui:widget": "select",
      "ui:options": {
        width: 4,
        selectOptions: ['bv', 'vzw', 'eenmanszaak', 'vof','nv', 'commnv', 'commva', 'cvba', 'cvoa'].map((key) => { return {key: key, value: key, text: I18n.t(`api.forms.organisation.organisation_types.${key}`) }})
      }
    },
    "enterpriseNumber": {
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:widget": "MaskedTextWidget",
      "ui:options" : {
        mask: [/\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]
      }
    },
    ...addressSchema(state,props),
  }

  return uiSchema
}

export default organisationSchema;

