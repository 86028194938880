import React from 'react'
import { connect } from 'react-redux'
import ui from 'redux-ui'

import { Button, Icon, Menu, Popup } from 'semantic-ui-react'

import { Modal } from 'semantic-ui-react-with-store'

import NewEntityModal from 'components/entities/NewEntityModal'
import EmailCommunicationForm from 'components/email_communications/EmailCommunicationForm'
import { createEntityWithDefaults } from "../../actions/entities";
import { getTemplate } from "../../selectors/email_templates";

class SendEmailItem extends  React.Component {
  SubmitButton = (props) => {
    return(<Modal.Actions>
      <Button color='red' inverted onClick={ (event, data) => {props.onCancel()}} > <Icon name='close' /> {I18n.t('components.entities_list.cancel')}
      </Button>
      <Button.Group color='green'>
        <Button color='green' onClick={ (event, data) => {props.submit()}} >
          <Icon name='send' /> {I18n.t('components.send_email_item.submit')}
        </Button>
      </Button.Group>
    </Modal.Actions>)
  }

  createNewEmailCommunication = () => {
    let {template} = this.props
    return this.props.newEmailCommunication(template)
  }

  render() {
    const { invoice } = this.props
    const dossier = invoice.dossier
    var message
    if (dossier) {
      if (invoice.proposed) {
        return <span></span>
      } else {
        message = invoice.sendInvoiceWarning || invoice.printInvoiceWarning
      }
    }

    if (message) {
      if (invoice.emailCommunications) {
        return <Popup trigger={<Menu.Item icon='mail outline' />} header={I18n.t(`components.pdf_item.error.${message}`)}/>
      }
      else {
        return <Popup trigger={<Menu.Item icon='send' />} header={I18n.t(`components.pdf_item.error.${message}`)}/>
      }
    } else {
      let Trigger = null
      if (invoice.emailCommunications) {

        Trigger = ((props) => <Modal trigger={<Menu.Item icon='mail outline' />} open={this.props.ui.isEmailPopupVisible} onOpen={() => this.props.updateUI('isEmailPopupVisible', true)} onClose={() => this.props.updateUI('isEmailPopupVisible', false)} on='click'>
            <Modal.Header>
              {I18n.t('components.invoice.confirm_mail_info')}
            </Modal.Header>
            <Modal.Actions>
             <Button color='green' onClick={() => { props.onClick(); this.props.updateUI('isEmailPopupVisible', false) }} content={I18n.t('components.invoice.confirm_mail')}/>
            </Modal.Actions>
          </Modal>
          )
      } else {
        Trigger = ((props) => <Menu.Item icon='send' {...props}/> )
      }
      return <NewEntityModal entityName='emailCommunication' form={EmailCommunicationForm} modalEntityId={this.props.ui.modalEntityId} trigger={Trigger} SubmitButton={this.SubmitButton} createEntityWithDefaults={this.createNewEmailCommunication}/>
    }
  }
}

const mapStateToProps = (state, ownProps) => {
    let type = ownProps.invoice.payedAt ? "invoice" : "invoice_unpaid"
  return {
    template: getTemplate(type)(state, ownProps)[0]
  };
}


const mapDispatchToProps = (dispatch, ownProps) => {
  const { invoice } =  ownProps

  let emailCommunicationType = invoice.payedAt ? "invoice" : "invoice_unpaid"
  return {
    newEmailCommunication: (template) => {
        const subject = template? template.subject : ""
        const body = template? template.body: ""
      return dispatch(createEntityWithDefaults('emailCommunication', { relationships: {},emailCommunicationType: emailCommunicationType, dossierId: invoice.dossier.id, invoiceId: invoice.id, subject: subject, body: body }))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ui({ state: { isEmailPopupVisible: false, modalEntityId: null } })(SendEmailItem));
