import React from 'react'
import { Button } from "semantic-ui-react";
import ReactToPrint from "react-to-print";

class PrintableComponent extends React.Component {

  render() {
    const {attributes, children} = this.props

    return (
      <div {...attributes}>
        <style>{getPageMargins()}</style>
        {children}
      </div>
    )
  }
}

export const getPrintButton = (printRef) => {
  return (
    <ReactToPrint trigger={() => {
      return <Button icon='print' className="dont-print"/>
    }} content={() => printRef}/>
  )
}

const getPageMargins = () => {
  return `@page { margin: 1cm !important; }`;
};

export default PrintableComponent