import React from 'react'
import { List, Image } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

class EmploymentsListItem extends React.Component {

  render () {
    const employment = this.props.employment

    return (
      <List.Item key={employment.id}>
        <Image src={employment.photoUrl} avatar/>
        <List.Content>
          <List.Header>{`${employment.user?.firstName} ${employment.user?.lastName}`}</List.Header>
          <List.Description>{I18n.t(`components.employments_list_item.employment_type.${employment.employmentType}`)}</List.Description>
        </List.Content>
      </List.Item>
    )
  }
}

export default EmploymentsListItem;
