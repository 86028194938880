import pluralize from 'pluralize'

import dotProp from 'dot-prop'
import { createSelector } from 'reselect'
import { createSelector as ormSelector } from 'redux-orm'

const getKeyWindowIds = (entityName, keyWindow) => {
  return (state) => {
    return dotProp.get(state, `meta.${entityName}.keyWindows.byWindow.${keyWindow}.allIds`)
  }
}

export const getKeyWindowSelector = (entityName,  keyWindow, getEntities) => {
  return  createSelector([getEntities, getKeyWindowIds(entityName, keyWindow)], (entities, ids) => {
    if (ids) {
      return entities.filter((entity) => {
        return (ids.indexOf(entity.id.toString()) > - 1)
      })
    } else {
      return []
    }
  })
}
