import _ from 'lodash'
import pathToRegexp from 'path-to-regexp'
import underscored from 'underscore.string/underscored'

export function entityRoute(entityName,api_type = 'standard', extraParams = {}) {
  const routeName = `${routePrefix(api_type, false)}${underscored(entityName)}_path`

  if(!Routes[routeName]) {
    throw `Route not found: ${routeName}`
  } else {
    return Routes[routeName]({...snakeCaseParams(extraParams), ...{_options: true}})
  }
}

export function entitiesRoute(entitiesName,api_type = 'standard', extraParams = {}) {
  const routeName = `${routePrefix(api_type, false)}${underscored(entitiesName)}_path`

  if(!Routes[routeName]) {
    throw `Route not found: ${routeName}`
  } else {
    return Routes[routeName]({...snakeCaseParams(extraParams), ...{_options: true}})
  }
}

export function entityApiRoute(entityName,api_type = 'standard', extraParams = {}) {
  const routeName = `${routePrefix(api_type, true)}${underscored(entityName)}_path`

  if(!Routes[routeName]) {
    throw `Route not found: ${routeName}`
  } else {
    return Routes[routeName]({...snakeCaseParams(extraParams), ...{_options: true}})
  }
}

export function newEntityApiRoute(entityName,api_type = 'standard', extraParams = {}) {
  const routeName = `${routePrefix(api_type, true)}new_${underscored(entityName)}_path`

  if(!Routes[routeName]) {
    throw `Route not found: ${routeName}`
  } else {
    return Routes[routeName]({...snakeCaseParams(extraParams), ...{_options: true}})
  }
}

export function entitiesApiRoute(entitiesName,api_type = 'standard', extraParams) {
  const routeName = `${routePrefix(api_type, true)}${underscored(entitiesName)}_path`

  if(!Routes[routeName]) {
    throw `Route not found: ${routeName}`
  } else {
    return Routes[routeName]({...snakeCaseParams(extraParams), ...{_options: true}})
  }
}

function snakeCaseParams(params) {
  return _.mapKeys(params, function(value, key) { return _.snakeCase(key) } )
}

function routePrefix(api_type, api) {
  if(api_type === 'superuser') {
    if(api) {
      return 'superuser_api_'
    } else {
      return 'superuser_'
    }
  } else if(api_type === 'admin'){
    if(api) {
      return 'admin_api_'
    } else {
      return 'admin'
    }
  } else {
    if (api) {
      return 'api_'
    } else {
      return ''
    }

  }
}

export function activeColor(url) {
  let routeMap =
    {
      "orange": [Routes.compass_path()],
      "green": [Routes.consultations_path(), Routes.consultation_path({id: ':id', _options: true})],
      "blue": [
        Routes.dossiers_path(),
        Routes.dossier_path({id: ':id', _options: true}),
        Routes.dossier_infos_path(),
        Routes.dossier_info_path({id: ':id', _options: true}),
        Routes.dossier_financials_path(),
        Routes.dossier_financial_path({id: ':id', _options: true})
      ],
      "yellow": [
        Routes.contacts_path(),
        Routes.contact_path({id: ':id', _options: true}),
        Routes.invoices_path(),
        Routes.invoice_path({id: ':id', _options: true}),
        Routes.organisations_path(),
        Routes.organisation_path({id: ':id', _options: true}),
        Routes.receipts_journal_path(),
      ],
      "black": ['*']
    }

  return mapRoute(url, routeMap)
}

function mapRoute(url, routeMap) {
  return Object.keys(routeMap).find((value) => {
    let routes = routeMap[value]
    return routes.some( (route) => { return  pathToRegexp(route).exec(url) } )
  })
}




