export const SET_PROGRESS= 'SET_PROGRESS';
export function setProgress(id, value, total, label, color) {
  return {
    type: SET_PROGRESS,
    payload: {
      id: id,
      progress: {
        value: value,
        total: total,
        label: label,
        color: color
      }
    }
  };
}

export const END_PROGRESS= 'END_PROGRESS';
export function endProgress(id) {
  return {
    type: END_PROGRESS,
    payload: id,
  };
}
