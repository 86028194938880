import { attr } from 'redux-orm'
import Base from './Base'

class Office extends Base {
  static get fields() {
    return {
      id: attr(),
      name: attr(),
      iban: attr(),
      phoneNumber: attr(),
      slug: attr(),
      invoiceConditions: attr(),
      invoiceMessage: attr(),
      enterpriseNumber: attr(),
      rizivNumber: attr(),
      startInvoiceIdentification: attr(),
      current: attr(),
      hiddenPreferences: attr()
    }
  }
}

Office.modelName = 'office'

export default Office;
