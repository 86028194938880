import React from 'react'
import SchemaForm from 'components/forms/SchemaForm'
import { connect } from 'react-redux'
import { getCurrentOfficeId } from 'selectors/offices'
import { fetchEntities } from 'actions/entities'

import consultationPriceBatchSchema from 'forms/consultationPriceBatchSchema'

class ConsultationPriceBatchForm extends React.Component {
  componentDidMount() {
    this.props.fetchEntities();
  }

  render () {
    if(this.props.officeId) {
      return (
        <SchemaForm batch='consultationPrice' entityName='office' resourceName='consultationPriceBatch' uiSchema={this.props.uiSchema} submitButton={true} entityId={this.props.officeId}/>
      )
    } else {
      return <div></div>
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    officeId: getCurrentOfficeId(state),
    uiSchema: consultationPriceBatchSchema(state, ownProps)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('consultationTypes'))
      dispatch(fetchEntities('employments'))
      dispatch(fetchEntities('locations'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationPriceBatchForm);
