const consultationSchema = function(state, props) {
  return {
    "practionerId": {
      "ui:widget": "hidden"
    },
    "receiptId": {
      "ui:widget": "hidden"
    },
    "calendarId": {
      "ui:widget": "hidden"
    },
    "noInvoice": {
      "ui:widget": "hidden"
    },
    "dossierId":{
      "ui:field": "SelectorField",
      "ui:options": {
        width: 6,
        "entityName": "dossier",
        "displayAttribute": (dossier) => `${dossier.patient && dossier.patient.firstName} ${dossier.patient && dossier.patient.lastName}`
      }
    },
    "consultationTypeId":{
      "ui:field": "SelectorField",
      "ui:options": {
        width: 6,
        "entityName": "consultationType",
        "displayAttribute": (type) => `${type.name}`
      }
    }
  }
}

export default consultationSchema;
