import orm from 'models'
import flash from 'reducers/flash'

import modelReducer from 'reducers/form/model-reducer'
import createMetaReducer from 'reducers/meta'
import resources from 'reducers/resources'
import settingsReducer from 'reducers/settings'
import uiReducer from 'reducers/ui'
import { combineReducers } from 'redux'
import { createReducer } from 'redux-orm'
import { persistReducer } from 'redux-persist'


export const createRootReducer = (history) => { return (state,action) => {
  if (action.type === 'CLEAR_STORE') {
    state = undefined
  }

  return combineReducers({
  // router: connectRouter(history),
  api_type: () => { return 'superuser' },
  flash: flash,

  entities: (state, action) => {
    const intermediateState = createReducer(orm) (state, action)
    const finalState = modelReducer(`entities`, {})(intermediateState, action)
    return finalState;
  },
  meta: createMetaReducer(orm),
  // FIXME scope superuser on superuser models
  settings:  persistReducer({key: 'settings', storage: localStorage}, settingsReducer),
  resources: resources, // TODO: make as entity
  ui: uiReducer
})(state,action)
}}
