import { REQUEST_RESOURCES, RECEIVE_RESOURCES, FETCH_RESOURCES_FAILURE } from 'actions/resources'

const initialState = {
  list: [],
  loading: false,
  error: null,
}

export default function resources(state = initialState, action) {
  switch (action.type) {
    case REQUEST_RESOURCES:// start fetching resources and set loading = true
      return { ...state,  ...{list: [], error: null, loading: true}};
    case RECEIVE_RESOURCES:// return list of resources and make loading = false
      return { ...state, ...{list: action.resources, error:null, loading: false}};
    case FETCH_RESOURCES_FAILURE:// return error and make loading = false
      var error = "request failed"  // action.error || {message: action.error.message};//2nd one is network or server down errors
      return { ...state,  ...{list: [], error: error, loading: false}};
  }
  return state
}
