import { attr, fk } from 'redux-orm'
import Base from './Base'

class EventConsultationTypeTag extends Base {
  static get fields() {
    return {
      id: attr(),
      consultationTypeId :fk({to: 'consultationType', as: 'consultationType', relatedName: 'eventConsultationTypeTag'}),
      eventId :fk({to: 'event', as: 'event', relatedName: 'eventConsultationTypeTags'}),
    }
  }
}

EventConsultationTypeTag.modelName = 'eventConsultationTypeTag'

export default EventConsultationTypeTag;
