const findTags = (regex, allowedTags) => (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();
  let matchArr, start;
  if(!regex.global) {
    throw('tagTriggerRegex should be a global regex');
  };
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    if (allowedTags.indexOf(matchArr[1]) > -1 ) {
      callback(start, start + matchArr[0].length);
    }
  }
};

export default findTags;
