import _ from 'lodash'
import orm from 'models'
import { createSelector as ormSelector } from "redux-orm/lib/redux";
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'
import getEntities from 'selectors/entities'

const createDeepEqualSelector = createSelectorCreator(defaultMemoize,
  _.isEqual
)

const getDossierId = (state, props) => props.dossierId

const getDossier = (state, props) => {
  if (getDossierId(state, props)) {
    return orm.session(getEntities(state, props)).dossier.withId(getDossierId(state, props))
  } else {
    return null
  }
}

const getConsultations = ormSelector(orm, getEntities, (session) => {
  return session.consultation.all().toModelArray().map(consultation => {
    const {ref} = consultation
    return {
      ...ref,
      dossier: session.dossier.withId(consultation.dossierId),
      consultationType: session.consultationType.withId(consultation.consultationTypeId),
      event: session.event.withId(consultation.eventId)
    }
  })
})

const getConsultationsForDossier = createSelector([getConsultations, getDossierId],
  (consultations, dossierId) => {
    return consultations.filter(consultation => consultation.dossier && consultation.dossier.id === dossierId)
  }
)

export const getDossierItemsForDossier = createSelector([getDossier], (dossier) => {
  if (!dossier) {
    return []
  }

  return dossier.dossierItems.toModelArray().map(item => {
    const {ref} = item
    return {
      ...ref,
      consultation: item.consultation
    }
  })
})

const getDossierSlicesForDossier = createSelector([getDossier], (dossier) => {
  if (!dossier) {
    return []
  }

  return dossier.dossierSlices.toRefArray()
})

export const getDossierItemsJson = createDeepEqualSelector([getDossier, getDossierSlicesForDossier, getDossierItemsForDossier, getConsultationsForDossier], (dossier, slices, items, consults) => {
  if (!dossier) {
    return "{}"
  }

  let dossierItem = {id: `dossier_${dossier.id}`, dossierItemType: 'dossier', dossierId: dossier.id}
  let unsortedItems = [];

  let itemsPerConsult = {};
  let plainItems = [];

  items.forEach((item) => {
      if (item.consultationId) {
        itemsPerConsult[item.consultationId] = item
      } else
        plainItems.push(item);
    }
  )

  consults.forEach((consult) => {
    let item = itemsPerConsult[consult.id];
    let startTime = consult.event ? consult.event.startTime : item && item.time
    unsortedItems.push({
      ...consult,
      id: `cons_${consult.id}`,
      consultationId: consult.id,
      dossierId: dossier.id,
      time: startTime,
      dossierItemType: 'consultation',
      text: item && item.text,
      itemId: item && item.id,
      creator: consult.practioner
    })
  })

  plainItems.forEach((item) => {
    unsortedItems.push({
      ...item,
      id: item.id,
      dossierId: dossier.id,
      time: item.time,
      creator: item.creator
    })
  })

  slices.forEach((slice) => {
    unsortedItems.push({
      id: `slice_${slice.id}`,
      dossierItemType: 'slice',
      sliceId: slice.id,
      dossierId: dossier.id,
      time: slice.startTime
    })
  })

  unsortedItems = _.orderBy(unsortedItems, 'time', 'desc');

  return [dossierItem, ...unsortedItems]
})
