import { createLogic } from 'redux-logic';
import subscribeGenerateInvoicesRuns from 'channels/generateInvoices';
import {  matchPath } from 'react-router-dom'

const subscribeInvoiceGenerationLogic = createLogic({
  type: '@@router/LOCATION_CHANGE',
  process({ getState, action }, dispatch, done) {
    const initialMatch = matchPath(getState().router.location.pathname, { path: '/:officeSlug/*' })

    if (initialMatch) {
      var currentOfficeSlug = initialMatch.params.officeSlug;

      subscribeGenerateInvoicesRuns(currentOfficeSlug);
    }

    done();
  }
})

export default [subscribeInvoiceGenerationLogic];
