import React from 'react'

import { connect } from 'react-redux'
import { actions as formActions } from 'react-redux-form'
import get from 'react-redux-form/utils/get'
import MaskedInput from "react-text-mask";
import { Button, Input } from "semantic-ui-react";


class SemanticMaskedTextWidget extends React.Component {
  render() {
    const copyButton = this.props.options.copyButton
    const action = copyButton ? {icon: 'copy'} : undefined
    return (
      <Input action={action}>
        <MaskedInput
          mask={this.props.options.mask}
          pipe={this.props.options.pipe}
          onChange={this.props.handleChange}
          value={this.props.value}
        />
        {copyButton ? <Button type='button' icon="copy" onClick={this.copyText}></Button> : null}
      </Input>
  )
  }

  copyText = (event) => {
    event.preventDefault();
    let value = this.props.value;
    this.props.options.charsNotToCopy.forEach(char => value = value.split(char).join(''))
    navigator.clipboard.writeText(value);
  }
}

const mapStateToProps = (state, ownProps) => {
  let fullModel = `${ownProps.model}.${ownProps.id}`

  return {
    value: get(state, fullModel),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  let fullModel = `${ownProps.model}.${ownProps.id}`

  return {
    handleChange: (event) => {
      dispatch(formActions.change(fullModel, event.target.value))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SemanticMaskedTextWidget);
