import React from 'react'
import SchemaForm from 'components/forms/SchemaForm'
import { connect } from 'react-redux'
import { fetchEntities } from 'actions/entities'

import invoiceSchema from 'forms/invoiceSchema'

class InvoiceForm extends React.Component {
  componentDidMount() {
    this.props.fetchDossiers()
  }

  render () {
    return (
      <SchemaForm entityName='invoice' {...this.props}/>
      )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    uiSchema: invoiceSchema(state, ownProps)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDossiers: () => {
      dispatch(fetchEntities('dossiers'))
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(InvoiceForm);
