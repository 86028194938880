import React from 'react'
import { Card, Menu, Icon } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import MarkPayedItem from 'components/invoices/MarkPayedItem'
import SendEmailItem from 'components/invoices/SendEmailItem'
import PdfItem from 'components/invoices/PdfItem'

class InvoicesListItem extends React.Component {

  render () {
    const invoice = this.props.invoice

    return (
      <Card key={invoice.id} color={invoice.proposed ? "orange" : null } link className={invoice.proposed ? "opaque" : null}>
        <Card.Content as={NavLink} to={Routes.invoice_path({id: invoice.id, _options: true})}>
          <Card.Header>
            {`${invoice.formattedIdentification}: ${invoice.patient?.firstName} ${invoice.patient?.lastName}`}
          </Card.Header>
          <Card.Meta>
            { invoice.invoiceDate ? I18n.t('components.invoices_list_item.invoice_date', { date: I18n.l("date.formats.default",invoice.invoiceDate) })  : null }
          </Card.Meta>
          <Card.Meta>
            { invoice.proposed ?  I18n.t('components.invoices_list_item.proposed') : null }
          </Card.Meta>
          <Card.Meta>
            { invoice.paid ?  I18n.t('components.invoices_list_item.paid') : null }
          </Card.Meta>
          {
            (invoice.emailCommunications || []).map ( (communication) =>
              {
                if ( communication.createdAt) {
                  return (<Card.Meta>{I18n.t('components.invoices_list_item.sent_at', { date: I18n.l("date.formats.default",communication.createdAt)})}</Card.Meta>)
                } else {
                  return (<Card.Meta>{I18n.t('components.invoices_list_item.sent')}</Card.Meta>)
                }
              }
            )
          }
        </Card.Content>
        <Card.Content extra>
          <Menu compact icon borderless secondary size='large'>
            <PdfItem invoice={invoice}/>
            <SendEmailItem invoice={invoice}/>
            <MarkPayedItem invoice={invoice}/>
          </Menu>
        </Card.Content>
    </Card>)
  }
}

export default InvoicesListItem;
