import React from 'react'
import { connect } from 'react-redux'
import { Card } from 'semantic-ui-react'

import EntitiesList from 'components/entities/EntitiesList';
import LocationsListItem from 'components/locations/LocationsListItem'
import LocationForm from 'components/locations/LocationForm'

import { getLocations } from 'selectors/locations'

const mapStateToProps = (state, ownProps) => {
  return {
    entities: getLocations(state),
    entityName: 'location',
    item: LocationsListItem,
    form: LocationForm,
    loading: state.meta?.location?.loading,
    error: state.meta?.location?.error
  };
}

export default connect(mapStateToProps)(EntitiesList)
