const dossierItemSchema = function (state, props, dossierItemType) {

  const uiSchema = {
    "ui:readonly": props.readonly,
    "text": {
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:widget":"RichTextWidget",
      "ui:options": {
        "label": false
      },
    },
    "dossierItemType": {
      "ui:widget": "hidden"
    },
    "consultationId": {
      "ui:widget": "hidden"
    },
    "dossierId": {
      "ui:widget": "hidden"
    },
    "attachment": {
      "ui:widget": "hidden"
    },
    "time" : {
      "ui:visible": dossierItemType === 'note',
      "ui:group": "line",
      "ui:options": {
        "ui:width": 2
      }
    },
  }


  return uiSchema
}

export default dossierItemSchema;
