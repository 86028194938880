import pluralize from 'pluralize'
import { createSelector as ormSelector } from 'redux-orm'
import orm from  'models'
import isUuid from 'is_uuid'

const getEntities = state => state.entities

export const getEntitiesByIds = (entityName, ids) => {
  return ormSelector(orm, getEntities, (session) => {
    return session[entityName].filter((entity) => {   return (ids.indexOf(entity.id.toString()) > -1) } ).toModelArray().map(entity => {
      const { ref } = entity
      return { ...ref}
    })
  })
}

export const getPersistedEntities = (entityName) => {
  return ormSelector(orm, getEntities, (session) => {
    return session[entityName].filter((entity) => {   return entityPersisted(entity) } ).toModelArray()
  })
}

export function findEntity(state, entityName, id) {
  let session = orm.session(state.entities)
  return session[entityName].withId(id)
}

function entityPersisted(entity) {
  if (!entity.id) {
    return false
  }

  if(isUuid(entity.id)) {
    return false
  }

  return true
}

export default getEntities
