const emailTemplateBatchSchema = function(state, props) {
  const emailTypesSchema = {
    "ui:emptyValue": "",
    "ui:placeholder": "",
    "ui:widget": "select",
    "ui:group": 'name',
    "ui:options": {
      width: 4,
      selectOptions: ['invoice', 'invoice_unpaid'].map( (type) => {
        return { key: type, value: type, text: I18n.t(`components.email_template_batch_form.email_type.${type}`)  }
      })
    }
  }

  const uiSchema = {
    "emailTemplates": {
      "ui:options": {
        "entityName": "emailTemplate",
        "displayTitle": false
      },
      "items" : {
        "ui:field": "NestedFormField",
        "ui:options": {
          "entityName": "emailTemplate",
          "displayTitle": false,
          "inline": true
        },
        "ui:schema": {
          "subject": {
            "ui:autofocus": true,
            "ui:emptyValue": "",
            "ui:placeholder": "",
            "ui:options": {
              width: 6
            }
          },
          "body": {
            "ui:widget": "DraftWidget",
            "ui:emptyValue": "",
            "ui:placeholder": "",
            "ui:options": {
              width:12
            },
          },
          "emailType":  {...emailTypesSchema },
          "officeId": {
            "ui:widget" : "hidden"
          }
        }
      }
    }
  }

  return uiSchema
}

export default emailTemplateBatchSchema;

