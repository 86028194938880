import Flash from 'components/generics/Flash'
import ConsultationTypes from 'components/superuser/consultation_types/ConsultationTypes'
import HealthFunds from 'components/superuser/health_funds/HealthFunds'
import Offices from 'components/superuser/offices/Offices'
import history from 'custom_history'
import React from 'react'
import { Provider } from 'react-redux'
import { NavLink, Route, Router, Switch } from 'react-router-dom'
import { Icon, Menu, Sidebar } from 'semantic-ui-react'
import store from 'store'


import ErrorPage from './generics/ErrorPage'

export default class SuperuserApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  render () {
    if (this.state.error) {
      return (<ErrorPage redirectUrl={Routes.superuser_root_path()} error={this.state.error}/>)
    } else {
      return(<Provider store={store}>
        <Router history={history}>
          <div className="page-layout">
            <Menu flex="top" color='teal' inverted>
              <Menu.Item>
                Zorgmee: Superuser
              </Menu.Item>
              <Menu.Menu position='right'>
                <Menu.Item as={ NavLink } to={Routes.destroy_user_session_path()} data-method="delete">
                  <Icon name="sign out"/>
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            <div className="main">
              <Flash/>
              <Switch>
                <Route path={Routes.superuser_offices_path()} component={Offices}/>
                <Route path={Routes.superuser_consultation_types_path()} component={ConsultationTypes}/>
                <Route path={Routes.superuser_health_funds_path()} component={HealthFunds}/>
              </Switch>
            </div>
            <Sidebar as={Menu} visible={true} direction="bottom" animation="push" inverted={true} widths={5} icon='labeled' size="massive" color='teal'>
              <Menu.Item as={ NavLink } to={Routes.superuser_offices_path()}>
                <Icon name='building outline'/>
                {I18n.t('superuser.components.superuser_app.menu.offices')}
              </Menu.Item>
              <Menu.Item as={ NavLink } to={Routes.superuser_users_path()}>
                <Icon name='users'/>
                {I18n.t('superuser.components.superuser_app.menu.users')}
              </Menu.Item>
              <Menu.Item as={ NavLink } to={Routes.superuser_consultation_types_path()}>
                <Icon name='clock'/>
                {I18n.t('superuser.components.superuser_app.menu.consultation_types')}
              </Menu.Item>
              <Menu.Item as={ NavLink } to={Routes.superuser_health_funds_path()}>
                <Icon name='hospital'/>
                {I18n.t('superuser.components.superuser_app.menu.health_funds')}
              </Menu.Item>
            </Sidebar>
          </div>
        </Router>
      </Provider>
      )
    }
  }
}
