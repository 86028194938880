import AppChannels from 'cable.js'
import store from 'store.js'
import moment from 'moment'
import { refreshEntities } from 'actions/entities'
import { setProgress, endProgress } from 'actions/progress'
import { setChannelConnected, setChannelDisconnected} from 'actions/channels'


export default function subscribeGenerateInvoicesRuns(officeSlug) {
    AppChannels.generateInvoicesRuns = AppChannels.generateInvoicesRuns || {};

    if(!AppChannels.generateInvoicesRuns[officeSlug]) {
      AppChannels.generateInvoicesRuns[officeSlug] = AppChannels.cable.subscriptions.create({ channel: "GenerateInvoicesChannel", slug: officeSlug }, {
        connected: function() {
          store.dispatch(setChannelConnected('generate_invoices'))
        },

        disconnected: function() {
          store.dispatch(setChannelDisconnected('generate_invoices'))
        },

        received: function(data) {
          switch (data.status) {
            case 'generate_started':
            break;
            case 'generate_running':
              store.dispatch(setProgress(`generate_invoices_run_${data.service_id}`, data.events_checked, data.events_total, I18n.t(`channels.generate_invoices.statuses.${data.status}`), data.color));
            break;
            case 'generating':
              store.dispatch(setProgress(`generate_invoices_run_${data.service_id}`, data.events_checked, data.events_total, I18n.t(`channels.generate_invoices.statuses.${data.status}`), data.color));
            break;
            case 'ok':
              store.dispatch(setProgress(`generate_invoices_run_${data.service_id}`, data.events_checked, data.events_total, I18n.t(`channels.generate_invoices.statuses.${data.status}`), data.color));
              store.dispatch(endProgress(`generate_invoices_run_${data.service_id}`));
              store.dispatch(refreshEntities('invoices', { name: 'list', filter: {}, page: 1, append: true }))
              // Called when there's incoming data on the websocket for this channel
            break;
            case 'failed':
              store.dispatch(refreshEntities('invoices', { name: 'list', filter: {}, page: 1, append: true }))
              // Called when there's incoming data on the websocket for this channel
            break;
          }
        }
      });
    }
}
