import AppChannels from 'cable.js'
import store from 'store.js'
import { refreshEntities } from 'actions/entities'
import { endProgress, setProgress } from 'actions/progress'
import { setChannelConnected, setChannelDisconnected } from 'actions/channels'
import { refreshConsultationsInCurrentView } from "../actions/consultations";


export default function subscribeGenerateConsultationsRuns(calendarId) {
    AppChannels.generateConsultationsRuns = AppChannels.generateConsultationsRuns || {};

    if(!AppChannels.generateConsultationsRuns[calendarId]) {
      AppChannels.generateConsultationsRuns[calendarId] = AppChannels.cable.subscriptions.create({ channel: "GenerateConsultationsChannel", id: calendarId }, {
        connected: function() {
          store.dispatch(setChannelConnected('generate_consultations'))
        },

        disconnected: function() {
          store.dispatch(setChannelDisconnected('generate_consultations'))
        },

        received: function(data) {
          switch (data.status) {
            case 'generate_started':
              break;
            case 'generate_running':
              store.dispatch(setProgress(`generate_consultations_run_${data.service_id}`, data.events_checked, data.events_total, I18n.t(`channels.generate_consultations.statuses.${data.status}`), data.color));
              break;
            case 'generating':
              store.dispatch(setProgress(`generate_consultations_run_${data.service_id}`, data.events_checked, data.events_total, I18n.t(`channels.generate_consultations.statuses.${data.status}`), data.color));
              break;
            case 'ok':
              store.dispatch(setProgress(`generate_consultations_run_${data.service_id}`, data.events_checked, data.events_total, I18n.t(`channels.generate_consultations.statuses.${data.status}`), data.color));
              store.dispatch(endProgress(`generate_consultations_run_${data.service_id}`));
              store.dispatch(refreshConsultationsInCurrentView())
              store.dispatch(refreshEntities('notifications',{ name: 'menuItem', filter: {}, page: 1}, ['eventDossierTag', 'generateConsultationError', 'eventConsultationTypeTag'] ));
              // Called when there's incoming data on the websocket for this channel
            case 'failed':
              store.dispatch(endProgress(`generate_consultations_run_${data.service_id}`));
              store.dispatch(refreshConsultationsInCurrentView())
              store.dispatch(refreshEntities('notifications',{ name: 'menuItem', filter: {}, page: 1}, ['eventDossierTag', 'generateConsultationError', 'eventConsultationTypeTag'] ));

              // Called when there's incoming data on the websocket for this channel
              break;
          }


        }
      });
    }
}
