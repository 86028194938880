import React from 'react'
import { connect } from 'react-redux'
import ui from 'redux-ui'
import { Button, Icon } from 'semantic-ui-react'
import { createEntityWithDefaults, fetchEntities, updateEntity, upsertEntity } from "../../actions/entities";
import { fetchResources } from "../../actions/resources";
import { getFirstCalendarId } from "../../selectors/calendars/index.js";
import { getFirstLocationId } from "../../selectors/locations";
import { getCurrentOfficeId } from "../../selectors/offices";
import CalendarForm from "../calendars/CalendarForm.jsx";
import SchemaForm from "../forms/SchemaForm";
import setupLocationSchema from "./setupLocationSchema";
import setupOfficeSchema from "./setupOfficeSchema";
import SetupWizardStep from "./SetupWizardStep.jsx";

class SetupWizard extends React.Component {

  componentDidMount() {
    this.props.fetchEntities();
  }


  render() {
    let { officeId, locationId, officeSchema, locationSchema, calendarId} = this.props

    let SubmitButton = () => ''

    return ([
      <SetupWizardStep
        key='stepOne' open={this.props.ui.step === 1}
        text = {I18n.t('components.setup_wizard.welcome') }
        text2 = { I18n.t('components.setup_wizard.office_settings') }
        content = { <SchemaForm entityName="office" uiSchema={officeSchema} SubmitButton={SubmitButton} entityId={officeId}/>}
        nextButton =  {this.nextButton((onSubmitSuccess) => this.props.handleSubmit('office', officeId, onSubmitSuccess))}
      />,
        <SetupWizardStep
          key='stepTwo' open={this.props.ui.step === 2}
          text = {I18n.t('components.setup_wizard.location_settings')}
          content = {<SchemaForm entityName="location" uiSchema={locationSchema} SubmitButton={SubmitButton} entityId={locationId}/>}
          nextButton =  {this.nextButton((onSubmitSuccess) => this.props.handleSubmit('location', locationId, onSubmitSuccess, ['address']))}
        />,
        <SetupWizardStep
          key='stepThree' open={this.props.ui.step === 3}
          text = {I18n.t('components.setup_wizard.calendar_settings')}
          content = {<CalendarForm SubmitButton={SubmitButton} entityId={calendarId}/>}
          nextButton = {this.nextButton((onSubmitSuccess) => {this.props.handleSubmit('calendar', calendarId, onSubmitSuccess);})}
        />
      ])
  }

  nextButton(submit) {
    let nextStep = this.getNextStep(this.props.ui.step)
    return (<Button color="green" inverted onClick={() => {submit(() => this.goToStep(nextStep)) ;}}>
        <Icon name="checkmark"/>
        {nextStep > 3 ? I18n.t('components.setup_wizard.done') : I18n.t('components.setup_wizard.next')}
      </Button>
    )
  }

  getNextStep(currentStep) {
    let nextStep = currentStep + 1
    if(this.showStep(nextStep)) {
      return nextStep
    } else {
      return this.getNextStep(nextStep)
    }
  }

  showStep(step) {
    switch(step) {
      case 1:
        return true
      case 2:
        return !this.props.locationId
      case 3:
        return !this.props.calendarId
      default:
        return true
    }
  }

  goToStep(step) {
    switch(step) {
      case 1:
        break
      case 2:
        this.props.createLocation()
        break
      case 3:
        this.props.createCalendar()
        break
      default:
        this.props.handleFinish(this.props.user.id)
    }
    this.props.updateUI('step', step)
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    officeId: getCurrentOfficeId(state),
    officeSchema: setupOfficeSchema(state, ownProps),
    locationSchema: setupLocationSchema(state, ownProps),
    locationId: getFirstLocationId(state),
    calendarId: getFirstCalendarId(state),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleSubmit: (entityName, entityId, onSubmitSucces, upsertedRelationships = []) => {
      dispatch(upsertEntity(entityName, entityId, upsertedRelationships)).then(onSubmitSucces)
    }, fetchEntities: () => {
      dispatch(fetchResources())
      dispatch(fetchEntities('countries'))
      dispatch(fetchEntities('employments'))
      dispatch(fetchEntities('locations'))
      dispatch(fetchEntities('calendars'))
    },
    createLocation: () => {
      let addressId = dispatch(createEntityWithDefaults('address'))
      dispatch(createEntityWithDefaults('location', { addressId: addressId }))
    },
    createCalendar: () => {
      dispatch(createEntityWithDefaults('calendar'))
    },
    handleFinish: (userId) => {
      dispatch(updateEntity('user', { id: userId, setupDone: true, onboardingsDone: ['setup'] }))
      dispatch(upsertEntity('user', userId))
    }
  }
}

export default ui({
                    key: 'setupWizard',
                    state: { step: 1 }
                  })(connect(mapStateToProps, mapDispatchToProps)(SetupWizard));
