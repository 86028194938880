import React from 'react'

import ScrollContext from 'contexts/ScrollContext';

import { Visibility } from 'semantic-ui-react';

export default class InfiniteScroll extends React.Component {

  render() {
    const { onBottomVisible } = this.props
    return(<ScrollContext.Consumer>
      { scrollContext => {
          return (
            <Visibility fireOnMount={true} onBottomVisible={onBottomVisible} context={scrollContext.current} continuous={true}>
              {this.props.children}
            </Visibility>
          )
        }
      }
    </ScrollContext.Consumer>)
  }
}
