import NewEntityModal from 'components/entities/NewEntityModal';
import InfiniteScroll from 'components/generics/InfiniteScroll';

import pluralize from 'pluralize'
import React from 'react'
import { Link } from 'react-router-dom'
import { entitiesRoute, entityRoute } from 'route_helpers'
import { Sidebar } from "semantic-ui-react";
import { DimmerDimmable } from "semantic-ui-react";
import { Button, Menu } from 'semantic-ui-react'
import underscored from 'underscore.string/underscored'

export default class EntitiesMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {blurred: false}
  }

  componentDidMount () {
    this.props.fetchEntities();
  }

  toggleBlur = () => this.setState({ blurred: !this.state.blurred })

  renderEntities(entities) {
    if (!this.props.entityName || !(this.props.entityName.length > 0) || !entities.length) {
      return (<Menu.Item> {I18n.t('components.entities_menu.not_found')}</Menu.Item>)
    } else {
      var EntityMenuItem = this.props.item
      var entitiesName = pluralize(this.props.entityName)
      return (entities.map((entity) => {
        var entityProps = {}
        entityProps[this.props.entityName] = entity
        return (<Menu.Item as={Link} key={entity.id} to={entityRoute(this.props.entityName, this.props.apiType, {id: entity.id})}>
          <EntityMenuItem {...entityProps} />
        </Menu.Item>)
      }))
    }
  }

  renderList() {
    const { entities, onLoadMore, activePage, totalPages } = this.props

    if (onLoadMore) {
      return (<InfiniteScroll onBottomVisible={ () => onLoadMore(activePage, totalPages) }>
        <Menu.Menu>{this.renderEntities(entities)}</Menu.Menu>
      </InfiniteScroll>)
    } else {
      return (<Menu.Menu>{this.renderEntities(entities)}</Menu.Menu>)
    }
  }

  render() {
    var entitiesName = pluralize(this.props.entityName)
    return (
        <Sidebar as={Menu} vertical visible={true}>
          <Menu.Item>
            <Menu.Header as={Link} to={entitiesRoute(pluralize(this.props.entityName), this.props.apiType)}>
              { I18n.t(`components.${underscored(entitiesName)}_list.title`)}

            </Menu.Header>
            { this.props.form ?
            <NewEntityModal entityName={this.props.entityName} createEntityWithDefaults={this.props.createEntityWithDefaults} form={this.props.form} trigger={ (props) => {
              return (<Button basic floated='right' icon='plus' onClick={props.onClick} disabled={this.props.readonly}/>)} }
            /> : null }
            <Button basic icon={this.state.blurred? 'eye slash' : 'eye'} onClick={this.toggleBlur} style={{boxShadow:'none'}}/>
          </Menu.Item>
          {/* { this.props.filterEntities ? */}
          {/*   <Menu.Item> */}
          {/*     <Menu.Menu> */}
          {/*       <Menu.Item> <Input icon='search' placeholder={I18n.t('components.entities_list.search')} onChange={(event) => this.props.filterEntities(event.target.value)}/> </Menu.Item> */}
          {/*     </Menu.Menu> */}
          {/*   </Menu.Item> */}
          {/*   : null */}
          {/* } */}
          <Menu.Item>
            <DimmerDimmable blurring dimmed={this.state.blurred}>
              { this.renderList() }
            </DimmerDimmable>
          </Menu.Item>
        </Sidebar>
      )
  }
}


