import { fk, attr } from 'redux-orm'
import Base from './Base'

class RemoveConsultationError extends Base {
  static get fields() {
    return {
      id: attr(),
      errorType: attr(),
      resolutionType: attr(),
      eventId :fk({to: 'event', as: 'event', relatedName: 'removeConsultationError'}),
    }
  }
}

RemoveConsultationError.modelName = 'removeConsultationError'

export default RemoveConsultationError;
