import tagSuggestions from './TagSuggestions'; // eslint-disable-line import/no-named-as-default
// import TagSuggestionsPortal from './TagSuggestionsPortal';
import defaultRegExp from './defaultRegExp';
import tagStrategy from './tagStrategy';
import Tag from './Tag'
// import tagSuggestionsStrategy from './tagSuggestionsStrategy';
//
export { default as TagSuggestions } from './TagSuggestions';

import { Map } from 'immutable';

export default (config = {}) => {
  const callbacks = {
    keyBindingFn: undefined,
    handleKeyCommand: undefined,
    onDownArrow: undefined,
    onUpArrow: undefined,
    onTab: undefined,
    onEscape: undefined,
    handleReturn: undefined,
    onChange: undefined,
  };

  const ariaProps = {
    ariaHasPopup: 'false',
    ariaExpanded: 'false',
    ariaOwneeID: undefined,
    ariaActiveDescendantID: undefined,
  };

  let searches = Map();
  let escapedSearch;
  let clientRectFunctions = Map();
  let isOpened;

  const store = {
    getEditorState: undefined,
    setEditorState: undefined,
    getPortalClientRect: (offsetKey) => clientRectFunctions.get(offsetKey)(),
    getAllSearches: () => searches,
    isEscaped: (offsetKey) => escapedSearch === offsetKey,
    escapeSearch: (offsetKey) => {
      escapedSearch = offsetKey;
    },

    resetEscapedSearch: () => {
      escapedSearch = undefined;
    },

    register: (offsetKey) => {
      searches = searches.set(offsetKey, offsetKey);
    },

    updatePortalClientRect: (offsetKey, func) => {
      clientRectFunctions = clientRectFunctions.set(offsetKey, func);
    },

    unregister: (offsetKey) => {
      searches = searches.delete(offsetKey);
      clientRectFunctions = clientRectFunctions.delete(offsetKey);
    },

    getIsOpened: () => isOpened,
    setIsOpened: (nextIsOpened) => { isOpened = nextIsOpened; },
  };

  // Styles are overwritten instead of merged as merging causes a lot of confusion.
  //
  // Why? Because when merging a developer needs to know all of the underlying
  // styles which needs a deep dive into the code. Merging also makes it prone to
  // errors when upgrading as basically every styling change would become a major
  // breaking change. 1px of an increased padding can break a whole layout.
  const {
    tagPrefix = '',
    theme,
    tagComponent,
    tagSuggestionsComponent = tagSuggestions,
    entityMutability = 'SEGMENTED',
    tagTriggerRegex = new RegExp('{{(.*?)}}','g'),
    tagRegExp = defaultRegExp,
    allowedTags = [],
  } = config;
  // const tagSearchProps = {
  //   ariaProps,
  //   callbacks,
  //   theme,
  //   store,
  //   entityMutability,
  //   positionSuggestions,
  //   tagTriggerRegex,
  //   tagPrefix,
  // };
  return {
    // TagSuggestions: decorateComponentWithProps(tagSuggestionsComponent, tagSearchProps),
    //
    decorators: [
      {
        strategy: tagStrategy(tagTriggerRegex, allowedTags),
        component: Tag,
      },
      // {
      //   strategy: tagSuggestionsStrategy(tagTrigger, tagRegExp),
      //   component: decorateComponentWithProps(TagSuggestionsPortal, { store }),
      // },
    ],
    getAccessibilityProps: () => (
      {
        role: 'combobox',
        ariaAutoComplete: 'list',
        ariaHasPopup: ariaProps.ariaHasPopup,
        ariaExpanded: ariaProps.ariaExpanded,
        ariaActiveDescendantID: ariaProps.ariaActiveDescendantID,
        ariaOwneeID: ariaProps.ariaOwneeID,
      }
    ),

    initialize: ({ getEditorState, setEditorState }) => {
      store.getEditorState = getEditorState;
      store.setEditorState = setEditorState;
    },

    onDownArrow: (keyboardEvent) => callbacks.onDownArrow && callbacks.onDownArrow(keyboardEvent),
    onTab: (keyboardEvent) => callbacks.onTab && callbacks.onTab(keyboardEvent),
    onUpArrow: (keyboardEvent) => callbacks.onUpArrow && callbacks.onUpArrow(keyboardEvent),
    onEscape: (keyboardEvent) => callbacks.onEscape && callbacks.onEscape(keyboardEvent),
    handleReturn: (keyboardEvent) => callbacks.handleReturn && callbacks.handleReturn(keyboardEvent),
    onChange: (editorState) => {
      if (callbacks.onChange) return callbacks.onChange(editorState);
      return editorState;
    },
  };
};
