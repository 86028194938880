import React from 'react'
import { Sidebar, Segment, Menu, Icon } from 'semantic-ui-react'
import { BrowserRouter , Switch, Route } from 'react-router-dom'
import ConsultationPriceBatchForm from 'components/consultation_prices/ConsultationPriceBatchForm';

import { fetchEntities } from 'actions/entities';

import { connect } from 'react-redux'

class ConsultationPrices extends React.Component {
  componentDidMount() {
    this.props.fetchEntities()
  }

  render () {
    return (
      <ConsultationPriceBatchForm/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.meta.consultationPrice.loading,
    error: state.meta.consultationPrice.error
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('consultationPrices'))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ConsultationPrices)
