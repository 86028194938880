import { createSelector as ormSelector } from 'redux-orm'
import orm from 'models'
import getEntities from 'selectors/entities'

export const getEmailTemplates = ormSelector(orm, getEntities, (session) => {
  return session.emailTemplate.all().toModelArray().map(emailTemplate => {
    const { ref } = emailTemplate
    return { ...ref}
  })
})

export const getTemplate = (type) => {
  return ormSelector(orm, getEntities, (session) => {
    return session.emailTemplate.all().filter((template) => { return (template.emailType === type) } ).toModelArray().map(template => {
      const { ref } = template
      return { ...ref}
    })
  })
}