import React from 'react'
import { Segment, Header, Menu, Grid, Divider, } from 'semantic-ui-react'

import { connect } from 'react-redux'
import { findEntity } from 'selectors/entities'

import OrganisationForm from 'components/organisations/OrganisationForm'

class Organisation extends React.Component {
  render() {
    const { organisation } = this.props

    if (!organisation) {
      return <div/>
    }

    return (
      <Segment>
          <Header dividing>
            <Grid columns={2}>
              <Grid.Row verticalAlign='middle'>
                <Grid.Column width={14}>
                  <Header as='h3'>
                    {`${I18n.t('components.organisation.title')}: ${organisation.name}`}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
         <OrganisationForm entityId={this.props.organisationId}/>
       </Segment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    organisation: findEntity(state, 'organisation', ownProps.organisationId)
  };
}

export default connect(mapStateToProps)(Organisation);
