import React from 'react'
import { Mention } from "./Mentions.jsx";

export default class EditorElement extends React.Component {
  render() {
    let {attributes, children, element} = this.props
    const style = {
      textAlign: element.align,
      lineHeight: 1.25,
      marginBottom: 0
    }
    switch (element.type) {
      case 'block-quote':
        return (
          <blockquote style={style} {...attributes}>
            {children}
          </blockquote>
        )
      case 'bulleted-list':
        return (
          <ul style={style} {...attributes}>
            {children}
          </ul>
        )
      case 'heading-two':
        return (
          <h2 style={style} {...attributes}>
            {children}
          </h2>
        )
      case 'list-item':
        return (
          <li style={style} {...attributes}>
            {children}
          </li>
        )
      case 'numbered-list':
        return (
          <ol style={style} {...attributes}>
            {children}
          </ol>
        )
      case 'mention':
        return <Mention {...this.props} />
      default:
        return (
          <p style={style} {...attributes}>
            {children}
          </p>
        )
    }
  }
}
