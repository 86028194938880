import SchemaForm from 'components/forms/SchemaForm'
import { consultationTypeSchema } from 'forms/superuser/consultationTypeSchema'
import React from 'react'
import { connect } from 'react-redux'

class ConsultationTypeForm extends React.Component {
  render () {
    return (
      <SchemaForm entityName='consultationType' uiSchema={this.props.uiSchema} submitButton={true} entityId={this.props.entityId}/>
      )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    submitButton: true,
    uiSchema: consultationTypeSchema(state, ownProps)
  }
}

export default connect(mapStateToProps)(ConsultationTypeForm);
