import InvoiceConsultationsListContainer from 'components/invoices/InvoiceConsultationsListContainer'

import InvoiceForm from 'components/invoices/InvoiceForm'
import PdfItem from 'components/invoices/PdfItem'

import SendEmailItem from 'components/invoices/SendEmailItem'
import React from 'react'

import { connect } from 'react-redux'
import { Link } from "react-router-dom";

import { entityRoute } from 'route_helpers'
import { findEntity } from 'selectors/entities'
import { Grid, Header, Label, Menu, Segment } from 'semantic-ui-react'

class Invoice extends React.Component {
  render() {
    const { invoice } = this.props

    if (!invoice) {
      return <div/>
    }

    return (
      <Segment clearing className="min-height-90">
          <Header dividing>
            <Grid columns={2}>
              <Grid.Row verticalAlign='middle'>
                <Grid.Column width={4}>
                  <Header as='h3'>
                    {`${invoice.creditNote ? I18n.t('components.invoice.title_credit_note') : (invoice.proposed ? I18n.t('components.invoice.title_proposed') : I18n.t('components.invoice.title'))}: ${invoice.formattedIdentification}`}

                  </Header>
                </Grid.Column>
                <Grid.Column width={10}>
                    { invoice.dossier && invoice.dossier.patient &&
                      (<Label as={Link} to={entityRoute('dossier',false, {id: invoice.dossier.id})}>{invoice.dossier.patient.lastName} {invoice.dossier.patient.firstName}</Label>)
                      }
                </Grid.Column>
                <Grid.Column width={2}>
                  <Menu compact borderless secondary>
                    <SendEmailItem invoice={invoice}/>
                    <PdfItem invoice={invoice}/>
                  </Menu>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
         <InvoiceForm entityId={this.props.invoiceId}/>
         <Header dividing>
           <Grid columns={2}>
             <Grid.Row verticalAlign='middle'>
               <Grid.Column width={14}>
                 <Header as='h3'>
                   {I18n.t('components.invoice.consultations')}
                 </Header>
               </Grid.Column>
             </Grid.Row>
           </Grid>
         </Header>
         <InvoiceConsultationsListContainer invoice={invoice}/>
       </Segment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    invoice: findEntity(state, 'invoice', ownProps.invoiceId)
  };
}

export default connect(mapStateToProps)(Invoice);
