const setupOfficeSchema = function(state, props) {
  return {
    "name": {
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:options": {
        width: 6
      }
    },
    "enterpriseNumber": {
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:widget": "MaskedTextWidget",
      "ui:options": {
        mask: [/\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]
      }
    },
    "rizivNumber": {
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:widget": "MaskedTextWidget",
      "ui:options": {
        mask: [/\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/]
      }
    },
    "phoneNumber": {
      "ui:widget": "hidden"
    },
    "iban": {
      "ui:widget": "hidden"
    },
    "invoiceConditions": {
      "ui:widget": "hidden"
    },
    "invoiceMessage": {
      "ui:widget": "hidden"
    },
    "startInvoiceIdentification": {
      "ui:widget": "hidden"
    }
  }
}

export default setupOfficeSchema;
