import orm from 'models'
import { createSelector as ormSelector } from 'redux-orm'
import getEntities from 'selectors/entities'

export const getContacts = ormSelector(orm, getEntities, (session) => {
  return session.contact.all().toModelArray()
                .filter(contact => !contact.isArchived)
                .map(contact => {
                  const {ref} = contact;
                  return {...ref}
                })
})

