import { fetchEntities } from 'actions/entities';
import EntitiesMenu from 'components/entities/EntitiesMenu';
import React from 'react'
import { connect } from 'react-redux'
import { getHealthFunds } from 'selectors/health_funds'


const mapStateToProps = (state, ownProps) => {
  return {
    entities: getHealthFunds(state, ownProps),
    entityName: 'healthFund',
    item: (props) => <div>{`${props.healthFund.name}`}</div>,
    loading: state.meta.healthFund.loading,
    error: state.meta.healthFund.error,
    apiType: 'superuser'
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('healthFunds'))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesMenu);
