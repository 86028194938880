import DossierForm from 'components/dossiers/DossierForm'
import DossiersListItem from 'components/dossiers/DossiersListItem'

import EntitiesList from 'components/entities/EntitiesList';
import { connect } from 'react-redux'

import { getDossiers } from 'selectors/dossiers'
import { Card } from 'semantic-ui-react'

const mapStateToProps = (state, ownProps) => {
  return {
    entities: getDossiers(state),
    entityName: 'dossier',
    item: DossiersListItem,
    form: DossierForm,
    listComponent: Card.Group,
    loading: state.meta.dossier.loading,
    error: state.meta.dossier.error
  };
}

export default connect(mapStateToProps)(EntitiesList)
