import { attr, fk } from 'redux-orm'
import Base from './Base'

class Dossier extends Base {
  static get fields() {
    return {
      id: attr(),
      createdAt: attr(),
      invoiceReceiver: attr(),
      extraRemark: attr(),
      dossierNumber: attr(),
      isArchived: attr(),
      locationId: fk('location'),
      patientId: fk({to: 'contact', as: 'patient', relatedName: 'dossier'}),
      healthFundId :fk({ to: 'healthFund', as: 'healthFund', relatedName: 'dossiers' })
    }
  }
}

Dossier.modelName = 'dossier'

export default Dossier;
