import { fetchEntities } from 'actions/entities';
import DossierForm from 'components/dossiers/DossierForm'
import React from 'react'
import { connect } from 'react-redux'

import { Item } from 'semantic-ui-react'

class DossierBasicInfo extends React.Component {
  componentDidMount() {
      this.props.dispatch(fetchEntities('dossierSlices', { name: 'dossier', filter: { dossierId: this.props.dossier.id }, page: 1 }))
      this.props.dispatch(fetchEntities('relationships', { name: 'dossier', filter: { dossierId: this.props.dossier.id }, page: 1 }))
  }

  render() {
    const dossier = this.props.dossier;

    if (dossier) {
      return (
        <div>
          <DossierForm entityId={dossier.id}/>
        </div>
      )
    } else {
      return (
        <Item>
          Geen dossier
        </Item>
      )
    }
  }
}

export default connect()(DossierBasicInfo);
