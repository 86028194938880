import { attr, fk } from 'redux-orm'
import Base from './Base'

class DossierItem extends Base {
  static get fields() {
    return {
      id: attr(),
      text: attr(),
      time: attr(),
      dossierItemType: attr(),
      attachmentExpiringUrl: attr(),
      attachmentTitle: attr(),
      dossierId: fk({to: 'dossier', as: 'dossier', relatedName: 'dossierItems'}),
      consultationId: fk({to: 'consultation', as: 'consultation', relatedName: 'dossierItems'}),
    }
  }
}

DossierItem.modelName = 'dossierItem';

export default DossierItem;
